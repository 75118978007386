<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="模块" prop="">
          <a-input v-model="form.name" placeholder="请输入模块" />
        </a-form-model-item>

        <a-form-model-item label="模块类型" prop="">
          <a-select
            v-model="form.type"
            placeholder="请选择模块类型"
            style="width: 150px"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in modulesList"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="学段" prop="">
          <a-select
            v-model="form.grade_type"
            placeholder="请选择学段"
            style="width: 150px"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in sectionList"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>

        <!-- <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="delFrom"
        >
          <template slot="title">
            <p>确认删除吗?</p>
          </template>
          <a-button class="ml" icon="delete"> 删除 </a-button>
        </a-popconfirm>
        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
         -->

        <!-- <a-upload
          name="file"
          :multiple="false"
          :show-upload-list="false"
          @change="handleChange"
          :customRequest="customRequest"
        >
          <a-button class="btnIn ml">
            <a-icon type="upload" /> 导入模板
          </a-button>
        </a-upload> -->
      </div>

      <a-table
        :scroll="{ x: 1500, y: 520 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.name"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <span slot="xiaoxue" slot-scope="text">
          <a-icon
            type="check"
            v-if="text.indexOf(1) > -1"
            style="color: #22bb7e"
          />
          <a-icon type="close" v-else style="color: #ff5655" />
        </span>

        <span slot="chuzhong" slot-scope="text">
          <a-icon
            type="check"
            v-if="text.indexOf(2) > -1"
            style="color: #22bb7e"
          />
          <a-icon type="close" v-else style="color: #ff5655" />
        </span>

        <span slot="gaozhong" slot-scope="text">
          <a-icon
            type="check"
            v-if="text.indexOf(3) > -1"
            style="color: #22bb7e"
          />
          <a-icon type="close" v-else style="color: #ff5655" />
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>
          <!-- <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm> -->
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "模块",
    dataIndex: "name",
    // align: 'center',
    key: "name",
    // width: 250,
  },
  {
    title: "小学",
    dataIndex: "grade_type",
    // align: 'center',
    key: "xiaoxue",
    scopedSlots: { customRender: "xiaoxue" },
    // width: 100,
  },
  {
    title: "初中",
    dataIndex: "grade_type",
    // align: 'center',
    key: "chuzhong",
    scopedSlots: { customRender: "chuzhong" },
    // width: 100,
  },
  {
    title: "高中",
    dataIndex: "grade_type",
    // align: 'center',
    key: "gaozhong",
    scopedSlots: { customRender: "gaozhong" },
    // width: 100,
  },
  {
    title: "模块类型",
    dataIndex: "type_name",
    // align: 'center',
    key: "type_name",
    // width: 100,
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
// 预览 模块当前 暂时取消新增编辑功能
import addFrom from "./addFrom";

export default {
  name: "question",
  components: {
    addFrom,
  },
  created() {
    // 学段
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));

    this.getTable();
  },
  data() {
    return {
      visibleModal: false,
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      modulesList: [
        // 1-可选 2-必选 3-特殊
        {
          value: 1,
          label: "可选",
        },
        {
          value: 2,
          label: "干扰",
        },
        {
          value: 3,
          label: "特殊",
        },
        {
          value: 4,
          label: "自定义",
        },
      ],
    };
  },
  methods: {
    getTable(name = "", type = "", grade_type = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/modules", {
          expand: "grade_type_name,type_name",
          page: this.pagination.current,
          "per-page": 10,
          name,
          type,
          grade_type,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(this.form.name, this.form.type, this.form.grade_type);
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(this.form.name, this.form.type, this.form.grade_type);
    },

    addFrom() {
      console.log("addFrom");
      this.$refs.addFrom.addFrom();
    },
    editRecord(record) {
      // console.log('editRecord')
      // console.log(record)
      this.$refs.addFrom.editFrom(record);
    },
    delFrom() {
      console.log("delFrom--多选删除");
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);

      // this.$api.delAction("/admin/v1/schools", record.id).then((res) => {
      //   console.log(res);
      //   if (res.code == 0) {
      //     // this.$message.success(res.msg)
      //     this.getTable();
      //   } else {
      //     if (res.data !== undefined) {
      //       console.log(res.data[Object.keys(res.data)[0]]);
      //       this.$message.error(res.data[Object.keys(res.data)[0]]);
      //     } else {
      //       this.$message.error(res.msg);
      //     }
      //   }
      // });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style scoped lang="less">
.btnRegion {
  .time {
    font-size: 16px;
    color: #343a40;
    .big {
      color: #22bb7e;
      font-size: 36px;
      padding: 0 10px;
    }
  }
}
.speedStyle {
  display: flex;
  justify-content: space-between;
}
</style>
