<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学校">
          <a-input v-model="form.school_name" placeholder="请输入学校" />
        </a-form-model-item>

        <a-form-model-item label="班级">
          <a-input v-model="form.class_name" placeholder="请输入班级" />
        </a-form-model-item>
        <a-form-model-item label="姓名">
          <a-input v-model="form.name" placeholder="请输入姓名" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
          <a-popconfirm
            v-if="form.school_name"
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="clearUp"
          >
            <template slot="title">
              <p>参与测试的学生数据会保留，未参与测试的学生会全部删除</p>
            </template>
            <a-button class="ml" icon="delete"> 一键清空 </a-button>
          </a-popconfirm>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>
        <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="delFrom"
        >
          <template slot="title">
            <p>确认删除吗?</p>
          </template>
          <a-button class="ml" icon="delete"> 删除 </a-button>
        </a-popconfirm>

        <a-button class="btnIn ml" @click="showDrawer" icon="upload">
          导入模板
        </a-button>

        <!-- <a-button class="btnIn ml" @click="outFn" icon="download">
          导出空模板
        </a-button> -->

        <a :href="outUrl + '/report/template/student.xlsx'">
          <a-button class="btnIn ml" icon="download"> 导出学生空模板 </a-button>
        </a>

        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
      </div>

      <a-table
        :scroll="{ x: 1500, y: 500 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="name" slot-scope="text, record">
          <p style="white-space: nowrap;min-width: 180px;">
            <span>{{ text }}</span>
            <span class="btnOrg">{{ record.grade_info.session }}届</span>
          </p>
          <p class="grey">
            {{ record.identity }}
          </p>
        </span>
        <span slot="grade_info" slot-scope="text" style="display: flex;align-items: center;">
          <p :title="text.school_name" style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 50%;">
            {{ text.school_name }}
          </p>
          <p class="grey" style="margin-left: 10px;min-width: 25px;">
            {{ text.class }}
          </p>
          <p class="grey" style="margin-left: 10px;">
            {{ text.grades }}
          </p>
        </span>

        <span slot="assess_info" slot-scope="text">
          <div v-if="text.assess_name">
            <p>
              {{ text.assess_name }}
            </p>
            <p>共 {{ text.question_count }} 题</p>
          </div>
          <div v-else>--</div>
        </span>

        <span slot="status" slot-scope="text, record">
          <span v-if="!record.assess_info.assess_name"> -- </span>
          <span v-else>
            <span
              :class="[
                'dianQuan',
                text.progress == '未完成' || !text.progress
                  ? 'dianRed'
                  : 'dianGreen',
              ]"
            />
            {{ text.progress ? text.progress : "未完成" }}
          </span>
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <!-- <p style="text-align: center; margin-bottom: 0px">删除</p> -->
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />

    <a-drawer
      title="学生信息导入"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="640"
    >
      <a-alert message="请先选择对应学校,然后再进行导入" banner />

      <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="学校" prop="">
          <schoolSelect @setSchoolId="setSchoolId" :isDisabled="!isUping" />
        </a-form-model-item>

        <a-form-model-item label="导入模板" prop="name">
          <a-upload
            name="file"
            :multiple="false"
            :show-upload-list="false"
            @change="handleChange"
            :customRequest="customRequest"
            v-if="isUping"
          >
            <a-button class="btnIn ml">
              <a-icon type="upload" /> 导入模板
            </a-button>
          </a-upload>
          <a-button v-else loading> 数据导入中，请稍等 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>

    <a-modal
      title="导入失败信息"
      :visible="isErrShow"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
      :footer="null"
    >
      <p v-for="(item, i) in errMsg" :key="i">{{ item }}</p>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "姓名",
    dataIndex: "name",
    // align: 'center',
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "学校 / 班级 / 年级",
    dataIndex: "grade_info",
    // align: 'center',
    key: "grade_info",
    width: 300,
    scopedSlots: { customRender: "grade_info" },
  },
  {
    title: "学校内部号",
    dataIndex: "inner_code",
    // align: 'center',
    key: "inner_code",
  },
  {
    title: "学籍号",
    dataIndex: "code",
    // align: 'center',
    key: "code",
  },
  {
    title: "测评卷名称",
    dataIndex: "assess_info",
    // align: 'center',
    key: "assess_info",
    scopedSlots: { customRender: "assess_info" },
  },
  {
    title: "状态",
    dataIndex: "assess_info",
    // align: 'center',
    key: "status",
    width: 100,
    scopedSlots: { customRender: "status" },
  },
  // {
  //   title: '生成报告时间',
  //   dataIndex: 'contact_phone',
  //   // align: 'center',
  //   key: 'contact_phone',
  //   width: 150,
  // },
  // {
  //   title: '是否生成报告',
  //   dataIndex: 'contact_phone1',
  //   // align: 'center',
  //   key: 'contact_phone1',
  //   width: 150,
  // },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

import addFrom from "./addFrom";
import schoolSelect from "@/components/schoolSelect";

export default {
  name: "studentsInfo",
  components: {
    addFrom,
    schoolSelect,
  },
  created() {
    this.getTable();
    console.log(process.env.VUE_APP_API_BASE_URL);
  },
  data() {
    return {
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },

      school_id: "",

      visible: false,
      form: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      schoolId: "",
      outUrl: process.env.VUE_APP_API_BASE_URL,

      isUping: true,
      isErrShow: false,
      confirmLoading: false,
      errMsg: [],
    };
  },
  methods: {
    clearUp(){
      console.log('clear==')
      let that = this
      let params = {
        school_name: that.form.school_name
      }
      that.$api
        .postAction("/admin/v1/students/delete-all", params)
        .then((res) => {
          console.log(res);
          that.isUping = true;
          if (res.code == 0) {
            that.handleSubmit()
            that.$message.success(res.msg);
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              that.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              that.$message.error(res.msg);
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // outFn() {
    //   console.log("outFn");
    //   // let aDom = document.createElement("a");
    //   // aDom.style.display = "none";
    //   // aDom.href = this.outUrl + "/report/template/student.xlsx";
    //   // aDom.download = "你猜.xlsx";
    //   // aDom.click();
    // },
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    handleCancel() {
      this.isErrShow = false;
      this.errMsg.push = [];
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      if (this.isUping) {
        this.visible = false;
      } else {
        this.$message.warning("数据导入中，请稍等");
      }
    },
    setSchoolId(val) {
      console.log(val);
      this.schoolId = val;
    },
    customRequest(data) {
      console.log(this.schoolId);
      if (!this.schoolId) {
        this.$message.warning("请先选择学校");
        return false;
      }
      console.log(data);
      let formData = new FormData();
      console.log(data.file);

      formData.append("attachment", data.file);
      formData.append("school_id", this.schoolId);
      console.log(formData);

      this.saveFile(formData);
    },
    saveFile(formData) {
      let that = this;
      that.errMsg = [];
      console.log("that.isErrShow");
      console.log(that.isErrShow);
      that.$message.warning("数据导入中，请稍等");
      that.isUping = false;
      that.$api
        .postAction("/admin/v1/students/import", formData)
        .then((res) => {
          console.log(res);
          that.isUping = true;
          if (res.code == 0) {
            that.$message.success(res.msg);
            that.onClose();

            that.getTable();
          } else {
            if (res.data !== undefined) {
              //
              console.log(res.data);
              for (let i in res.data) {
                //i是键名，obj[i]是键值
                // console.log(i, res.data[i]);
                that.errMsg.push(res.data[i]);
              }
              that.isErrShow = true;
              //
              // console.log(res.data[Object.keys(res.data)[0]]);
              // that.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              that.$message.error(res.msg);
            }
            that.isUping = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    getTable(school_name = "", class_name = "", name = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/students", {
          expand: "grade_info,assess_info",
          page: this.pagination.current,
          "per-page": 10,
          school_name,
          class_name,
          name,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(
        this.form.school_name,
        this.form.class_name,
        this.form.name
      );
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      this.$refs.addFrom.addFrom();
    },
    editRecord(record) {
      console.log("editRecord");
      // console.log(record)
      this.$refs.addFrom.editFrom(record);
    },
    inFrom() {
      console.log("inFrom--导入");
    },
    delFrom() {
      console.log("delFrom--多选删除");

      if (this.selectedRowKeys.length < 1) {
        this.$message.warning("请选勾选");
        return false;
      }

      this.$api
        .delAction("/admin/v1/students/batch-delete", "", {
          id: this.selectedRowKeys,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.selectedRowKeys = [];
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);

      let key = this.selectedRowKeys.indexOf(record.id);
      if (key > -1) {
        this.selectedRowKeys.splice(key, 1);
      }

      this.$api.delAction("/admin/v1/students", record.id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.getTable();
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]]);
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;
      // console.log(this.form);
      this.getTable(
        this.form.school_name,
        this.form.class_name,
        this.form.name
      );
    },
  },
};
</script>

<style scoped lang="less">
span.btnOrg {
  border: 1px solid #ff8200;
  color: #ff8200;
  background: rgba(255, 128, 1, 0.06);
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 5px;
}
p {
  margin-bottom: 0;
  & + p.grey {
    padding: 5px 0;
    font-size: 12px;
  }
}

.router-test {
  color: #007cae;
  font-size: 14px;
}

.dianQuan {
  display: inline-block;
  // background-color: #FF5655;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.dianQuan.dianRed {
  background-color: #ff5655;
}
.dianQuan.dianGreen {
  background-color: #22bb7e;
}
</style>
