<template>
  <a-drawer
    title="角色权限信息"
    placement="right"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="题目名称" prop="name">
        <a-input
          v-model="form.name"
          type="textarea"
          :auto-size="{ minRows: 5 }"
        />
      </a-form-model-item>

      <a-form-model-item label="模块" prop="module_id">
        <a-select
          v-model="form.module_id"
          placeholder="请选择模块"
          style="width: 100%"
        >
          <a-select-option
            :value="item.id"
            v-for="(item, index) in modularList"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- <a-form-model-item label="选项" prop="">
        <a-row :gutter="[16, 16]" v-for="(option, i) in options" :key="i">
          <a-col :span="10">
            <a-input placeholder="请输入选项名称" v-model="option.label" />
          </a-col>
          <a-col :span="10">
            <a-input placeholder="请输入选项值" v-model="option.value" />
          </a-col>
          <a-col :span="2" v-if="options.length > 1">
            <a-icon type="minus-circle" @click="reomveOption(i)" />
          </a-col>
        </a-row>

        <a-button @click="addOption" class="btnGreenFull">
          添加选项信息
        </a-button>
      </a-form-model-item> -->

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
        <a-button @click="resetForm" class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
export default {
  name: '',
  props: ['modularList'],
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入题目名称',
            trigger: 'blur',
          },
        ],
        module_id: [
          {
            required: true,
            message: '请选择模块',
            trigger: 'blur',
          },
        ],
      },

      // 判断是 add 还是edit
      addFlag: true,
    }
  },
  watch: {},
  created() {},
  methods: {
    addOption() {
      console.log('添加--选项')
      this.options.push({
        label: '',
        value: '',
      })
    },
    reomveOption(i) {
      console.log('删除--选项')
      console.log(i)
      this.options.splice(i, 1)
    },
    onSubmit() {
      console.log(this.form)
      // console.log(this.options)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // this.form.option = this.options
          // console.log(this.form)

          if (this.addFlag) {
            this.$api
              .postAction('/admin/v1/question-banks', this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.onClose()
                  this.$parent.getTable()
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]])
                    this.$message.error(res.data[Object.keys(res.data)[0]])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              })
          } else {
            this.$api
              .putAction('/admin/v1/question-banks', this.form.id, this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.onClose()
                  this.$parent.getTable()
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]])
                    this.$message.error(res.data[Object.keys(res.data)[0]])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.form = {}
      this.onClose()
    },
    addFrom() {
      this.form = {}
      console.log('addFrom')
      this.visible = true
      this.addFlag = true
    },
    editFrom(record) {
      console.log('editFrom')
      console.log(record)

      this.form = {}
      this.form = record

      this.visible = true
      this.addFlag = false
    },
    onClose() {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    afterVisibleChange(val) {
      console.log('触发以及结束时的回调', val)
    },
  },
}
</script>

<style scoped>
:deep(.ant-select-selection--multiple .ant-select-selection__choice) {
  background: rgba(0, 124, 174, 0.1);
  border-radius: 16px;
}
</style>
