<template>
  <div ref="question">
    <a-drawer
      title="问卷"
      placement="right"
      :closable="true"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="resetForm"
      :width="640"
      :maskClosable="false"
    >
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        labelAlign="left"
      >
        <a-row>
          <a-col :span="16">
            <a-form-model-item
              label="学生账号"
              prop="student_name"
              :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 16 }"
            >
              <a-input v-model="form.student_name" :disabled="!canEdit" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8" style="margin-top: 3px">
            <a-button v-if="!canEdit" @click="editStudent">修改</a-button>
          </a-col>
        </a-row>

        <a-row v-if="canEdit">
          <a-col :span="16">
            <a-form-model-item
              label="密码"
              prop="student_identity"
              :labelCol="{ span: 4 }"
              :wrapperCol="{ span: 16 }"
            >
              <a-input v-model="form.student_identity"/>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-form-model-item
          :label="index + 1 + '.' + item.name"
          :prop="'rule' + item.id"
          :colon="false"
          v-for="(item, index) in interview_question_data"
          :key="item.id"
        >
          <a-radio-group
            v-model="form['rule' + item.id]"
            @change="onchange($event, index, item.option)"
          >
            <a-radio
              :value="optionItem.label"
              v-for="(optionItem, optionIndex) in item.option"
              :key="optionIndex"
            >
              {{ optionItem.label }}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
          <a-button @click="resetForm" class="ml"> 取消 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>

export default {
  name: "",
  data() {
    return {
      interview_question_data: [],
      visible: false,
      other: "",
      form: {},
      rules: {},
      rulesOrginal: {
        student_name: [
          {
            required: true,
            message: "请输入学生账号",
            trigger: "blur",
          },
          {
            min: 0,
            max: 8,
            message: "最大长度8",
            trigger: "blur",
          },
        ],
        student_identity: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      //是否可以编辑学生信息
      canEdit: true,
      // 判断是 add 还是edit
      addFlag: true,
    };
  },
  watch: {},
  created() {},
  methods: {
    editStudent() {
      this.$confirm({
        content: "是否修改学生信息？",
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.form.student_name = "";
          this.canEdit = true;
        },
      });
    },
    onchange(e, index, options) {
      this.form.content[index].label = e.target.value;
      let temp = options.filter((item) => item.label === e.target.value);
      this.form.content[index].value = temp[0].value;
    },
    onSubmit() {
      console.log("onSubmit===", this.form);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.addFlag) {
            this.$api
              .postAction("/admin/v1/interviews", this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.resetForm();
                  this.$parent.getTable();
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]]);
                    this.$message.error(res.data[Object.keys(res.data)[0]]);
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              });
          } else {
            //如果编辑时，没有修改学生信息则不上传学生信息
            let submitData = JSON.parse(JSON.stringify(this.form));
            if (!this.canEdit) {
              submitData.student_name = "";
              submitData.student_identity = "";
            }

            this.$api
              .putAction("/admin/v1/interviews", this.form.id, submitData)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.resetForm();
                  this.$parent.getTable();
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]]);
                    this.$message.error(res.data[Object.keys(res.data)[0]]);
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {};
      this.rules = {};
      this.interview_question_data = [];
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    //data 是问卷数据
    addFrom(data) {
      this.addFlag = true;
      this.canEdit = true;
      this.initData(data);
    },
    //data 是问卷数据  record是列表编辑数据
    editFrom(data, record) {
      this.addFlag = false;
      this.canEdit = false;
      this.initData(data, record);
    },
    initData(data, record) {
      let tempData = JSON.parse(JSON.stringify(data));
      let tempRules = JSON.parse(JSON.stringify(this.rulesOrginal));
      let tempForm = { content: [] };

      if (this.addFlag) {
        //遍历问卷数据，并创建提交数据的数据结构和校验条件的数据结构
        tempData.forEach((item) => {
          tempForm.content.push({
            question_id: item.id,
            label: "",
            value: "",
          });

          tempRules["rule" + item.id] = [
            {
              required: true,
              message: "请选择选项",
              trigger: "change",
            },
          ];
        });
      } else {
        //遍历问卷数据，并创建提交数据的数据结构和校验条件的数据结构
        tempData.forEach((item) => {
          //找出问题id相同的数据（有且只有一条）
          let tempArr =  record.content.filter(element =>{return element.question_id === item.id})

          tempForm.content.push({
            question_id: item.id,
            label: tempArr[0].label,
            value: tempArr[0].value,
          });

          //用于单选框回显
          tempForm["rule" + item.id] = tempArr[0].label;

          tempRules["rule" + item.id] = [
            {
              required: true,
              message: "请选择选项",
              trigger: "change",
            },
          ];
        });
        tempForm.student_name = record.student_name;
        tempForm.id = record.id;
      }

      this.rules = tempRules;
      this.form = tempForm;
      this.interview_question_data = tempData;
      this.visible = true;
    },
    afterVisibleChange(val) {
      console.log("触发以及结束时的回调", val);
    },
  },
};
</script>

<style scoped lang="less">
:deep(.ant-drawer-body) {
  .ant-col-4 {
    width: 20%;
  }

  .ant-input-number {
    width: 100%;
  }
}
</style>
