<template>
  <div ref="question">
    <a-drawer
      title="问卷"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="resetForm"
      :width="640"
    >
      <a-form-model :model="form" labelAlign="left">
        <a-form-model-item label="班级">
          <a-cascader
            v-model="form.select"
            :options="options"
            placeholder="请选择班级"
            allowClear
            @change="onChangeGrade"
            :field-names="{
              label: 'name',
              value: 'id',
              children: 'list',
            }"
          />
          <div class="selectList">
            <a-checkbox-group v-model="form.selectStu" style="width: 100%">
              <a-checkbox
                :value="item.id"
                style="min-width: 33%; margin-left: 0"
                name="type"
                v-for="(item, index) in gradeList"
                :key="index"
                >{{ item.name }}</a-checkbox
              >
            </a-checkbox-group>
          </div>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
          <a-button @click="resetForm" class="ml"> 取消 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      visible: false,
      form: {
        school_id: undefined,
        assess_id: undefined,
        selectStu: [],
        class_id: undefined,
        grade: undefined,
        select: [],
      },
      list: [],
      options: [],
      select: [],
      gradeList: [],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    onChange(e) {
      this.form.assess_id = e.target.value;
    },
    onChangeGrade(value, valueObj) {
      console.log("valueObj====", value, valueObj);
      let list = valueObj.length > 1 ? valueObj[1].stuList : [];
      this.gradeList = list;
      console.log("gradeList====", this.gradeList);
      if (value.length != 0) {
        this.form.select = value;
      } else {
        this.list = [];
      }
    },
    getInfo() {
      this.$api
        .getAction("/admin/v1/class-reports/class-list", {})
        .then((res) => {
          if (res.code == 0) {
            // this.$message.success(res.msg);
            this.options = this.dataManage(res.data.school, 1); // res.data.school;
            console.log("options===", this.options);
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    dataManage(data) {
      let arr = [];
      data.forEach((item) => {
        let obj = {
          id: item.id,
          name: item.name,
          level: 1,
        };
        if (item.list) {
          let arr2 = [];
          item.list.forEach((item2) => {
            let obj2 = {
              id: item2.id,
              name: item2.name,
              stuList: item2.list,
              level: 2,
            };
            arr2.push(obj2);
          });
          obj.list = arr2;
        }
        arr.push(obj);
      });
      return arr;
    },
    onSubmit() {
      if (!this.form.select.length) {
        this.$message.warning("请选择班级");
        return false;
      }
      if (this.form.selectStu.length == 0) {
        this.$message.warning("请选择班级");
        return false;
      }
      let params = {
        class_id: this.form.selectStu,
        school_id: this.form.select[0],
      };
      this.$api.postAction("/admin/v1/class-reports-batch", params).then((res) => {
        if (res.code == 0) {
          this.resetForm();
          this.$message.success(res.msg);
          this.$parent.getTable();
        } else {
          if (res.data !== undefined) {
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    resetForm() {
      this.form = {};
      this.visible = false;
    },
    //data 是问卷数据
    addFrom() {
      this.visible = true;
    },
    afterVisibleChange(val) {
      console.log("触发以及结束时的回调", val);
    },
  },
};
</script>

<style scoped lang="less">
:deep(.ant-drawer-body) {
  .ant-col-4 {
    width: 20%;
  }

  .ant-input-number {
    width: 100%;
  }
  .ant-cascader-menu {
    height: 300px !important;
  }
}
.selectList {
  width: 100%;
}
</style>
