<template>
  <div class="error">
    <h1>404</h1>
  </div>
</template>

<script>
export default {
  name: 'error',
  created() {},
  data() {
    return {
      msg: 'error',
    }
  },
  mounted() {
    console.log('3000')
    setTimeout(() => {
      this.$router.push('/')
    }, 3000)
  },
}
</script>

<style scoped></style>
