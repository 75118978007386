<template>
  <div class="login">
    <div class="logo">
      <div class="top">
        <img src="@/assets/logo.png" alt="" />
        <p class="nameTips">关注青少年健康成长</p>
        <p class="nameTitle">学生乐成长综合素养发展测评系统</p>
      </div>

      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="" prop="username">
          <a-input
            v-model="form.username"
            placeholder="请输入账号"
            style="color: #fff"
          >
            <a-tooltip slot="suffix" title="">
              <a-icon type="user" style="color: #fff; font-size: 20px" />
            </a-tooltip>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="" prop="password">
          <a-input
            v-model="form.password"
            placeholder="请输入密码"
            :type="showEye ? 'password' : ''"
          >
            <a-tooltip slot="suffix" title="">
              <a-icon
                type="eye"
                style="color: #fff; font-size: 20px"
                @click="() => (this.showEye = !this.showEye)"
              />
            </a-tooltip>
          </a-input>
        </a-form-model-item>

        <!-- <a-form-model-item label="">
          <a-checkbox v-model="form.remember"> 记住密码 </a-checkbox>
        </a-form-model-item> -->

        <a-form-model-item>
          <a-button @click="onSubmit" class="loginSub"> 登录 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  created() {
    localStorage.clear();
    sessionStorage.clear();
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      showEye: true,
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!", this.form);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$api
            .postAction("/admin/v1/user/login", this.form)
            .then((res) => {
              if (res.code == 0) {
                sessionStorage.setItem("token", res.data.token);
                sessionStorage.setItem(
                  "userMenu",
                  JSON.stringify(res.data.menu)
                );
                sessionStorage.setItem("userInfo", JSON.stringify(res.data));
                // 后台应用 userType 为1
                sessionStorage.setItem("userType", 1);

                this.getStatic();

                let menu = res.data.menu[1];
                if (menu.url) {
                  this.$router.push("/" + menu.url);
                } else {
                  this.$router.push("/" + menu.list[0].url);
                }
              } else {
                if (res.data !== undefined) {
                  console.log(res.data[Object.keys(res.data)[0]]);
                  this.$message.error(res.data[Object.keys(res.data)[0]]);
                } else {
                  this.$message.error(res.msg);
                }
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    getStatic() {
      // 获取 所有的 省市区 + 学段
      this.$api
        .getAction("/admin/v1/common/static", {
          data_name: "city,section,school",
        })
        .then((res) => {
          sessionStorage.setItem("provinceList", JSON.stringify(res.data.city));
          sessionStorage.setItem(
            "sectionList",
            JSON.stringify(res.data.section)
          );
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.login {
  background: url("../../assets/bg1.png") no-repeat fixed;
  // background-size: 100% 100%;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;

  .logo {
    // width: 440px;
    margin: 0 auto;
    padding-top: 200px;

    .top {
      text-align: center;
      img {
        // width: 154px;
        // height: 137px;
        // margin-bottom: 20px;
        width: 66px;
        height: 58px;
        margin-bottom: 10px;
      }
      p {
        color: #ffffff;
        font-size: 16px;
      }
      p.nameTitle {
        font-size: 32px;
        font-weight: 600;
      }
    }

    .loginSub {
      width: 100%;
      height: 70px;
      border-radius: 15px;
      background-color: #00577a;
      font-size: 18px;
      color: #ffffff;
      border: 0;
    }

    :deep(.ant-form) {
      width: 440px;
      margin: 0 auto;

      .ant-form-item:nth-child(3) {
        margin-bottom: 0;
      }

      .ant-form-item {
        .ant-checkbox-wrapper {
          font-size: 18px;
          color: #ffffff;
        }
        input {
          height: 60px;
          border-radius: 15px;
          background: rgba(0, 0, 0, 0.18);
          font-size: 18px;
          color: #ffffff;
          border: 0;
        }

        input:-moz-placeholder,
        textarea:-moz-placeholder {
          color: #ffffff;
          opacity: 0.72;
        }

        input:-ms-input-placeholder,
        textarea:-ms-input-placeholder {
          color: #ffffff;
          opacity: 0.72;
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          color: #ffffff;
          opacity: 0.72;
        }
      }

      .ant-col {
        width: 100%;
      }
    }
  }
}
</style>
