<template>
  <div class="examination">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学校">
          <a-input v-model="form.school_name" placeholder="请输入学校" />
        </a-form-model-item>

        <a-form-model-item label="学段" prop="">
          <a-select
            v-model="form.section"
            placeholder="请选择学段"
            style="width: 150px"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in sectionList"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="评测卷">
          <a-input v-model="form.name" placeholder="请输入评测卷" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>
        <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="delFrom"
        >
          <template slot="title">
            <p>确认删除吗?</p>
          </template>
          <a-button class="ml" icon="delete"> 删除 </a-button>
        </a-popconfirm>
        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
      </div>

      <a-table
        :scroll="{ x: 1500, y: 520 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="assess_name" slot-scope="text, record">
          <p class="assessName">
            {{ text }}
          </p>
          <p>共 {{ record.question_count ? record.question_count : 0 }} 题</p>
        </span>

        <span slot="school_info" slot-scope="text, record">
          <span v-if="text.length > 1">
            <a-tooltip placement="topLeft">
              <template slot="title">{{ record.schoolName }} </template>
              <span v-for="(item, i) in text" :key="i">
                <p v-if="i <= 1" class="schoolName">{{ item.name }}</p>
              </span>
              <span>...</span>
            </a-tooltip>
          </span>
          <span v-else>
            <span v-for="(item, i) in text" :key="i">
              <p class="schoolName">{{ item.name }}</p>
            </span>
          </span>
        </span>

        <span slot="module_info" slot-scope="text, record">
          <span v-if="text.length > 4">
            <a-tooltip placement="topLeft">
              <template slot="title">{{ record.moduleName }} </template>
              <span v-for="(item, i) in text" :key="i">
                <span class="moduleInfo" v-if="i <= 4">
                  {{ item.name }}
                </span>
              </span>
              <span> ... </span>
            </a-tooltip>
          </span>
          <span v-else>
            <span v-for="(item, i) in text" :key="i">
              <span class="moduleInfo">
                {{ item.name }}
              </span>
            </span>
          </span>
        </span>

        <span slot="type" slot-scope="text, record">
          <a-switch
            default-checked
            @change="(checked) => onChange(checked, record.name)"
          />
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>

          <a-divider type="vertical" />

          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "测评卷名称",
    dataIndex: "name",
    // align: 'center',
    key: "name",
    width: 260,
    scopedSlots: { customRender: "assess_name" },
  },
  // {
  //   title: '学校',
  //   dataIndex: 'schoolName',
  //   // align: 'center',
  //   key: 'schoolName',
  //   width: 260,
  //   ellipsis: true,
  // },
  {
    title: "学校",
    dataIndex: "school_info",
    // align: 'center',
    key: "school_info",
    width: 260,
    scopedSlots: { customRender: "school_info" },
  },
  {
    title: "学段",
    dataIndex: "section_name",
    // align: 'center',
    key: "section_name",
    width: 160,
  },

  {
    title: "模块",
    dataIndex: "module_info",
    // align: 'center',
    key: "module_info",
    width: 300,
    scopedSlots: { customRender: "module_info" },
  },
  {
    title: "创建人",
    dataIndex: "speed",
    // align: 'center',
    key: "speed",
    width: 100,
    customRender: function (t, r) {
      return r.created_by_info.name;
    },
  },
  {
    title: "电话",
    dataIndex: "province_name2",
    // align: 'center',
    key: "province_name2",
    width: 150,
    customRender: function (t, r) {
      return r.created_by_info.phone;
    },
  },
  {
    title: "创建时间",
    dataIndex: "created_at_str",
    // align: 'center',
    key: "created_at_str",
    width: 150,
  },
  // {
  //   title: '状态',
  //   dataIndex: 'type',
  //   // align: 'center',
  //   key: 'type',
  //   width: 100,
  //   scopedSlots: { customRender: 'type' },
  // },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

import addFrom from "./addFrom";

export default {
  name: "examination",
  components: {
    addFrom,
  },
  created() {
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));
    this.getTable();
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      speedList: [
        {
          value: 1,
          label: "未开始",
        },
        {
          value: 2,
          label: "进行中",
        },
        {
          value: 3,
          label: "已完成",
        },
      ],
      sectionList: [],
    };
  },
  methods: {
    onChange(checked, name) {
      console.log(`a-switch to ${checked}`);
      console.log(name);
    },
    getTable(school_name = "", section = "", name = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/assessments", {
          expand:
            "section_name,school_info,module_info,question_count,created_at_str,created_by_info",
          page: this.pagination.current,
          "per-page": 10,
          name,
          section,
          school_name,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;

            this.data = res.data.list.map((item) => {
              let schoolName = item.school_info.map((i) => {
                return i.name;
              });
              item.schoolName = schoolName.join("，");

              let moduleName = item.module_info.map((y) => {
                return y.name;
              });
              item.moduleName = moduleName.join("，");
              return item;
            });

            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(this.form.school_name, this.form.section, this.form.name);
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      // alert('暂无')
      this.$refs.addFrom.addFrom();
    },
    editRecord(record) {
      // console.log('editRecord')
      // console.log(record)
      // alert('暂无')
      this.$refs.addFrom.editFrom(record);
    },
    inFrom() {
      console.log("inFrom--导入");
    },
    delFrom() {
      console.log("delFrom--多选删除");

      if (this.selectedRowKeys.length < 1) {
        this.$message.warning("请选勾选");
        return false;
      }

      this.$api
        .delAction("/admin/v1/assessments/batch-delete", "", {
          id: this.selectedRowKeys,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.selectedRowKeys = [];
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);

      let key = this.selectedRowKeys.indexOf(record.id);
      if (key > -1) {
        this.selectedRowKeys.splice(key, 1);
      }

      this.$api.delAction("/admin/v1/assessments", record.id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          // this.$message.success(res.msg)
          this.getTable();
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]]);
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(this.form.school_name, this.form.section, this.form.name);
    },
  },
};
</script>

<style scoped lang="less">
.btnRegion {
  .time {
    font-size: 16px;
    color: #343a40;
    .big {
      color: #22bb7e;
      font-size: 36px;
      padding: 0 10px;
    }
  }
}
.speedStyle {
  display: flex;
  justify-content: space-between;
}
p.assessName {
  // color: #007cae;
  margin-bottom: 0;
  cursor: pointer;

  & + p {
    padding: 5px 0;
    font-size: 12px;
    color: #aab2b8;
  }
}
.moduleInfo {
  background: rgba(0, 124, 174, 0.06);
  border-radius: 4px;
  padding: 4px;
  color: #007cae;
  margin: 5px;
  font-size: 12px;
  display: inline-block;
}
p.schoolName {
  margin-bottom: 0;
}
</style>
