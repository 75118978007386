<template>
  <a-drawer
    title="测评管理"
    placement="right"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="测试卷名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item label="测试卷简介" prop="desc">
        <a-input v-model="form.desc" type="textarea" :rows="4" />
      </a-form-model-item>

      <a-form-model-item label="学段" prop="section">
        <a-select
          v-model="form.section"
          placeholder="请选择学段"
          @change="sectionChange"
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in sectionList"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="学校" prop="school">
        <a-transfer
          :data-source="schoolList"
          show-search
          :filter-option="filterOption"
          :target-keys="schoolKeys"
          :render="(item) => item.title"
          @change="handleChange"
        />
      </a-form-model-item>

      <a-form-model-item label="模块" prop="modular">
        <a-tabs
          default-active-key="1"
          tab-position="left"
          :style="{
            height: '300px',
            border: '1px solid #d9d9d9',
            padding: '10px',
            'border-radius': '5px',
          }"
        >
          <a-tab-pane key="1" tab="可选">
            <a-checkbox-group @change="optionalChange" :value="optionalKeys">
              <a-row :gutter="[32, 16]">
                <a-col :span="24" v-for="(item, i) in optionalKeyList" :key="i">
                  <a-checkbox :value="item.id"> {{ item.name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-tab-pane>
          <a-tab-pane key="2" tab="干扰">
            <a-checkbox-group @change="mustChange" :value="mustKeys">
              <a-row :gutter="[32, 16]">
                <a-col :span="24" v-for="(item, i) in mustKeyList" :key="i">
                  <a-checkbox :value="item.id"> {{ item.name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-tab-pane>
          <a-tab-pane key="3" tab="特殊">
            <a-checkbox-group @change="specialChange" :value="specialKeys">
              <a-row :gutter="[32, 16]">
                <a-col :span="24" v-for="(item, i) in specialKeyList" :key="i">
                  <a-checkbox :value="item.id"> {{ item.name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-tab-pane>
          <a-tab-pane key="4" tab="自定义">
            <a-checkbox-group @change="customChange" :value="customKeys">
              <a-row :gutter="[32, 16]">
                <a-col :span="24" v-for="(item, i) in customKeyList" :key="i">
                  <a-checkbox :value="item.id"> {{ item.name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-tab-pane>
          <a-tab-pane key="5" tab="欺凌">
            <a-checkbox-group @change="customChange1" :value="customKeys1">
              <a-row :gutter="[32, 16]">
                <a-col :span="24" v-for="(item, i) in customKeyList1" :key="i">
                  <a-checkbox :value="item.id"> {{ item.name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-tab-pane>
          <a-tab-pane key="6" tab="家庭冲突">
            <a-checkbox-group @change="familyConflicChange" :value="familyConflicKey">
              <a-row :gutter="[32, 16]">
                <a-col :span="24" v-for="(item, i) in familyConflictList" :key="i">
                  <a-checkbox :value="item.id"> {{ item.name }} </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </a-tab-pane>
        </a-tabs>
      </a-form-model-item>

      <a-form-model-item label="考试时长(分钟)" prop="duration">
        <a-input-number v-model="form.duration" />
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 生成测评卷 </a-button>
        <a-button @click="resetForm" class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import moment from "moment";
export default {
  name: "",
  data() {
    return {
      visible: false,
      visibleModal: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {
        duration: 60,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入测试卷名称",
            trigger: "blur",
          },
        ],
        desc: [
          {
            required: true,
            message: "请输入测试卷简介",
            trigger: "blur",
          },
          { min: 1, max: 1024, message: "最大长度为1024字符", trigger: "blur" },
        ],
        section: [
          {
            required: true,
            message: "请选择学段",
            trigger: "blur",
          },
        ],
        duration: [
          {
            required: true,
            message: "请输入时长",
            trigger: "blur",
          },
        ],
        school: [
          {
            required: true,
            validator: this.schoolFn,
            trigger: "blur",
          },
        ],
        modular: [
          {
            required: true,
            validator: this.modularFn,
            trigger: "blur",
          },
        ],
      },

      // 判断是 add 还是edit
      addFlag: true,

      // 学段
      sectionList: [],

      // 学校树
      schoolList: [],
      schoolListAll: [],
      schoolKeys: [],

      // 模块
      modularList: [],

      // 必选
      mustKeyList: [],
      mustKeys: [],
      // 可选
      optionalKeyList: [],
      optionalKeys: [],
      // 特殊
      specialKeyList: [],
      specialKeys: [],
      // 自定义
      customKeyList: [],
      customKeys: [],
      //欺凌
      customKeyList1: [],
      // 家庭冲突
      familyConflictList: [],
      familyConflicKey: [],
      customKeys1: [],
    };
  },
  watch: {},
  created() {
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));
    // this.schoolListAll = JSON.parse(sessionStorage.getItem('schoolList'))
    this.getStatic();
    this.getmodularList();
  },
  methods: {
    moment,
    getStatic() {
      // 获取 所有的 学校
      this.$api
        .getAction("/admin/v1/common/static", {
          data_name: "school",
        })
        .then((res) => {
          this.schoolListAll = res.data.school;
        });
    },
    optionalChange(checkedValues) {
      console.log("optionalChange = ", checkedValues);
      this.optionalKeys = checkedValues;
    },
    mustChange(checkedValues) {
      console.log("mustChange = ", checkedValues);
      this.mustKeys = checkedValues;
    },
    specialChange(checkedValues) {
      console.log("specialChange = ", checkedValues);
      this.specialKeys = checkedValues;
    },
    customChange(checkedValues) {
      console.log("customChange = ", checkedValues);
      this.customKeys = checkedValues;
    },
    customChange1(checkedValues) {
      console.log("customChange = ", checkedValues);
      this.customKeys1 = checkedValues;
    },
    familyConflicChange(checkedValues) {
      this.familyConflicKey = checkedValues;
    },
    sectionChange(value) {
      // 通过学段获取对应学校信息
      this.getSchoolList(value);
    },
    getSchoolList(val = "") {
      console.log(val);
      // 获取所有学校
      let schoolList = this.schoolListAll.filter((item) => {
        return val == item.section;
      });
      console.log(schoolList);
      this.schoolList = schoolList.map((item) => {
        return { key: item.id + "", title: item.name };
      });
    },
    getmodularList() {
      // 获取所有模块 区分模块类型
      this.$api
        .getAction("/admin/v1/modules", {
          // 写死？？？？
          "per-page": 100,
        })
        .then((res) => {
          if (res.code == 0) {
            //  1-可选 2-干扰 3-特殊 4-自定义
            this.optionalKeyList = res.data.list.filter((item) => {
              return item.type == 1;
            });
            this.mustKeyList = res.data.list.filter((item) => {
              return item.type == 2;
            });
            this.specialKeyList = res.data.list.filter((item) => {
              return item.type == 3;
            });
            this.customKeyList = res.data.list.filter((item) => {
              return item.type == 4;
            });
            this.customKeyList1 = res.data.list.filter((item) => {
              return item.type == 5;
            });
            this.familyConflictList = res.data.list.filter((item) => {
              return item.type == 6;
            });
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    onSubmit() {
      this.form.school_id = this.schoolKeys.map((item) => parseInt(item));
      // console.log(this.mustKeys)
      // console.log(this.optionalKeys)
      // console.log(this.specialKeys)
      console.log(this.customKeys);
      this.form.module_id = this.mustKeys
        .concat(this.optionalKeys)
        .concat(this.specialKeys)
        .concat(this.customKeys)
        .concat(this.familyConflicKey)
        .concat(this.customKeys1);
      console.log(this.form);

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.addFlag) {
            this.$api
              .postAction('/admin/v1/assessments', this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.onClose()
                  this.$parent.getTable()
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]])
                    this.$message.error(res.data[Object.keys(res.data)[0]])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              })
          } else {
            this.$api
              .putAction('/admin/v1/assessments', this.form.id, this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.onClose()
                  this.$parent.getTable()
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]])
                    this.$message.error(res.data[Object.keys(res.data)[0]])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.onClose();
    },
    addFrom() {
      this.addFlag = true;
      this.form = {
        duration: 60,
      };
      console.log("addFrom");
      this.visible = true;
    },
    editFrom(record) {
      this.addFlag = false;
      console.log("editFrom");
      console.log(record);
      this.form = record;

      this.getSchoolList(record.section);

      this.schoolKeys = record.school_info.map((item) => {
        return item.id + "";
      });

      console.log(record.module_id);

      console.log(this.optionalKeyList);
      console.log(this.mustKeyList);
      console.log(this.specialKeyList);

      // 干扰
      this.mustKeys = this.mustKeyList.map((item) => {
        if (record.module_id.indexOf(item.id) > -1) {
          return item.id;
        }
      });
      // 可选
      this.optionalKeys = this.optionalKeyList.map((item) => {
        if (record.module_id.indexOf(item.id) > -1) {
          return item.id;
        }
      });
      // 特殊
      this.specialKeys = this.specialKeyList.map((item) => {
        if (record.module_id.indexOf(item.id) > -1) {
          return item.id;
        }
      });
      // 自定义
      this.customKeys = this.customKeyList.map((item) => {
        if (record.module_id.indexOf(item.id) > -1) {
          return item.id;
        }
      });
      //欺凌
      this.customKeys1 = this.customKeyList1.map((item) => {
        if (record.module_id.indexOf(item.id) > -1) {
          return item.id;
        }
      });
      // 家庭冲突
      this.familyConflicKey = this.familyConflictList.map((item) => {
        if (record.module_id.indexOf(item.id) > -1) {
          return item.id;
        }
      });
      console.log("this.mustKeys");
      console.log(this.mustKeys);
      console.log(this.optionalKeys);
      console.log(this.specialKeys);

      this.visible = true;
    },
    onClose() {
      console.log("onClose");
      this.$refs.ruleForm.resetFields();
      this.form = {};

      // 清除 目录树选中项
      this.schoolKeys = [];
      this.schoolList = [];

      // 干扰
      this.mustKeys = [];
      // 可选
      this.optionalKeys = [];
      // 特殊
      this.specialKeys = [];
      this.customKeys1 = []
      this.familyConflicKey = []
      this.visible = false;
    },
    handleChange(targetKeys, direction, moveKeys) {
      console.log("handleChange--");
      console.log(targetKeys, direction, moveKeys);
      this.schoolKeys = targetKeys;
    },
    filterOption(inputValue, option) {
      return option.title.indexOf(inputValue) > -1;
    },

    schoolFn(rule, value, callback) {
      console.log("value-*-schoolFn");
      console.log(this.schoolKeys);
      // 如果为空值，就抛出错误
      if (this.schoolKeys.length == 0) {
        callback(new Error("请选择学校"));
      } else {
        callback();
      }
    },
    timeFn(rule, value, callback) {
      console.log("value-*-timeFn");
      // console.log(this.form.start_time)
      // console.log(this.form.end_time)
      // 如果为空值，就抛出错误
      if (
        this.form.start_time == "" ||
        this.form.end_time == "" ||
        this.form.start_time == undefined ||
        this.form.end_time == undefined
      ) {
        callback(new Error("请选择起止时间"));
      } else {
        callback();
      }
    },
    modularFn(rule, value, callback) {
      console.log("value-*-timeFn");

      let arr = this.mustKeys
        .concat(this.optionalKeys)
        .concat(this.specialKeys);

      // 如果为空值，就抛出错误
      if (arr.length == 0) {
        callback(new Error("请选择模块"));
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped lang="less">
:deep(.ant-col-14) {
  width: 80%;
}

:deep(.ant-form) {
  .ant-transfer-list {
    height: 400px;
    width: calc(50% - 20px);
  }

  .ant-checkbox-group {
    .ant-row {
      overflow: auto;
      height: 300px;
    }
  }

  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: center;
  }
}

:deep(.ant-drawer-body) {
  .ant-col-4 {
    width: 20%;
  }

  .ant-input-number {
    width: 100%;
  }
}
</style>
