<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
        labelAlign="right"
      >
        <a-row>
          <a-col :span="6">
            <a-form-model-item label="学校">
              <a-input v-model="form.name" placeholder="请输入学校" />
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item label="校区">
              <a-input v-model="form.campus" placeholder="请输入校区" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="手机号">
              <a-input
                v-model="form.contact_phone"
                placeholder="请输入手机号"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item>
              <a-button html-type="submit" class="btnGreenFull" icon="search">
                搜索
              </a-button>
              <a-button class="btnGreen ml" @click="reset" icon="sync">
                重置
              </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="6">
            <a-form-model-item label="省" prop="province_id">
              <a-select
                v-model="form.province_id"
                placeholder="请选择省份"
                @change="provinceChange"
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in provinceList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="市" prop="city_id">
              <a-select
                v-model="form.city_id"
                placeholder="请选择城市"
                @change="cityChange"
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in cityList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="区" prop="district_id">
              <a-select v-model="form.district_id" placeholder="请选择县区">
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in districtList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>

        <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="delFrom"
        >
          <template slot="title">
            <p>确认删除吗?</p>
          </template>
          <a-button class="ml" icon="delete"> 删除 </a-button>
        </a-popconfirm>

        <a-upload
          name="file"
          :multiple="false"
          :show-upload-list="false"
          @change="handleChange"
          :customRequest="customRequest"
          v-if="!loading"
        >
          <a-button class="btnIn ml">
            <a-icon type="upload" /> 导入模板
          </a-button>
        </a-upload>
        <a-button v-else loading class="ml">模板导入中，请稍等 </a-button>

        <a :href="outUrl + '/report/template/school.xlsx'">
          <a-button class="btnIn ml" icon="download"> 导出学校空模板 </a-button>
        </a>

        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
      </div>

      <a-table
        :scroll="{ x: 1500, y: 520 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <!-- <p style="text-align: center; margin-bottom: 0px">删除</p> -->
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "学校名称",
    dataIndex: "name",
    // align: 'center',
    key: "name",
  },
  {
    title: "校区",
    dataIndex: "campus",
    // align: 'center',
    key: "campus",
  },
  {
    title: "省",
    dataIndex: "province_name",
    // align: 'center',
    key: "province_name",
  },
  {
    title: "市",
    dataIndex: "city_name",
    // align: 'center',
    key: "city_name",
  },
  {
    title: "县区",
    dataIndex: "district_name",
    // align: 'center',
    key: "district_name",
  },
  {
    title: "学段",
    dataIndex: "section_name",
    // align: 'center',
    key: "section_name",
  },
  {
    title: "联系人姓名",
    dataIndex: "contact_name",
    // align: 'center',
    key: "contact_name",
  },
  {
    title: "手机号",
    dataIndex: "contact_phone",
    // align: 'center',
    key: "contact_phone",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

import addFrom from "./addFrom";

export default {
  name: "schoolInfo",
  components: {
    addFrom,
  },
  created() {
    this.provinceList = JSON.parse(sessionStorage.getItem("provinceList"));
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));

    this.getTable();
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        // hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      outUrl: process.env.VUE_APP_API_BASE_URL,

      // 省市区
      provinceList: [],
      cityList: [],
      districtList: [],
    };
  },
  methods: {
    provinceChange(value) {
      console.log(`selected ${value}`);
      let city = this.provinceList.filter((item) => item.id == value);
      console.log(city);
      this.cityList = city[0].list;
      console.log(this.cityList);
    },
    cityChange(value) {
      console.log(`selected ${value}`);
      let district = this.cityList.filter((item) => item.id == value);
      console.log(district);
      this.districtList = district[0].list;
      console.log(this.districtList);
    },
    customRequest(data) {
      let formData = new FormData();
      console.log(data.file);

      formData.append("attachment", data.file);

      console.log(formData);

      this.saveFile(formData);
    },
    saveFile(formData) {
      this.loading = true;
      this.$message.warning("数据导入中，请稍等");
      // /consumer/importOrder
      this.$api
        .postAction("/admin/v1/schools/import", formData)
        .then((res) => {
          console.log(res);

          if (res.code == 0) {
            this.$message.success(res.msg);

            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
            this.loading = false;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    getTable(
      name = "",
      campus = "",
      contact_phone = "",
      province_id = "",
      city_id = "",
      district_id = ""
    ) {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/schools", {
          expand: "province_name,city_name,district_name,section_name",
          page: this.pagination.current,
          name,
          campus,
          contact_phone,
          "per-page": 10,
          province_id,
          city_id,
          district_id,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
          this.loading = false;
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(
        this.form.name,
        this.form.campus,
        this.form.contact_phone,
        this.form.province_id,
        this.form.city_id,
        this.form.district_id
      );
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      this.$refs.addFrom.addFrom();
    },
    editRecord(record) {
      console.log("editRecord");
      console.log(record);
      this.$refs.addFrom.editFrom(record);
    },
    delFrom() {
      console.log("delFrom--多选删除");

      if (this.selectedRowKeys.length < 1) {
        this.$message.warning("请选勾选");
        return false;
      }

      this.$api
        .delAction("/admin/v1/schools/batch-delete", "", {
          id: this.selectedRowKeys,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.selectedRowKeys = [];
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      // console.log(record)

      let key = this.selectedRowKeys.indexOf(record.id);
      if (key > -1) {
        this.selectedRowKeys.splice(key, 1);
      }

      this.$api.delAction("/admin/v1/schools", record.id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          // this.$message.success(res.msg)
          this.getTable();
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]]);
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(
        this.form.name,
        this.form.campus,
        this.form.contact_phone,
        this.form.province_id,
        this.form.city_id,
        this.form.district_id
      );
    },
  },
};
</script>

<style scoped lang="less">
.bodyTop {
  height: 130px;
  :deep(.ant-select-selection--single .ant-select-selection__rendered) {
    width: 100px;
  }

  :deep(.ant-form-inline .ant-form-item > .ant-form-item-label) {
    width: 55px;
  }
}
</style>
