import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './css/changeCss.less'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)

import api from './api/api'
Vue.prototype.$api = api

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
