<template>
  <div class="questionBank">
    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>
        <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="delFrom"
        >
          <template slot="title">
            <p>确认删除吗?</p>
          </template>
          <a-button class="ml" icon="delete"> 删除 </a-button>
        </a-popconfirm>
        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
      </div>

      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.name"
      >
        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <!-- <p style="text-align: center; margin-bottom: 0px">删除</p> -->
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    dataIndex: '',
    key: 'rowIndex',
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1
    },
  },
  {
    title: '角色名称',
    dataIndex: 'name',
    // align: 'center',
    key: 'name',
    width: 150,
  },
  {
    title: '角色权限',
    dataIndex: 'permissions_name',
    // align: 'center',
    key: 'permissions_name',
  },
  {
    title: '创建时间',
    dataIndex: 'created_at_str',
    // align: 'center',
    key: 'created_at_str',
    width: 150,
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    width: 200,
    scopedSlots: { customRender: 'action' },
  },
]

import addFrom from './addFrom'

export default {
  name: 'questionBank',
  components: {
    addFrom,
  },
  created() {
    this.getTable()
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
      },
    }
  },
  methods: {
    getTable() {
      this.loading = true
      this.$api
        .getAction('/admin/v1/roles', {
          expand: 'created_at_str,permissions_name,permissions',
          page: this.pagination.current,
          'per-page': 10,
        })
        .then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount
            this.data = res.data.list.map((item) => {
              item.permissions_name = item.permissions_name.join(' , ')

              return item
            })
            this.loading = false
          } else {
            this.$message.error(res.msg)
          }
        })
    },
    handleSubmit() {
      console.log(this.form)
    },
    reset() {
      console.log('reset')
      this.form = {}
    },
    addFrom() {
      console.log('addFrom')
      this.$refs.addFrom.addFrom()
    },
    editRecord(record) {
      console.log('editRecord')
      console.log(record)
      this.$refs.addFrom.editFrom(record)
    },
    delFrom() {
      console.log('delFrom--多选删除')
      console.log(this.selectedRowKeys)

      if (this.selectedRowKeys.length < 1) {
        this.$message.warning('请选勾选')
        return false
      }
      
      this.$api
        .delAction('/admin/v1/roles/batch-delete', '', {
          id: this.selectedRowKeys,
        })
        .then((res) => {
          console.log(res)
          if (res.code == 0) {
            this.getTable()
            this.selectedRowKeys = []
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]])
              this.$message.error(res.data[Object.keys(res.data)[0]])
            } else {
              this.$message.error(res.msg)
            }
          }
        })
    },
    delRecord(record) {
      console.log('delRecord')
      console.log(record)

      this.$api.delAction('/admin/v1/roles', record.name).then((res) => {
        console.log(res)
        if (res.code == 0) {
          // this.$message.success(res.msg)
          this.getTable()
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]])
            this.$message.error(res.data[Object.keys(res.data)[0]])
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys)
      this.selectedRowKeys = selectedRowKeys
    },
    tableChange(pagination) {
      console.log(pagination.current)
      this.pagination.current = pagination.current
    },
  },
}
</script>

<style scoped></style>
