<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学校">
          <a-input v-model="form.school_name" placeholder="请输入学校" />
        </a-form-model-item>

        <a-form-model-item label="学生姓名">
          <a-input v-model="form.student_name" placeholder="请输入学生姓名" />
        </a-form-model-item>

        <!-- <a-form-model-item label="学段" prop="">
          <a-select
            v-model="form.section"
            placeholder="请选择进度"
            style="width: 150px"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in speedList"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->

        <a-form-model-item label="评测卷">
          <a-input v-model="form.assess_name" placeholder="请输入评测卷" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>

          <a-button class="btnGreen ml" @click="resetCe" icon="sync">
            批量重测
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <!-- :row-selection="{
      selectedRowKeys: selectedRowKeys,
      onChange: onSelectChange,
    }" -->
    <div class="bodyMain">
      <a-table
        :scroll="{ x: 1500, y: 570 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="risk_module_name" slot-scope="text, record">
          <span v-if="text.length > 4">
            <a-tooltip placement="topLeft">
              <template slot="title">{{ record.moduleName }} </template>
              <span v-for="(item, i) in text" :key="i">
                <span class="moduleInfo" v-if="i <= 4">
                  {{ item }}
                </span>
              </span>
              <span> ... </span>
            </a-tooltip>
          </span>
          <span v-else>
            <span v-for="(item, i) in text" :key="i">
              <span class="moduleInfo">
                {{ item }}
              </span>
            </span>
          </span>
        </span>

        <span slot="speed" slot-scope="text">
          <a-icon type="close" v-if="text" style="color: #ff5655" />
          <a-icon type="check" v-else style="color: #22bb7e" />
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record.id)">
            查看评测卷
          </a-button>

          <a-button
            class="btnOrg ml"
            @click="againFn(record.id)"
            v-if="record.validity_desc.indexOf('无效') > -1"
          >
            重新测评
          </a-button>
        </span>
      </a-table>
    </div>

    <a-modal
      v-model="visibleModal"
      title="抑郁压力测量表测试"
      :closable="false"
      :footer="null"
      :width="800"
    >
      <div v-for="(item, i) in normalOption" :key="i">
        <p :class="['title', item.level == 3 ? 'red' : '']">
          #{{ item.module_name }}
        </p>
        <p v-for="(op, y) in item.question_answer" :key="y">
          <span>{{ y + 1 }}</span>
          <span :title="op.question_name">{{ op.question_name }}</span>
          <span>{{ op.option_label }}</span>
        </p>
      </div>
    </a-modal>

    <a-modal
      v-model="schoolModal"
      title="学校批量重新测试"
      :closable="false"
      :footer="null"
      :width="1000"
    >
      <schoolRe ref="schoolRe"></schoolRe>
    </a-modal>
  </div>
</template>

<script>
import schoolRe from "./schoolRe.vue";
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "学校",
    dataIndex: "school_name",
    // align: 'center',
    key: "school_name",
  },
  {
    title: "测试卷名称",
    dataIndex: "assess_name",
    // align: 'center',
    key: "assess_name",
  },
  {
    title: "学生姓名",
    dataIndex: "student_name",
    // align: 'center',
    key: "student_name",
  },
  {
    title: "问题卷有效性",
    dataIndex: "validity_desc",
    // align: 'center',
    key: "validity_desc",
  },
  {
    title: "社会赞许性",
    dataIndex: "prettify_module_id",
    // align: 'center',
    key: "prettify_module_id",
    customRender: function (t) {
      return t != 0 ? "未通过" : "通过";
    },
  },
  {
    title: "注意力检测题",
    dataIndex: "invalid_module_id",
    // align: 'center',
    key: "invalid_module_id",
    customRender: function (t) {
      return t != 0 ? "未通过" : "通过";
    },
  },
  {
    title: "备注",
    dataIndex: "invalid_module_score_desc",
    // align: 'center',
    key: "invalid_module_score_desc",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 250,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
  // {
  //   title: "年级/班级",
  //   dataIndex: "campus",
  //   // align: 'center',
  //   key: "campus",
  //   customRender: function (t, r) {
  //     return r.grade + "年级" + r.class;
  //   },
  // },
  // {
  //   title: "风险模块",
  //   dataIndex: "risk_module_name",
  //   // align: 'center',
  //   key: "risk_module_name",
  //   width: 300,
  //   scopedSlots: { customRender: "risk_module_name" },
  // },
  // {
  //   title: "答题用时",
  //   dataIndex: "take_time",
  //   // align: 'center',
  //   key: "take_time",
  // },
  // {
  //   title: "无效问卷",
  //   dataIndex: "invalid_module_id",
  //   // align: 'center',
  //   key: "invalid_module_id",
  //   scopedSlots: { customRender: "speed" },
  // },
  // {
  //   title: "提交时间",
  //   dataIndex: "end_at_str",
  //   // align: 'center',
  //   key: "end_at_str",
  // },
];

export default {
  name: "question",
  components: { schoolRe },
  created() {
    this.getTable();
  },
  data() {
    return {
      visibleModal: false,
      // visibleModal: true,
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      speedList: [
        {
          value: 0,
          label: "未开始",
        },
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已完成",
        },
      ],

      normalOption: [],

      schoolModal: false,
    };
  },
  methods: {
    againFn(id) {
      console.log(id);
      this.$api
        .postAction("/admin/v1/trouble-papers/re-assess", { id: id })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    getTable(school_name = "", student_name = "", assess_name = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/trouble-papers", {
          expand:
            "school_name,student_name,assess_name,risk_module_name,end_at_str,take_time,validity_desc,invalid_module_score_desc",
          page: this.pagination.current,
          "per-page": 10,
          school_name,
          student_name,
          assess_name,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              item.moduleName = item.risk_module_name.join("，");
              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(
        this.form.school_name,
        this.form.student_name,
        this.form.assess_name
      );
    },
    resetCe() {
      console.log("重新测评");
      this.schoolModal = true;
      if (this.$refs.schoolRe) {
        this.$refs.schoolRe.selectedRowKeys = [];
      }
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      // this.$refs.addFrom.addFrom()
    },
    editRecord(id) {
      console.log("editRecord");
      console.log(id);
      // this.$refs.addFrom.editFrom(record)
      this.visibleModal = true;

      this.$api
        .getAction("/admin/v1/trouble-papers/" + id, {
          expand: "assess_name,assess_answer",
          // student_assess_id: id,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            console.log(res.data.assess_answer);
            this.normalOption = res.data.assess_answer;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    inFrom() {
      console.log("inFrom--导入");
    },
    delFrom() {
      console.log("delFrom--多选删除");
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);

      this.$api.delAction("/admin/v1/schools", record.id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.selectedRowKeys = [];
          this.getTable();
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]]);
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(
        this.form.school_name,
        this.form.student_name,
        this.form.assess_name
      );
    },
  },
};
</script>

<style scoped lang="less">
.btnRegion {
  .time {
    font-size: 16px;
    color: #343a40;
    .big {
      color: #22bb7e;
      font-size: 36px;
      padding: 0 10px;
    }
  }
}
.speedStyle {
  display: flex;
  justify-content: space-between;
}

.moduleInfo {
  background: rgba(0, 124, 174, 0.06);
  border-radius: 4px;
  padding: 4px;
  color: #007cae;
  margin: 5px;
  font-size: 12px;
  display: inline-block;
}

:deep(.ant-modal) {
  .ant-modal-content {
    // height: 600px;
    // overflow-y: auto;
    padding: 12px 12px 24px;

    .ant-modal-title {
      text-align: center;
      color: #007cae;
      font-size: 16px;
    }
    .ant-modal-body {
      height: 600px;
      overflow-y: auto;

      p {
        height: 53px;
        line-height: 53px;
        margin-bottom: 0;
        border-bottom: 1px solid #eeeeee;
        padding: 0 20px;

        span:nth-child(2) {
          padding-left: 24px;
          width: 550px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        span:nth-child(1) {
          float: left;
        }
        span:nth-child(3) {
          float: right;
        }
      }
      p.title {
        background: #fafafa;
        border-radius: 4px 4px 0px 0px;
        color: #007cae;
      }
      p.title.red {
        color: #ff5655;
      }
    }
    .ant-modal-body::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
