<template>
  <div ref="question">
    <a-drawer
      title="添加筛选项"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="resetForm"
      :width="640"
    >
      <a-form-model :model="form" labelAlign="left">
        <!-- <a-form-model-item label="教育局名称">
          <a-input v-model="form.edu_name" placeholder="请输入教育局名称" />
        </a-form-model-item> -->
        <a-form-model-item label="测评卷">
          <a-checkbox-group @change="onChange" style="width: 100%;">
            <a-row>
              <a-col v-for="(item,index) in list" :key="index" :span="24" class="example" style="margin: 5px 0;">
                <a-checkbox :value="item.id" style="width: 100%;white-space: pre-wrap !important; word-break: break-all; word-wrap: break-word;">{{ item.name }}</a-checkbox>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
          <a-button @click="resetForm" class="ml"> 取消 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      visible: false,
      form: {
        // edu_name: '',
        assess_ids: []
      },
      list: [],
    };
  },
  watch: {},
  created() {},
  mounted(){
    this.getList()
  },
  methods: {
    onChange(checkedValues) {
      this.form.assess_ids = checkedValues
    },
    getList() {
      this.$api.getAction("/admin/v1/region-data-visualizations/assess-list", {}).then((res) => {
        if (res.code == 0) {
          // this.$message.success(res.msg);
          this.list = res.data
        } else {
          if (res.data !== undefined) {
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      })
    },
    onSubmit() {
      // if(!this.form.edu_name){
      //   this.$message.warning('请填写教育部名称');
      //   return false
      // }
      if(!this.form.assess_ids.length){
        this.$message.warning('请选择问卷');
        return false
      }
      this.$api.postAction("/admin/v1/region-data-visualizations", this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.resetForm();
          this.$parent.getTable();
        } else {
          if (res.data !== undefined) {
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    resetForm() {
      this.form = {};
      this.visible = false;
    },
    //data 是问卷数据
    addFrom() {
      this.visible = true;
    },
    afterVisibleChange(val) {
      console.log("触发以及结束时的回调", val);
    },
  },
};
</script>

<style scoped lang="less">
:deep(.ant-drawer-body) {
  .ant-col-4 {
    width: 20%;
  }

  .ant-input-number {
    width: 100%;
  }
}

</style>
