<template>
  <div class="report">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学校">
          <a-input v-model="form.school_name" placeholder="请输入学校" />
        </a-form-model-item>

        <a-form-model-item label="学段" prop="">
          <a-select
            v-model="form.section"
            placeholder="请选择学段"
            style="width: 150px"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in sectionList"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="评测卷">
          <a-input v-model="form.assess_name" placeholder="请输入评测卷" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <!-- <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>
        <a-button class="" @click="delFrom" icon="delete"> 删除 </a-button>
        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
      </div> -->

      <!-- :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }" -->
      <a-table
        :scroll="{ x: 1500, y: 580 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span
          slot="action"
          slot-scope="text, record"
          style="display: flex; flex-direction: row;justify-content: center;"
        >
          <!-- <a-button
            class="btnIn"
            @click="print(record.report_path)"
            v-if="record.report_path != ''"
          >
            查看并打印报告
          </a-button>

          <a-button
            class="btnOrg ml"
            @click="changePrint(record.id)"
            v-if="record.report_path == ''"
          >
            立即生成报告
          </a-button>
          <a-button
            class="btnOrg ml"
            @click="daoPrint(record.id, record.school_info.name)"
          >
            导出
          </a-button> -->
          <a-button
            class="btnIn ml"
            :disabled="record.lock"
            :class="{gray:record.lock}"
            @click="
              xiaZai(
                record.id,
                record.school_info.name,
                record.student_report_path,
                record.assess_name,record
              )
            "
            v-if="record.student_report_path != ''"
          >
            下载报告
          </a-button>
          <!-- <a-button class="btnOrg ml" @click="editRecord(record)">
            查看报告
          </a-button>
          <a-divider type="vertical" />
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm> -->
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "学校",
    dataIndex: "school_info",
    // align: 'center',
    key: "school",
    width: 260,
    customRender: function (t) {
      return t.name;
    },
  },
  {
    title: "学段",
    dataIndex: "school_info",
    // align: 'center',
    key: "section",
    width: 260,
    customRender: function (t) {
      return t.section_name;
    },
  },
  {
    title: "测试卷流水",
    dataIndex: "id",
    width: 260,
    // align: 'center',
    key: "id",
  },

  {
    title: "测评报告",
    dataIndex: "assess_name",
    // align: 'center',
    width: 260,
    key: "assess_name",
  },

  {
    title: "操作",
    key: "action",
    align: "center",
    width: 150,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
import moment from "moment";
export default {
  name: "personalReport",
  components: {},
  created() {
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));

    this.getTable();
  },
  data() {
    return {
      visibleModal: false,
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
    };
  },
  methods: {
    moment,
    //导出
    // daoPrint(id, str) {
    //   str = str.trim();
    //   console.log(id);
    //   console.log(str);
    //   let name =
    //     str + "学生答题原始数据" + moment().startOf("day").format("YYYYMMDD");
    //   // console.log(name);
    //   const a = document.createElement("a");
    //   let url =
    //     process.env.VUE_APP_API_BASE_URL +
    //     "/api/admin/v1/school-assessments/student-excel?id=" +
    //     id;

    //   // console.log(url);
    //   // 这里是将url转成blob地址，
    //   fetch(url)
    //     .then((res) => res.blob())
    //     .then((blob) => {
    //       // 将链接地址字符内容转变成blob地址
    //       a.href = URL.createObjectURL(blob);
    //       // 下载文件的名字
    //       a.download = name;
    //       document.body.appendChild(a);
    //       a.click();
    //     });
    // },
    // 下载学生个人报告
    xiaZai(id, str, xinxi, e,record) {
      if(record.lock){
        this.$message.warning('不可重复点击,请于一分钟后再试');
        return false
      }
      str = str.trim();
      console.log("id", id);
      console.log("str", str);
      console.log("xinxi", xinxi);
      console.log("e", e);
      let name = str + e + "学生个人报告";
      console.log("name", name);
      const a = document.createElement("a");
      let url = process.env.VUE_APP_API_BASE_URL + xinxi;
      console.log("url", url);
      a.href = url;
      // 下载文件的名字
      a.download = name;
      document.body.appendChild(a);
      a.click();
      this.data.filter(item => item.id == id)[0].lock = true
      setTimeout(() => {
				this.data.filter(item => item.id == id)[0].lock = false
			}, 60000)
    },
    // 立即生成报告
    // changePrint(id) {
    //   this.loading = true;
    //   this.$api
    //     .postAction(
    //       "/admin/v1/reports/generate",
    //       { id: id },
    //       { timeOut: 300000 }
    //     )
    //     .then((res) => {
    //       console.log(res);
    //       if (!res) {
    //         this.$message.error("接口请求超时");
    //         // this.$message.error('报告数据量较大，请耐心等待');
    //         this.loading = false;
    //       } else if (res.code == 0) {
    //         this.getTable();
    //       } else {
    //         if (res.data !== undefined) {
    //           console.log(res.data[Object.keys(res.data)[0]]);
    //           this.$message.error(res.data[Object.keys(res.data)[0]]);
    //         } else {
    //           this.$message.error(res.msg);
    //         }
    //         this.loading = false;
    //       }
    //     });
    // },
    
    // 获取表格信息
    getTable(school_name = "", section = "", assess_name = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/personal-reports", {
          expand: "school_info,assess_name",
          page: this.pagination.current,
          "per-page": 10,
          school_name,
          section,
          assess_name,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              item.speed = Math.round(Math.random() * 10);
              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.getTable(
        this.form.school_name,
        this.form.section,
        this.form.assess_name
      );
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      this.$refs.addFrom.addFrom();
    },

    // 查看报告
    // editRecord(record) {
    //   console.log("editRecord");
    //   console.log(record);
    //   // alert('查看报告')
    //   // this.$refs.addFrom.editFrom(record)
    // },

    // 查看并打印报告
    // print(path) {
    //   console.log("print--打印");
    //   console.log(process.env.VUE_APP_API_BASE_URL);
    //   console.log(path);

    //   window.open(process.env.VUE_APP_API_BASE_URL + path, "_blank");
    // },
    delFrom() {
      console.log("delFrom--多选删除");
      alert("暂未");
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);
      alert("暂未");

      // this.$api.delAction('/admin/v1/schools', record.id).then((res) => {
      //   console.log(res)
      //   if (res.code == 0) {
      //     this.$message.success(res.msg)
      //     this.getTable()
      //   } else {
      //     this.$message.error(res.msg)
      //     if (res.data !== undefined) {
      //             console.log(res.data[Object.keys(res.data)[0]])
      //             this.$message.error(res.data[Object.keys(res.data)[0]])
      //           } else {
      //             this.$message.error(res.msg)
      //           }
      //   }
      // })
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(
        this.form.school_name,
        this.form.section,
        this.form.assess_name
      );
    },
  },
};
</script>

<style scoped lang="less">
.btnRegion {
  .time {
    font-size: 16px;
    color: #343a40;
    .big {
      color: #22bb7e;
      font-size: 36px;
      padding: 0 10px;
    }
  }
}
.speedStyle {
  display: flex;
  justify-content: space-between;
}
.whole {
  width: 800px;
  height: auto;
}
/deep/ .ant-table .ant-btn{
  margin: 0;
}
.gray{
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}
</style>
