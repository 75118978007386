<template>
  <!-- 封装  输入 单选  -->
  <!-- v-model="form.name" -->

  <a-select
    show-search
    placeholder="请选择学校"
    option-filter-prop="children"
    style="width: 300px"
    :filter-option="filterOption"
    @focus="selectFocus"
    @blur="selectBlur"
    @change="selectChange"
    :disabled="isDisabled"
  >
    <a-select-option :value="item.id" v-for="(item, i) in schoolList" :key="i">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "schoolList",
  mixins: [],
  components: {},
  props: ["isDisabled"],
  data() {
    return {
      schoolList: [],
    };
  },
  created() {
    this.getStatic();
  },
  methods: {
    getStatic() {
      // 获取 所有的 学校
      this.$api
        .getAction("/admin/v1/common/static", {
          data_name: "school",
        })
        .then((res) => {
          // console.log('res.data.school')
          // console.log(res.data.school)
          this.schoolList = res.data.school;
        });
    },
    selectChange(value) {
      console.log(`selected ${value}`);

      this.$emit("setSchoolId", value);
    },
    selectBlur() {
      console.log("blur");
    },
    selectFocus() {
      console.log("focus");
    },
    filterOption(input, option) {
      // console.log(input)
      // console.log(option.componentOptions.children[0].text.indexOf(input) >= 0)
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
  },
};
</script>

<style lang="less" scoped></style>
