<template>
  <a-drawer
    title="用户信息"
    placement="right"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="用户名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item label="登录密码" prop="password">
        <a-input v-model="form.password" v-if="addFlag" />
        <a-input-search
          v-else
          v-model="form.password"
          :type="passwordType ? 'text' : 'password'"
          placeholder="请输入密码"
          enter-button="重置密码"
          @input="$forceUpdate()"
          @search="passwordChange"
        />
      </a-form-model-item>

      <a-form-model-item label="联系方式" prop="phone">
        <a-input v-model="form.phone" />
      </a-form-model-item>

      <a-form-model-item label="角色名称" prop="role_name">
        <a-select v-model="form.role_name" placeholder="请选择角色名称">
          <a-select-option :value="item" v-for="(item, i) in authList" :key="i">
            {{ item }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属地区" prop="area_id">
        <a-cascader
          v-model="form.select"
          :options="options"
          style="width: 100%"
          placeholder="请选择地区"
          @change="onChangeArea"
          :field-names="{
            label: 'name',
            value: 'id',
            children: 'list',
          }"
        />
      </a-form-model-item>
      <a-form-model-item
        label="所属学校"
        prop="school_id"
        v-if="schoolList.length > 0"
      >
        <a-select
          v-model="form.school_id"
          placeholder="请选择所属学校"
          allowClear
          @change="schoolChange"
        >
          <a-select-option
            :value="item.id"
            v-for="(item, i) in schoolList"
            :key="i"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="所属班级"
        prop="grade_id"
        v-if="classList.length > 0"
      >
        <a-checkbox-group
          v-model="form.grade_id"
          placeholder="请选择所属班级"
          allowClear
        >
          <a-row>
            <a-col
              v-for="(item, index) in classList"
              :key="index"
              :span="24"
              class="example"
              style="margin: 5px 0"
            >
              <a-checkbox
                :value="item.id"
                style="
                  width: 100%;
                  white-space: pre-wrap !important;
                  word-break: break-all;
                  word-wrap: break-word;
                "
              >
                {{ item.grade }} / {{ item.class }}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
        <a-button @click="resetForm" class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
const isTelCode = (rule, value, callback) => {
  const reg = /^1(3|4|5|6|7|8|9)\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入正确的手机号"));
  } else {
    callback();
  }
};
export default {
  name: "questionBankFrom",
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {
        password: "",
        select: [],
        grade_id: undefined,
        school_id: undefined,
        area_id: undefined,
      },
      options: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入用户姓名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入联系方式",
            trigger: "blur",
          },
          {
            validator: isTelCode,
            trigger: "blur",
          },
        ],
        role_name: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
        // school_id: [
        //   {
        //     required: true,
        //     message: '请选择所属学校',
        //     trigger: 'change',
        //   },
        // ],
      },
      addFlag: true,
      authList: [],
      schoolList: [],
      classList: [],
      passwordType: true,
    };
  },
  created() {
    this.getStatic();
    this.getAuthList();
  },
  methods: {
    // 选择地区
    onChangeArea(value) {
      if (value && value.length > 0) {
        this.form.area_id = value[value.length - 1];
        // 获取学校列表
        this.getSchoolList(this.form.area_id);
      } else {
        this.schoolList = [];
        this.classList = [];
        this.form.area_id = ''
        this.form.grade_id = []
        this.form.grade_name = []
        this.form.grade_name_str = ''
      }
    },
    // 选择学校
    schoolChange() {
      // 获取年级/班级列表
      if (this.form.school_id) {
        this.getClassList(this.form.school_id);
      }
    },
    // 获取学校列表
    getSchoolList(id) {
      this.$api
        .getAction("/admin/v1/common/area-school", {
          area_id: id,
        })
        .then((res) => {
          if (res.code == 0) {
            if (res.data) {
              this.schoolList = res.data;
            } else {
              this.schoolList = [];
            }
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        });
    },
    // 获取班级列表
    getClassList(id) {
      this.$api
        .getAction("/admin/v1/common/school-grade", {
          school_id: id,
        })
        .then((res) => {
          if (res.data) {
            this.classList = res.data;
          } else {
            this.classList = [];
          }
        });
    },
    getStatic() {
      this.$api
        .getAction("/admin/v1/common/static", {
          data_name: "city",
        })
        .then((res) => {
          this.options = res.data.city;
        });
    },
    getAuthList() {
      // 获取所有权限
      this.$api
        .getAction("/admin/v1/roles", {
          "per-page": 100000,
        })
        .then((res) => {
          if (res.code == 0) {
            this.authList = res.data.list.map((item) => {
              return item.name;
            });
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // if (!this.passwordType) {
          //   this.form.password = ''
          // }
          if (this.addFlag) {
            this.$api
              .postAction("/admin/v1/admin-users", this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.onClose();
                  this.$parent.getTable();
                } else {
                  if (res.data !== undefined) {
                    this.$message.error(res.data[Object.keys(res.data)[0]]);
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              });
          } else {
            this.$api
              .putAction("/admin/v1/admin-users", this.form.id, this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.onClose();
                  this.$parent.getTable();
                } else {
                  if (res.data !== undefined) {
                    this.$message.error(res.data[Object.keys(res.data)[0]]);
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.onClose();
    },
    addFrom() {
      console.log("addFrom");
      if (!this.rules.password) {
        this.rules["password"] = [
          {
            required: true,
            message: "请输入登录密码",
            trigger: "blur",
          },
        ];
      }
      this.form = {};
      this.schoolList = [];
      this.classList = [];
      this.visible = true;
      this.addFlag = true;
    },
    editFrom(record) {
      console.log("editFrom");
      delete this.rules.password;
      this.form = record;
      // this.form.password = '111'
      if (this.form.area_id) {
        let arr = this.findParentIdsById(this.form.area_id, this.options);
        arr.push(this.form.area_id);
        this.form.select = arr;

        this.getSchoolList(this.form.area_id);
        if (this.form.school_id) {
          this.getClassList(this.form.school_id);
        } else {
          this.classList = [];
          this.form.school_id = undefined;
          this.form.grade_id = undefined;
        }
      } else {
        this.schoolList = [];
        this.classList = [];
        this.form.area_id = undefined;
        this.form.school_id = undefined;
        this.form.grade_id = undefined;
      }
      this.visible = true;
      this.addFlag = false;
      this.passwordType = false;
    },
    passwordChange() {
      this.passwordType = true;
      this.form.password = "";
    },
    onClose() {
      this.visible = false;
      this.$parent.getTable();
    },
    afterVisibleChange(val) {
      console.log("触发以及结束时的回调", val);
    },
    findParentIdsById(id, data, parentIds = []) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id === id) {
          return parentIds;
        } else if (data[i].list) {
          const result = this.findParentIdsById(id, data[i].list, [
            ...parentIds,
            data[i].id,
          ]);
          if (result) {
            return result;
          }
        }
      }
      return null;
    },
  },
};
</script>

<style scoped></style>
