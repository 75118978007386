<template>
  <a-drawer
    :title="modularTitle"
    placement="right"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="模块名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item label="学段" prop="">
        <a-select
          mode="multiple"
          v-model="form.grade_type"
          placeholder="请选择学段"
        >
          <a-select-option
            :value="item.value"
            v-for="(item, index) in sectionList"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- <a-form-model-item label="模块类型" prop="">
        <a-select v-model="form.type" placeholder="请选择模块类型">
          <a-select-option
            :value="item.value"
            v-for="(item, index) in modulesList"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item> -->

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
        <a-button @click="resetForm"  class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
export default {
  name: "modularAdd",
  data() {
    return {
      modularTitle: "",
      visible: false,
      visibleModal: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入测试卷名称",
            trigger: "blur",
          },
        ],
      },

      // 判断是 add 还是edit
      addFlag: true,

      // 省市区
      provinceList: [],
      // 学段
      sectionList: [],
      // 模块类型
      modulesList: [
        // 1-可选 2-必选 3-特殊 4-自定义
        {
          value: 1,
          label: "可选",
        },
        {
          value: 2,
          label: "干扰",
        },
        {
          value: 3,
          label: "特殊",
        },
        // {
        //   value: 4,
        //   label: "自定义",
        // },
      ],

      mockData: [],
      targetKeys: [],
    };
  },
  watch: {},
  created() {
    this.provinceList = JSON.parse(sessionStorage.getItem("provinceList"));
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));
  },
  methods: {
    provinceChange(value) {
      console.log(`selected ${value}`);
    },
    onSubmit() {
      console.log(this.form);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.visibleModal = true;
          if (this.addFlag) {
            this.$api.postAction("/admin/v1/modules", this.form).then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.onClose();
                this.$parent.getTable();
              } else {
                if (res.data !== undefined) {
                  console.log(res.data[Object.keys(res.data)[0]]);
                  this.$message.error(res.data[Object.keys(res.data)[0]]);
                } else {
                  this.$message.error(res.msg);
                }
              }
            });
          } else {
            this.$api
              .putAction("/admin/v1/modules", this.form.id, this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.onClose();
                  this.$parent.getTable();
                } else {
                  this.$message.error(res.msg);
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]]);
                    this.$message.error(res.data[Object.keys(res.data)[0]]);
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
      this.form = {};
      this.onClose();
    },
    addFrom() {
      this.modularTitle = "模块管理新增";
      this.form = {};
      console.log("addFrom");
      this.visible = true;
      this.addFlag = true;
    },
    editFrom(record) {
      this.modularTitle = "模块管理编辑";
      console.log("editFrom");
      console.log(record);
      // this.provinceChange(record.province_id)
      this.form = record;
      this.visible = true;
      this.addFlag = false;
    },

    onClose() {
      this.visible = false;
    },
    afterVisibleChange(val) {
      console.log("触发以及结束时的回调", val);
    },
  },
};
</script>

<style scoped lang="less">
:deep(.ant-col-14) {
  width: 80%;
}

:deep(.ant-transfer-list) {
  height: 400px;
}
.ant-modal-body {
  text-align: center;

  .anticon {
    font-size: 36px;
    color: #22bb7e;
  }
}
</style>
