<template>
  <div class="questionBank">
    <div class="bodyTop">
      
        <a-button class="btnGreenFull" @click="addFrom" icon="plus" style="margin-left: 50px;">
          新增
        </a-button>
      
    </div>

    <div class="bodyMain">
      <a-table
        :scroll="{ x: 1500, y: 500 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" v-if="record.patch" @click="print(record.patch)"> 查看并打印报告 </a-button>
          <!-- <a-divider v-if="record.patch" type="vertical" /> -->
          <a-popconfirm
            class="ml"
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "学校",
    dataIndex: "school_name",
    key: "school_name",
  },
  {
    title: "年级",
    width: 100,
    dataIndex: "grade_name",
    key: "grade_name",
  },
  {
    title: "班级",
    width: 100,
    dataIndex: "class",
    key: "class",
  },
  {
    title: "测评卷",
    dataIndex: "assess_name",
    key: "assess_name",
  },
  {
    title: "状态",
    width: 150,
    dataIndex: "status_name",
    key: "status_name",
  },
  {
    title: "创建日期",
    width: 150,
    dataIndex: "created_at_str",
    key: "created_at_str",
  },
  // {
  //   title: "更新日期",
  //   width: 200,
  //   dataIndex: "updated_at_str",
  //   key: "updated_at_str",
  // },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 270,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

import addFrom from "./addFrom";

export default {
  name: "interview",
  components: {
    addFrom,
  },
  created() {
    this.getTable();
  },
  data() {
    return {
      data: [],
      list: [],
      loading: true,
      columns: columns,
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      form: {},
    };
  },
  methods: {
    // student_name = ""
    getTable() {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/class-reports", {
          expand: "school_name,assess_name,grade_name,status_name,created_at_str",
          page: this.pagination.current,
          "per-page": 10,
        })
        .then((res) => {
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
            this.loading = false;
          } else {
            this.loading = false;
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
        this.loading = false;
    },
    addFrom() {
      this.$refs.addFrom.addFrom();
    },
    print(path) {
      console.log("print--打印");

      window.open(process.env.VUE_APP_API_BASE_URL + path, "_blank");
    },
    delRecord(record) {
      console.log("delRecord--单个删除");

      this.$api.delAction("/admin/v1/class-reports", record.id).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg);
          this.getTable();
        } else {
          if (res.data !== undefined) {
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current;
      this.getTable();
    },
  },
};
</script>

<style scoped lang="less">
span.btnOrg {
  border: 1px solid #ff8200;
  color: #ff8200;
  background: rgba(255, 128, 1, 0.06);
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
