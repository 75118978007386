<template>
  <div class="userPaper">
    <div class="top">
      <div>
        <img src="../../assets/logo1.png" alt="" />
        <a-dropdown>
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            {{ schoolName }}&nbsp;&nbsp;&nbsp;{{ gradeName }}年级{{ className }}
            <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a-popconfirm
                placement="topRight"
                ok-text="确认"
                cancel-text="取消"
                @confirm="confirm"
              >
                <template slot="title">
                  <p>退出后此次答题成绩不做保留</p>
                </template>
                <p style="text-align: center; margin-bottom: 0px">退出</p>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>

    <div class="main">
      <div class="tips">
        {{ desc }}
      </div>

      <div class="content">
        <!-- <div class="title">
          <p>{{ schoolName }}</p>
          <p>学生{{ userName }}测评</p>
        </div> -->

        <div class="contentTitle">{{ title }}</div>
        <a-radio-group v-model="value" @change="onChange" size="large">
          <a-radio
            :style="radioStyle"
            :value="item.value"
            v-for="(item, i) in options"
            :key="i"
          >
            <!-- 占位 -->
            <span class="org">1</span>
            <span class="radioName">{{ item.label }}</span>
          </a-radio>
        </a-radio-group>
      </div>
    </div>

    <a-modal v-model="visibleModal" title="" :footer="null" @cancel="confirm">
      <div class="mmo">
        <p class="first">
          <a-icon type="check-circle" theme="filled" />
        </p>
        <p>您已完成本次测评！</p>
      </div>
    </a-modal>
    
    <div>

    <!-- <a-spin tip="加载中" size="large" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "userPaper",
  created() {
    document.oncontextmenu = function () {
      console.log("oncontextmenu");
      console.log("鼠标右键");
      return false;
    };

    document.onkeydown = function (event) {
      console.log("onkeydown");
      console.log("F5刷新");
      var e = event || window.event || arguments.callee.caller.arguments[0];
      if (e && e.keyCode == 116) {
        return false;
      }
    };

    window.onbeforeunload = function () {
      console.log("onbeforeunload");
      console.log("浏览器--刷新按钮");
      window.clearInterval(this.times);

      return false;
    };

    console.log(JSON.parse(sessionStorage.getItem("fujiati")));
    this.questions = JSON.parse(sessionStorage.getItem("fujiati"));
    // console.log("this.options.length");
    // console.log(this.questions.length);
    this.getQuestion();

    // this.timeCount = sessionStorage.getItem("duration");

    // window.clearInterval(this.times);
    // // 附加题 超时时间
    // this.times = setInterval(() => {
    //   this.timeCount--; //递减
    //   console.log("this.timeCount--2");
    //   console.log(this.timeCount);
    //   if (this.timeCount == 0) {
    //     console.log("走提交");
    //     clearInterval(this.times);
    //     this.subFn();
    //   }
    // }, 1000);
  },
  watch: {},
  computed: {},
  mounted() {
    //监听浏览器返回
    if (window.history && window.history.pushState) {
      console.log("监听浏览器--返回--按钮");
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goPageBefore, false);
    }
  },
  data() {
    return {
      assess_id: sessionStorage.getItem("assess_id"),
      userName: sessionStorage.getItem("userName"),
      schoolName: sessionStorage.getItem("schoolName"),
      gradeName: sessionStorage.getItem("gradeName"),
      className: sessionStorage.getItem("className"),
      desc: sessionStorage.getItem("desc"),
      value: "",
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      nowTopic: 0,
      questions: [],
      title: "",
      options: [],
      answer: [],
      visibleModal: false,
      timeCount: 0,

      times: "",
    };
  },
  methods: {
    getQuestion(i = this.nowTopic) {
      this.title = this.questions[i].name;
      this.options = this.questions[i].option;
    },
    onChange(e) {
      // console.log("onchange--e");
      // console.log(e);
      // console.log("this.nowTopic--当前点击角标");
      // console.log(this.nowTopic);
      // console.log(this.questions);

      let labelName = this.options.filter((item) => {
        return item.value == e.target.value;
      });

      let obj = {
        question_id: this.questions[this.nowTopic].id,
        module_id: this.questions[this.nowTopic].module_id,
        value: e.target.value,
        label: labelName[0].label,
      };

      this.answer.push(obj);
      // console.log("this.answer--组成的数据");
      // console.log(this.answer);

      // 点击获取当前数据的  module_id 与下一条数据的 module_id 进行对比，相同则进行下一题，不想相同则发起接口

      if (
        this.questions[this.nowTopic + 1] &&
        this.questions[this.nowTopic].module_id ==
          this.questions[this.nowTopic + 1].module_id
      ) {
        // console.log("相同--数据");

        this.nowTopic++;
        this.getQuestion();
      } else {
        // console.log("不相同--数据");
        this.questions.splice(0, this.nowTopic + 1);
        // console.log("this.questions--2");
        // console.log(this.questions);
        this.subFn();
      }

      this.value = "";
    },
    subFn() {
      let obj2 = {
        assess_id: this.assess_id,
        answer: this.answer,
      };

      this.$api.postAction("/app/v1/assessment/re-submit", obj2).then((res) => {
        console.log(res);
        this.answer = [];
        this.nowTopic = 0;

        if (res.code == 0) {
          this.value = "";
          if (res.data !== undefined) {
            // console.log("有附加");

            this.questions = res.data.concat(this.questions);
            console.log(this.questions);

            this.getQuestion();
          } else {
            // console.log("无附加----");
            // console.log("this.nowTopic--当前角标");
            // console.log(this.nowTopic);
            // console.log("this.questions--问题数组长度");
            // console.log(this.questions.length);

            if (this.nowTopic == this.questions.length) {
              // console.log("附加题结束");

              this.visibleModal = true;

              setTimeout(() => {
                this.confirm();
              }, 2000);
            }

            this.getQuestion();
          }
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]]);
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
          setTimeout(() => {
            this.confirm();
          }, 2000);
        }
      });
    },
    confirm() {
      sessionStorage.clear();
      // window.clearInterval(this.times);
      this.$router.push({
        path: "/loginUser",
      });
    },

    goPageBefore() {
      //这里设置想要返回的页面，需要参数等。
      // alert("想要返回？？");
      // console.log("想要返回？？");
      this.confirm();
      // return false;
    },
  },
  destroyed() {
    console.log("destroyed");
    window.removeEventListener("popstate", this.goPageBefore, false);
    document.onkeydown = () => {
      return true;
    };
  },
};
</script>

<style scoped lang="less">
.userPaper {
  .top {
    background: #fff;

    div {
      width: 858px;
      margin: 0 auto;

      img {
        width: 80px;
        height: 69px;
        margin-top: 7px;
        margin-bottom: 12px;
      }
      .ant-dropdown-link {
        float: right;
        color: #666666;
        margin-top: 32px;
      }
      .ant-dropdown-link:hover {
        color: #1890ff;
      }
    }
  }

  .main {
    width: 858px;
    margin: 0 auto;

    .tips {
      margin: 30px 0 25px;
      color: #333;
      span {
        color: #3b3230;
      }
      span.red {
        color: #ff595d;
      }
    }

    .content {
      background: #fff;
      border-radius: 10px;
      padding: 34px 120px 100px;

      .title {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #333;
      }
      .num {
        text-align: right;
        font-size: 12px;

        span {
          font-size: 20px;
          color: #007cae;
        }
      }

      .contentTitle {
        margin-bottom: 58px;
        text-align: center;
        color: #e87817;
        font-size: 30px;
      }

      :deep(.ant-radio-group) {
        width: 100%;
        .ant-radio-wrapper {
          width: 100%;
          background-color: #fafafa;
          margin-bottom: 15px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
          height: 55px !important;
          line-height: 55px !important;

          span.ant-radio + * {
            padding-left: 0;
          }

          span.ant-radio {
            float: right;
            margin: 20px;

            input.ant-radio-input:focus {
              display: none;
            }
          }

          .org {
            background: #fafafa;
            color: #fafafa;
            margin-right: 5px;
          }
        }
        .ant-radio-wrapper.ant-radio-wrapper-checked {
          background: rgba(255, 130, 0, 0.1);

          .org {
            background: #ff8200;
            color: #ff8200;
            padding: 19px 0;
          }
        }

        .ant-radio-wrapper:hover {
          background: rgba(255, 130, 0, 0.1);

          .org {
            background: #ff8200;
            color: #ff8200;
            padding: 19px 0;
          }
        }
      }

      .footer {
        margin-top: 33px;

        .fr {
          float: right;
        }
      }
    }
  }
}

:deep(.ant-modal-body) {
  text-align: center;

  .first {
    padding-top: 80px;
  }

  p {
    font-size: 36px;
    color: #22bb7e;

    .anticon {
      font-size: 54px;
    }
  }

  .mmo {
    height: 300px;
    background: url("../../assets/bgUser2.png") no-repeat center;
    background-size: cover;
  }
}
.radioName {
  font-weight: 900;
  font-size: 18px;
}
</style>
