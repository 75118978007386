<template>
  <a-drawer
    title="学生基本信息"
    placement="right"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="姓名" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>

      <a-form-model-item label="身份证号" prop="identity">
        <a-input v-model="form.identity" />
      </a-form-model-item>

      <a-form-model-item label="学校" prop="school_id">
        <a-select v-model="form.school_id" placeholder="请选择学校">
          <a-select-option
            :value="item.id"
            v-for="(item, index) in schoolList"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="届" prop="session">
        <a-input-number v-model="form.session" />
      </a-form-model-item>

      <a-form-model-item label="年级" prop="grade">
        <a-select v-model="form.grade" placeholder="请选择年级">
          <a-select-option
            :value="item.id"
            v-for="(item, index) in gradeList"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="班级" prop="class">
        <a-input v-model="form.class" />
      </a-form-model-item>

      <a-form-model-item label="学校内部学号" prop="inner_code">
        <a-input v-model="form.inner_code" />
      </a-form-model-item>
      <a-form-model-item label="学籍号" prop="code">
        <a-input v-model="form.code" />
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
        <a-button @click="resetForm" class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: '请输入学生名称',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 8,
            message: '最大长度8',
            trigger: 'blur',
          },
        ],
        class: [
          {
            required: true,
            message: '请输入班级',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 8,
            message: '最大长度8',
            trigger: 'blur',
          },
        ],
        identity: [
          {
            min: 0,
            max: 20,
            message: '最大长度20',
            trigger: 'blur',
          },
          {
            required: true,
            message: '请输入身份证号码',
            trigger: 'blur',
          },
        ],
        school_id: [
          {
            required: true,
            message: '请选择学校',
            trigger: 'blur',
          },
        ],
        grade: [
          {
            required: true,
            message: '请选择年级',
            trigger: 'blur',
          },
        ],
        session: [
          {
            required: true,
            message: '请输入届',
            trigger: 'blur',
          },
        ],
        inner_code: [
          {
            required: true,
            message: '请输入学校内部学号',
            trigger: 'blur',
          },
        ],
        code: [
          {
            required: true,
            message: '请输入学籍号',
            trigger: 'blur',
          },
        ],
      },

      // 判断是 add 还是edit
      addFlag: true,

      // 所有学校
      schoolList: [],
      // 年级
      gradeList: [
        {
          id: 1,
          name: '一年级',
        },
        {
          id: 2,
          name: '二年级',
        },
        {
          id: 3,
          name: '三年级',
        },
        {
          id: 4,
          name: '四年级',
        },
        {
          id: 5,
          name: '五年级',
        },
        {
          id: 6,
          name: '六年级',
        },
        {
          id: 7,
          name: '七年级(初一)',
        },
        {
          id: 8,
          name: '八年级(初二)',
        },
        {
          id: 9,
          name: '九年级(初三)',
        },
        {
          id: 10,
          name: '十年级(高一)',
        },
        {
          id: 11,
          name: '十一年级(高二)',
        },
        {
          id: 12,
          name: '十二年级(高三)',
        },
      ],
    }
  },
  watch: {},
  created() {
    this.provinceList = JSON.parse(sessionStorage.getItem('provinceList'))
    this.getStatic()
  },
  methods: {
    getStatic() {
      // 获取 所有的 学校
      this.$api
        .getAction('/admin/v1/common/static', {
          data_name: 'school',
        })
        .then((res) => {
          this.schoolList = res.data.school
        })
    },
    onSubmit() {
      console.log(this.form)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.addFlag) {
            this.$api
              .postAction('/admin/v1/students', this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.onClose()
                  this.$parent.getTable()
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]])
                    this.$message.error(res.data[Object.keys(res.data)[0]])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              })
          } else {
            this.$api
              .putAction('/admin/v1/students', this.form.id, this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.onClose()
                  this.$parent.getTable()
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]])
                    this.$message.error(res.data[Object.keys(res.data)[0]])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.form = {}
      this.onClose()
    },
    addFrom() {
      this.form = {}
      console.log('addFrom')
      this.visible = true
      this.addFlag = true
    },
    editFrom(record) {
      // console.log('editFrom')
      // console.log(record)
      // console.log(record.id)
      // console.log(record.grade_info)

      // this.form = record
      this.form = Object.assign({}, record.grade_info, record)
      // console.log(this.form)
      // console.log(this.form.id)

      this.visible = true
      this.addFlag = false
    },

    onClose() {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    afterVisibleChange(val) {
      console.log('触发以及结束时的回调', val)
    },
  },
}
</script>

<style scoped lang="less">
:deep(.ant-drawer-body) {
  .ant-col-4 {
    width: 20%;
  }

  .ant-input-number {
    width: 100%;
  }
}
</style>
