<template>
  <a-drawer
    title="角色权限信息"
    placement="right"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="角色名称" prop="name">
        <a-input v-model="form.name" :disabled="!addFlag" />
      </a-form-model-item>

      <a-form-model-item label="角色权限" prop="auth_name">
        <a-tree
          v-model="form.auth_name"
          checkable
          :auto-expand-parent="autoExpandParent"
          :expanded-keys="expandedKeys"
          :tree-data="treeData"
          @expand="onExpand"
          @select="onSelect"
          @check="onCheck"
        />
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
        <a-button @click="resetForm" class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
export default {
  name: 'questionBankFrom',
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      form: {
        name: '',
        auth_name: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur',
          },
        ],
        auth_name: [
          {
            required: true,
            message: '请选择角色权限',
            trigger: 'change',
          },
        ],
      },

      autoExpandParent: true,
      // 勾选项
      checkedKeys: [],
      // 展开项
      expandedKeys: [],
      // 选中项
      selectedKeys: [],

      // treeData1: treeData,
      treeData: [],

      // 判断是 add 还是edit
      addFlag: true,
    }
  },
  watch: {},
  created() {
    this.getMenu()
  },
  methods: {
    getMenu() {
      // 获取 所有的 菜单信息
      this.$api
        .getAction('/admin/v1/common/static', {
          data_name: 'menu',
        })
        .then((res) => {
          let array = res.data.menu

          for (let index = 0; index < array.length; index++) {
            let obj = {
              title: '',
              key: '',
              children: [],
            }
            obj.title = array[index].name
            // 当 auth_name 为一级菜单时,后台传值为'',这边添加值,'isNull' + index ,在add或者edit时移除
            obj.key =
              array[index].auth_name != ''
                ? array[index].auth_name
                : 'isNull' + index

            if (array[index].list.length > 0) {
              let arr = Array.from(array[index].list)

              for (let i = 0; i < arr.length; i++) {
                let objChild = {
                  title: '',
                  key: '',
                }
                objChild.title = arr[i].name
                objChild.key = arr[i].auth_name

                obj.children.push(objChild)
              }
            } else {
              delete obj.children
            }
            this.treeData.push(obj)
          }

          console.log(this.treeData)
        })
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.auth_name = this.form.auth_name.filter((item) => {
            return item.indexOf('isNull') == -1
          })

          if (this.addFlag) {
            this.$api.postAction('/admin/v1/roles', this.form).then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg)
                this.onClose()
                this.$parent.getTable()
              } else {
                if (res.data !== undefined) {
                  console.log(res.data[Object.keys(res.data)[0]])
                  this.$message.error(res.data[Object.keys(res.data)[0]])
                } else {
                  this.$message.error(res.msg)
                }
              }
            })
          } else {
            this.$api
              .putAction('/admin/v1/roles', this.form.name, this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg)
                  this.onClose()
                  this.$parent.getTable()
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]])
                    this.$message.error(res.data[Object.keys(res.data)[0]])
                  } else {
                    this.$message.error(res.msg)
                  }
                }
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      // this.$refs.ruleForm.resetFields()
      this.onClose()
    },
    addFrom() {
      this.form = {
        name: '',
        auth_name: [],
      }
      console.log('addFrom')
      this.visible = true
      this.addFlag = true
    },
    editFrom(record) {
      console.log('editFrom')
      console.log(record)
      this.form.name = record.name
      this.form.auth_name = record.permissions
      this.visible = true
      this.addFlag = false
    },

    onClose() {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    afterVisibleChange(val) {
      console.log('触发以及结束时的回调', val)
    },

    onCheck(checkedKeys) {
      console.log('onCheck', checkedKeys)
      this.checkedKeys = checkedKeys
    },

    onExpand(expandedKeys) {
      // 展开收起
      console.log('onExpand', expandedKeys)
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },

    onSelect(selectedKeys, info) {
      // 选中
      console.log('onSelect', info)
      this.selectedKeys = selectedKeys
    },
  },
}
</script>

<style scoped></style>
