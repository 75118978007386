<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学校">
          <a-input v-model="form.school_name" placeholder="请输入学校" />
        </a-form-model-item>

        <a-form-model-item label="校区">
          <a-input v-model="form.school_campus" placeholder="请输入校区" />
        </a-form-model-item>

        <!-- <a-form-model-item label="状态" prop="">
          <a-select
            v-model="form.progress"
            placeholder="请选择状态"
            style="width: 150px"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in speedList"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item> -->

        <a-form-model-item label="评测卷">
          <a-input v-model="form.assess_name" placeholder="请输入评测卷" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="delFrom"
        >
          <template slot="title">
            <p>确认删除吗?</p>
          </template>
          <a-button class="ml" icon="delete"> 删除 </a-button>
        </a-popconfirm>
        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
      </div>

      <a-table
        :scroll="{ x: 1500, y: 520 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="assess_name" slot-scope="text">
          <p class="assessName">
            {{ text.name }}
          </p>
          <p>共 {{ text.question_count ? text.question_count : 0 }} 题</p>
        </span>

        <!-- <span slot="module_info" slot-scope="text, record">
          <span v-for="(item, i) in record.assess_info.module_info" :key="i">
            <span v-if="i < 5" class="moduleInfo">
              {{ item.name }}
            </span>
          </span>
          <span v-if="record.assess_info.module_info.length > 6"> ... </span>
        </span> -->

        <span slot="module_info" slot-scope="text, record">
          <span v-if="record.assess_info.module_info.length > 4">
            <a-tooltip placement="topLeft">
              <template slot="title">{{ record.moduleName }} </template>
              <span
                v-for="(item, i) in record.assess_info.module_info"
                :key="i"
              >
                <span class="moduleInfo" v-if="i <= 4">
                  {{ item.name }}
                </span>
              </span>
              <span> ... </span>
            </a-tooltip>
          </span>
          <span v-else>
            <span v-for="(item, i) in record.assess_info.module_info" :key="i">
              <span class="moduleInfo">
                {{ item.name }}
              </span>
            </span>
          </span>
        </span>

        <span slot="time" slot-scope="text, record">
          {{ record.start_time_str ? record.start_time_str : "--" }} 至
          {{ record.end_time_str ? record.end_time_str : "--" }}
        </span>

        <span slot="speed" slot-scope="text">
          <a-progress :percent="text" :show-info="false" />

          <div class="speedStyle">
            <span v-if="text == 0">未开始</span>
            <span v-else-if="text == 100">已完成</span>
            <span v-else>进行中</span>

            <span>{{ text }}%</span>
          </div>
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>

          <a-divider type="vertical" />

          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <!-- <p style="text-align: center; margin-bottom: 0px">删除</p> -->
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>

          <a-divider type="vertical" />

          <a-button
            class="btnIn"
            @click="downFun(record.id, record.school_info.name)"
          >
            导出
          </a-button>

          <a-divider type="vertical" />

          <a-button
            class="btnIn"
            @click="daochuNo(record.id, record.school_info.name)"
          >
            导出未完成人员
          </a-button>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
    fixed: "left",
  },
  {
    title: "学校",
    dataIndex: "school_name",
    // align: 'center',
    key: "school_name",
    width: 260,
    customRender: function (t, r) {
      return r.school_info.name;
    },
    fixed: "left",
  },
  {
    title: "测评卷名称",
    dataIndex: "assess_info",
    // align: 'center',
    key: "assess_name",
    width: 260,
    scopedSlots: { customRender: "assess_name" },
  },

  // {
  //   title: '校区',
  //   dataIndex: 'school_campus',
  //   // align: 'center',
  //   key: 'school_campus',
  //   width: 260,
  // },
  {
    title: "学段",
    dataIndex: "section_name",
    // align: 'center',
    key: "section_name",
    width: 100,
    customRender: function (t, r) {
      return r.school_info.section_name;
    },
  },
  {
    title: "模块",
    dataIndex: "module_info",
    // align: 'center',
    key: "module_info",
    width: 300,
    scopedSlots: { customRender: "module_info" },
  },
  {
    title: "测评起止时间",
    dataIndex: "time",
    // align: 'center',
    key: "time",
    width: 200,
    scopedSlots: { customRender: "time" },
  },
  {
    title: "剩余有效天数",
    dataIndex: "remaining_days",
    // align: 'center',
    key: "remaining_days",
    width: 200,
    customRender: function (t) {
      return t == 0 ? "--" : "还有 " + t + " 天";
    },
  },
  {
    title: "人数",
    dataIndex: "students_num",
    // align: 'center',
    key: "students_num",
    width: 260,
  },
  {
    title: "完成率",
    dataIndex: "completion_rate",
    // align: 'center',
    key: "completion_rate",
    width: 100,
    customRender: function (t) {
      return t + "%";
    },
  },
  {
    title: "报告生成时间",
    dataIndex: "report_time_str",
    // align: 'center',
    key: "report_time_str",
    width: 150,
    customRender: function (t) {
      return t ? t : "--";
    },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 450,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

import moment from "moment";
import addFrom from "./addFrom";

export default {
  name: "schoolInfo",
  components: {
    addFrom,
  },
  created() {
    this.getTable();
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      // visibleModal: false,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      speedList: [
        {
          value: 0,
          label: "未开始",
        },
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已完成",
        },
      ],
    };
  },
  methods: {
    moment,
    daochuNo(id, str) {
      str = str.trim();
      // console.log(id);
      // console.log(str);
      // console.log(moment().startOf("day").format("YYYY-MM-DD"));
      let name =
        str + "未完成人员" + moment().startOf("day").format("YYYYMMDD");
      // console.log(name);

      const a = document.createElement("a");
      let url =
        process.env.VUE_APP_API_BASE_URL +
        "/api/admin/v1/school-assessments/student-unfinished?id=" +
        id;

      // console.log(url);
      // 这里是将url转成blob地址，
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob);
          // 下载文件的名字
          a.download = name;
          document.body.appendChild(a);
          a.click();
        });
    },
    downFun(id, str) {
      str = str.trim();
      console.log(id);
      console.log(str);
      // console.log(moment().startOf("day").format("YYYY-MM-DD"));
      let name =
        str + "学生答题原始数据" + moment().startOf("day").format("YYYYMMDD");
      // console.log(name);

      const a = document.createElement("a");
      let url =
        process.env.VUE_APP_API_BASE_URL +
        "/api/admin/v1/school-assessments/student-answer?id=" +
        id;

      // console.log(url);
      // 这里是将url转成blob地址，
      fetch(url)
        .then((res) => res.blob())
        .then((blob) => {
          // 将链接地址字符内容转变成blob地址
          a.href = URL.createObjectURL(blob);
          // 下载文件的名字
          a.download = name;
          document.body.appendChild(a);
          a.click();
        });
    },
    getTable(
      school_name = "",
      school_campus = "",
      progress = "",
      assess_name = ""
    ) {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/school-assessments", {
          expand:
            "school_info,assess_info,start_time_str,end_time_str,report_time_str,remaining_days,students_num",
          page: this.pagination.current,
          school_name,
          school_campus,
          progress,
          assess_name,
          "per-page": 10,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              // item.speed = Math.round(Math.random() * 100)

              let moduleName = item.assess_info.module_info.map((y) => {
                return y.name;
              });
              item.moduleName = moduleName.join("，");

              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(
        this.form.school_name,
        this.form.school_campus,
        this.form.progress,
        this.form.assess_name
      );
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      this.$refs.addFrom.addFrom();
    },
    editRecord(record) {
      // console.log('editRecord')
      // console.log(record)
      this.$refs.addFrom.editFrom(record);
    },
    inFrom() {
      console.log("inFrom--导入");
    },
    delFrom() {
      console.log("delFrom--多选删除");
      console.log(this.selectedRowKeys);

      if (this.selectedRowKeys.length < 1) {
        this.$message.warning("请选勾选");
        return false;
      }

      this.$api
        .delAction("/admin/v1/school-assessments/batch-delete", "", {
          id: this.selectedRowKeys,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.selectedRowKeys = [];
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);

      let key = this.selectedRowKeys.indexOf(record.id);
      if (key > -1) {
        this.selectedRowKeys.splice(key, 1);
      }

      this.$api
        .delAction("/admin/v1/school-assessments", record.id)
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            // this.$message.success(res.msg)
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(
        this.form.school_name,
        this.form.school_campus,
        this.form.progress,
        this.form.assess_name
      );
    },
  },
};
</script>

<style scoped lang="less">
.btnRegion {
  .time {
    font-size: 16px;
    color: #343a40;
    .big {
      color: #22bb7e;
      font-size: 36px;
      padding: 0 10px;
    }
  }
}
.speedStyle {
  display: flex;
  justify-content: space-between;
}

:deep(.ant-modal-body) {
  text-align: center;

  .anticon {
    font-size: 36px;
    color: #22bb7e;
  }
}

p.assessName {
  // color: #007cae;
  margin-bottom: 0;
  cursor: pointer;

  & + p {
    padding: 5px 0;
    font-size: 12px;
    color: #aab2b8;
  }
}
.moduleInfo {
  background: rgba(0, 124, 174, 0.06);
  border-radius: 4px;
  padding: 4px;
  color: #007cae;
  margin: 5px;
  font-size: 12px;
  display: inline-block;
}
</style>
