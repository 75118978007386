<template>
  <div class="login">
    <div class="loginBg">
      <div class="left">
        <img src="../../assets/bgUser1.png" alt="" />
        <img src="../../assets/logo.png" alt="" class="logo" />
      </div>
      <div class="right">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div class="sysName">学生乐成长综合素养发展测评系统</div>
          <div class="pLogin"><span>你好，</span>请登录</div>

          <a-form-model-item label="" prop="student_name">
            <a-input v-model="form.student_name" placeholder="请输入真实姓名">
              <a-icon
                slot="suffix"
                type="user"
                style="color: #aab2b8; font-size: 19px"
              />
            </a-input>
          </a-form-model-item>

          <a-form-model-item label="" prop="identity">
            <a-input
              v-model="form.identity"
              type="password"
              placeholder="请输入密码"
            >
              <a-icon
                slot="suffix"
                type="eye"
                style="color: #aab2b8; font-size: 19px"
              />
            </a-input>
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 14 }" class="inTest">
            <a-button type="primary" @click="onSubmit" class="loginSub">
              进入测试
            </a-button>
          </a-form-model-item>

          <a-form-model-item label="" class="lastClass">
            <a-checkbox v-model="isCheck"> </a-checkbox>
            <span> 同意 </span>
            <span @click="showModal(0)" class="tk">《使用条款》</span>
            和
            <span @click="showModal(1)" class="tk">《隐私政策》</span>
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 14 }" style="padding-top: 15px">
            <div class="bottomTips">钜驰教育 关注青少年健康成长</div>
            <div class="bottomTips2">软著登字第11542222号</div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>

    <a-modal
      :visible="isShow"
      @ok="handleOk"
      @cancel="handleCancel"
      :footer="null"
      width="800px"
    >
      <h1>关于隐私权保护和测评结果的申明</h1>
      <h2>一、隐私权保护申明</h2>
      <p>
        本站隐私权保护申明，系本网站保护个人隐私的承诺。鉴于Internet网络的特性，本网站会与您直接或间接的形成互动关系，故特此说明本网站对您个人信息所采取的收集、使用和保护政策，请您务必仔细阅读：
      </p>
      <h3>1.关于对您的信息的收集</h3>
      <p>
        本站使用的个人信息有您所在的单位（学校）提供，主要包含单位、姓名、身份证号、学籍号等。
      </p>
      <h3>2.关于对您的信息的使用</h3>
      <p>
        您提供的信息将受到严格保密，本网站使用您的这些信息只是为了更好地了解您的需要并为您提供更好的服务。
      </p>
      <h3>3.关于对您的信息的控制</h3>
      <p>
        本网站不会把您的个人识别信息出售或出租给他人。本网站将不会在未经您事先知晓和许可的情况下，以任何的方式随意使用或分享您在线提供给我们的个人识别信息。您的许可总被首先保障，我们决不会与任何不代表本网站、不受我们保密政策约束的第三方分享您的信息。
      </p>
      <h3>4.关于对您的信息的访问</h3>
      <p>
        本网站为了保护您的隐私和安全，将采取合理的措施验证身份，之后，您才能访问或修改信息。本网站力求使您的个人识别信息保持准确，并为您提供了包括使您能够在线访问个人信息、注册数据的能力，使您可以更新或修改您的信息。
      </p>
      <h3>5.关于您的信息的安全性</h3>
      <p>
        我们计划保护就您个人而言可以识别的上述信息的质量和完整性。我们会认真保护您的资料以防止其丢失、被误用、受到未授权访问或泄露、被篡改或毁坏。在本网站内，数据存放在有密码控制的服务器中，访问是受到限制的。
      </p>
      <p>
        我们将真诚努力，及时响应您的请求以便对您的个人信息误差进行纠正。为了对您的个人信息误差进行纠正，请把包含误差的信息送还给发送者，并附上所要求更正的细节。
      </p>
      <h3>6.未成年人隐私权的保护</h3>
      <p>
        本网站会最大限度地注意未满16岁的用户的安全，监护人应承担保护未成年人在网络环境下的隐私权的首要责任。任何16岁以下的成年人参加网上活动都应在学校的组织之下，或应事先得到家长或其法定监护人（以下简称"监护人"）的可经查证的同意，
      </p>
      <h3>7.关于cookie技术的使用</h3>
      <p>
        随着互联网对各项新技术的应用，为访客带来了完全个性化的体验。我们会使用cookies的技术来分析网页的使用情形，并维护您在本网站使用记录。Cookie是放置在您的电脑硬盘中的一段要求权限的文字。使用此项技术并不表示我们可自动获悉有关您的任何信息。我们可以确定您使用的计算机类型，但使用cookies的主要目的在于使您在访问本网站网站时获得更佳的体验。本网站绝对无意在您使用本网站网站时侵犯您的隐私。
      </p>
      <p>
        同时您也拥有完全的自主权，可以将网络浏览器（Microsoft InternetExplorer或
        Netscape
        Navigator）设置为通知您cookie的放置请求，或者完全拒绝cookie。您可以删除包含cookie的文件；这些文件被保存为internet浏览器的一部分。
      </p>
      <h3>二、测评结果申明</h3>
      <p>
        本测评结果只能说明您可能有某方面的倾向，不代表您已经有该方面的问题。建议结果指标为“值得关注”及以上的个人要及时寻求专业人士和专业机构的帮助。
      </p>
      <h3>三、关于本声明的更改</h3>
      <p>
        本声明的解释权及对本网站使用的解释权归本网站所有。本网站有时会更新本隐私保护声明。如果本声明有实质性的更改，本网站将放置醒目通知来告知用户。
      </p>
    </a-modal>

    <a-modal
      :visible="isShow0"
      @ok="handleOk0"
      @cancel="handleCancel0"
      :footer="null"
      width="800px"
    >
      <h1>使用条款</h1>
      <h2>一、使用条款</h2>
      <p>xxxxxx</p>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "loginUser",
  created() {
    console.log(process.env.VUE_APP_API_BASE_URL);

    localStorage.clear();
    sessionStorage.clear();
  },
  data() {
    return {
      isShow: false,
      isShow0: false,
      confirmLoading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        // school_name: '',
        student_name: "",
        identity: "",
      },
      rules: {
        school_name: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "blur",
          },
        ],
        student_name: [
          {
            required: true,
            message: "请输入学生姓名",
            trigger: "blur",
          },
        ],
        identity: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      isCheck: true,
    };
  },
  methods: {
    showModal(val) {
      if (val == 1) {
        this.isShow = true;
      } else {
        this.isShow0 = true;
      }
    },
    handleOk() {
      this.isShow = false;
    },
    handleCancel() {
      this.isShow = false;
    },
    handleOk0() {
      this.isShow0 = false;
    },
    handleCancel0() {
      this.isShow0 = false;
    },
    onSubmit() {
      console.log("submit!", this.form);

      if (!this.isCheck) {
        this.$message.error("请先阅读并勾选隐私协议");
        return;
      }

      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$api.postAction("/app/v1/user/login", this.form).then((res) => {
            console.log(res);
            if (res.code == 0) {
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("userName", res.data.name);
              sessionStorage.setItem(
                "schoolName",
                res.data.grade_info.school_name
              );
              sessionStorage.setItem("gradeName", res.data.grade_info.grade);
              sessionStorage.setItem("className", res.data.grade_info.class);
              sessionStorage.setItem("assess_id", res.data.assess.assess_id);
              sessionStorage.setItem("desc", res.data.assess.desc);

              sessionStorage.setItem("duration", res.data.assess.duration);

              // 后台应用 userType 为0
              sessionStorage.setItem("userType", 0);

              this.$router.push("/userPaper");
            } else {
              if (res.data !== undefined) {
                console.log(res.data[Object.keys(res.data)[0]]);
                this.$message.error(res.data[Object.keys(res.data)[0]]);
              } else {
                this.$message.error(res.msg);
              }
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.login {
  background: #007cae;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; /* 相对父元素水平居中 */

  .loginBg {
    display: flex;
    flex-direction: row; /* 子元素横向排列 */
    // justify-content: center; /* 相对父元素水平居中 */
    background: #007cae;
    box-shadow: 0px 2px 37px 0px rgba(0, 78, 109, 1);
    border-radius: 40px;
  }

  .left {
    position: relative;
    float: left;

    img {
      width: 365px;
      height: 616px;
    }
    .logo {
      position: absolute;
      top: 50px;
      left: 50px;
      width: 77px;
      height: 73px;
    }
  }

  .right {
    background: #fff;
    height: 616px;
    width: 635px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;

    // display: flex;
    flex-direction: row; /* 子元素横向排列 */
    justify-content: center; /* 相对父元素水平居中 */
    align-items: center;

    :deep(.ant-form) {
      // width: 400px;

      .sysName {
        font-size: 32px;
        color: #007cae;
        padding-top: 45px;
        padding-bottom: 45px;
        border-bottom: 1px solid #e7e7e7;
        text-align: center;
      }

      .pLogin {
        text-align: center;
        color: #333333;
        padding: 44px 0 33px;
        font-size: 22px;

        // span {
        //   font-weight: 600;
        // }
      }

      input {
        font-size: 18px;
      }

      input::-webkit-input-placeholder {
        color: #333 !important;
      }
      input::-moz-placeholder {
        color: #333 !important;
      }
      input::-ms-input-placeholder {
        color: #333 !important;
      }

      .loginSub {
        width: 100%;
        height: 54px;
        border-radius: 10px;
        background-color: #007cae;
        font-size: 18px;
        color: #ffffff;
        border: 0;
      }

      .ant-col {
        width: 100%;
      }

      .ant-form-item-with-help.ant-form-item {
        margin-bottom: 16px;
      }

      .ant-form-item {
        margin: 0 auto;
        margin-bottom: 30px;
        width: 440px;

        // .ant-form-item-control {
        //   line-height: 0;
        // }

        .ant-checkbox-wrapper {
          font-size: 12px;
        }
        span.itemName {
          position: absolute;
          z-index: 100;
          left: 13px;
          color: #666;
          font-size: 12px;
          top: -10px;
        }

        input {
          // border: 0;
          height: 54px;
          border-radius: 10px;
          // padding-top: 25px;
          padding-left: 12px;
          color: #333;
          // font-size: 18px;
        }

        input:-moz-placeholder,
        textarea:-moz-placeholder {
          color: #aab2b8;
          opacity: 0.72;
        }

        input:-ms-input-placeholder,
        textarea:-ms-input-placeholder {
          color: #aab2b8;
          opacity: 0.72;
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
          color: #aab2b8;
          opacity: 0.72;
        }
        .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
          border-color: #007cae;
        }
      }

      .inTest {
        margin-bottom: 0;

        a {
          color: #007cae;
        }
      }

      .lastClass {
        // margin-bottom: 67px;
        margin-bottom: 0;
      }
    }
  }
}
.bottomTips {
  color: #333333;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  // font-weight: 600;
}
.bottomTips2 {
  color: #999999;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  // font-weight: 600;
}
.tk {
  color: #1890ff;
  cursor: pointer;
}

.ant-modal-content {
  h1 {
    text-align: center;
  }
  p {
    text-indent: 1cm;
  }
}
</style>
