<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学校">
          <a-input v-model="form.school_name" placeholder="请输入学校" />
        </a-form-model-item>

        <a-form-model-item label="届">
          <a-input v-model="form.session" placeholder="请输入届" />
        </a-form-model-item>

        <a-form-model-item label="年级" prop="grade">
          <a-select
            v-model="form.grade"
            placeholder="请选择年级"
            style="width: 150px"
          >
            <a-select-option
              :value="item.id"
              v-for="(item, index) in gradeList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="班级">
          <a-input v-model="form.class" placeholder="请输入班级" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <!-- <span class="time">测评时间：还有<span class="big">90</span>天</span>
        <a-button class="btnGreenFull ml"> 查看测评卷 </a-button> -->

        <!-- <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>
        <a-button class="" @click="delFrom" icon="delete"> 删除 </a-button>
        <a-button class="btnIn" @click="inFrom" icon="upload">
          导入模板
        </a-button>
        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span> -->
      </div>

      <!-- :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }" -->
      <a-table
        :scroll="{ x: 1500, y: 550 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="type" slot-scope="text">
          <span v-if="text.uncompleted_count >= 0 && text.student_count > 0">
            <span v-if="text.uncompleted_count == 0" style="color: #22bb7e"
              >已完成</span
            >
            <span v-else>
              剩余
              <span style="color: #ff8001">{{ text.uncompleted_count }}</span>
              位未完成
            </span>
          </span>
          <span v-else>--</span>
        </span>

        <span slot="speed" slot-scope="text">
          <a-progress
            :percent="text.completion_rate ? parseInt(text.completion_rate) : 0"
            :show-info="false"
          />

          <div class="speedStyle">
            <span v-if="text.completion_rate == 0 || !text.completion_rate"
              >未开始</span
            >
            <span v-else-if="text.completion_rate == 100">已完成</span>
            <span v-else>进行中</span>
            <span>{{ text.completion_rate ? text.completion_rate : 0 }}%</span>
          </div>
        </span>

        <!-- <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span> -->
      </a-table>
    </div>

    <!-- <addFrom ref="addFrom" /> -->
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "测评卷名称",
    dataIndex: "assess_info",
    align: "center",
    key: "assess_name",
    customRender: function (t) {
      return t.assess_name ? t.assess_name : "";
    },
  },
  {
    title: "学校",
    dataIndex: "school_name",
    // align: 'center',
    key: "school_name",
  },
  {
    title: "届",
    dataIndex: "session",
    // align: 'center',
    key: "session",
  },
  {
    title: "年级",
    dataIndex: "grade",
    // align: 'center',
    key: "grade",
    customRender: function (t) {
      let a = "";
      switch (t) {
        case 1:
          a = "一年级";
          break;
        case 2:
          a = "二年级";
          break;
        case 3:
          a = "三年级";
          break;
        case 4:
          a = "四年级";
          break;
        case 5:
          a = "五年级";
          break;
        case 6:
          a = "六年级";
          break;
        case 7:
          a = "七年级(初一)";
          break;
        case 8:
          a = "八年级(初二)";
          break;
        case 9:
          a = "九年级(初三)";
          break;
        case 10:
          a = "十年级(高一)";
          break;
        case 11:
          a = "十一年级(高二)";
          break;
        default:
          a = "十二年级(高三)";
          break;
      }
      return a;
    },
  },
  {
    title: "班级",
    dataIndex: "class",
    // align: 'center',
    key: "class",
  },
  {
    title: "人数",
    dataIndex: "assess_info",
    // align: 'center',
    key: "student_count",
    customRender: function (t) {
      return t.student_count ? t.student_count + "人" : "0人";
    },
  },
  {
    title: "测评起止时间",
    dataIndex: "assess_info",
    // align: 'center',
    key: "created_at_str",
    customRender: function (t) {
      return (
        (t.created_at_str ? t.created_at_str : "--") +
        " 至 " +
        (t.end_time_str ? t.end_time_str : "--")
      );
    },
  },
  {
    title: "完成率",
    dataIndex: "assess_info",
    align: "center",
    key: "speed",
    scopedSlots: { customRender: "speed" },
  },

  {
    title: "状态",
    dataIndex: "assess_info",
    align: "center",
    key: "id",
    scopedSlots: { customRender: "type" },
  },
  // {
  //   title: '操作',
  //   key: 'action',
  //   align: 'center',
  //   width: 200,
  //   fixed: 'right',
  //   scopedSlots: { customRender: 'action' },
  // },
];

// import addFrom from './addFrom'

export default {
  name: "schoolInfo",
  components: {
    // addFrom,
  },
  created() {
    this.getTable();
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      speedList: [
        {
          value: 0,
          label: "未开始",
        },
        {
          value: 1,
          label: "进行中",
        },
        {
          value: 2,
          label: "已完成",
        },
      ],
      // 年级
      gradeList: [
        {
          id: 1,
          name: "一年级",
        },
        {
          id: 2,
          name: "二年级",
        },
        {
          id: 3,
          name: "三年级",
        },
        {
          id: 4,
          name: "四年级",
        },
        {
          id: 5,
          name: "五年级",
        },
        {
          id: 6,
          name: "六年级",
        },
        {
          id: 7,
          name: "七年级(初一)",
        },
        {
          id: 8,
          name: "八年级(初二)",
        },
        {
          id: 9,
          name: "九年级(初三)",
        },
        {
          id: 10,
          name: "十年级(高一)",
        },
        {
          id: 11,
          name: "十一年级(高二)",
        },
        {
          id: 12,
          name: "十二年级(高三)",
        },
      ],
    };
  },
  methods: {
    getTable(school_name = "", session = "", grade = "", className = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/grades", {
          expand: "school_name,student_count,assess_info",
          page: this.pagination.current,
          "per-page": 10,
          school_name,
          session,
          grade,
          class: className,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              // item.speed = Math.round(Math.random() * 100)
              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(
        this.form.school_name,
        this.form.session,
        this.form.grade,
        this.form.class
      );
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      this.$refs.addFrom.addFrom();
    },
    editRecord(record) {
      console.log("editRecord");
      console.log(record);
      this.$refs.addFrom.editFrom(record);
    },
    inFrom() {
      console.log("inFrom--导入");
    },
    delFrom() {
      console.log("delFrom--多选删除");
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);

      this.$api.delAction("/admin/v1/schools", record.id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          // this.$message.success(res.msg)
          this.getTable();
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]]);
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;
      this.getTable(
        this.form.school_name,
        this.form.session,
        this.form.grade,
        this.form.class
      );
    },
  },
};
</script>

<style scoped lang="less">
.btnRegion {
  .time {
    font-size: 16px;
    color: #343a40;
    .big {
      color: #22bb7e;
      font-size: 36px;
      padding: 0 10px;
    }
  }
}
.speedStyle {
  display: flex;
  justify-content: space-between;
}
</style>
