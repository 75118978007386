<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="评测卷">
          <a-input v-model="form.assess_name" placeholder="请输入评测卷" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus"> 新增 </a-button>
        <!-- <a-popconfirm
          placement="topRight"
          ok-text="确认"
          cancel-text="取消"
          @confirm="delFrom"
        >
          <template slot="title">
            <p>确认删除吗?</p>
          </template>
          <a-button class="ml" icon="delete"> 删除 </a-button>
        </a-popconfirm> -->
        <!-- <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span> -->
      </div>
      <!-- :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }" -->
      <a-table
        :loading="loading"
        :scroll="{ x: 1500, y: 500 }"
        :columns="columns"
        :data-source="data"
     
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
           <a-button class="btnIn" @click="editRecord(record)" :disabled="record.status_name == '数据生成成功'?false:true"> 点击跳转大屏 </a-button>
          <!-- <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>-->
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm> 
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: '序号',
    dataIndex: '',
    key: 'rowIndex',
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1
    },
  },
  {
    title: '测评卷',
    dataIndex: 'assess_name',
    // align: 'center',
    key: 'assess_name',
    width: 150,
  },
  {
    title: '所属学校',
    dataIndex: 'school_name',
    // align: 'center',
    key: 'school_name',
    width: 150,
  },
  {
    title: '跳转link',
    dataIndex: 'link',
    // align: 'center',
    key: 'link',
    width: 250,
  },
  // {
  //   title: 'token参数',
  //   dataIndex: 'token',
  //   key: 'token',
  //   width: 280,
  // },
  {
    title: '创建时间',
    dataIndex: 'created_at_str',
    // align: 'center',
    key: 'created_at_str',
    width: 150,
  },
  {
    title: '大屏数据状态',
    dataIndex: 'status_name',
    // align: 'center',
    key: 'status_name',
    width: 150,
  },
  {
    title: '操作',
    key: 'action',
    align: 'center',
    fixed: "right",
    width: 250,
    scopedSlots: { customRender: 'action' },
  },
]

import addFrom from './addFrom'

export default {
  name: 'schoolDataVisualization',
  components: {
    addFrom,
  },
  created() {
    this.getTable()
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
      },
    }
  },
  methods: {
    getTable(assess_name = '') {
      this.loading = true
      this.$api
        .getAction('/admin/v1/school-data-visualizations', {
          expand: 'school_name,area_name,grade_name,role_name,created_at_str',
          page: this.pagination.current,
          'per-page': 10,
          assess_name,
        })
        .then((res) => {
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount
            this.data = res.data.list
            // .map((item) => {
            //   if(item.grade_name.length){
            //     item.grade_name_str = item.grade_name.map((e) => {
            //       let str = e.grade + e.class
            //       return str
            //     })
            //     item.grade_name_str = item.grade_name_str.join(' , ')
            //   }
            //   return item
            // })
            this.loading = false
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]])
            } else {
              this.$message.error(res.msg)
            }
          }
        })
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(this.form.assess_name);
    },
    reset() {
      console.log('reset')
      this.form = {}
      this.getTable()
    },
    addFrom() {
      console.log('addFrom')
      this.$refs.addFrom.addFrom()
    },
    editRecord(record) {
      console.log(record)
      console.log('editRecord')
      // this.$router.push({
      //   path:record.link,
      //   params:{
      //     id:record.token
      //   }
      // })
      window.open(`${record.link}?id=${record.token}`)
      // this.$refs.addFrom.editFrom(record)
    },
    delFrom() {
      console.log('delFrom--多选删除')

      if (this.selectedRowKeys.length < 1) {
        this.$message.warning('请选勾选')
        return false
      }
      
      this.$api.delAction(`/admin/v1/school-data-visualizations/${this.selectedRowKeys.toString()}` ).then((res) => {
          if (res.code == 0) {
            this.selectedRowKeys = []
            this.getTable()
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]])
            } else {
              this.$message.error(res.msg)
            }
          }
        })
    },
    delRecord(record) {
      console.log('delRecord')

      this.$api.delAction('/admin/v1/school-data-visualizations', record.id).then((res) => {
        if (res.code == 0) {
          // this.$message.success(res.msg)
          this.getTable()
        } else {
          if (res.data !== undefined) {
            this.$message.error(res.data[Object.keys(res.data)[0]])
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    tableChange(pagination) {
      this.pagination.current = pagination.current
      this.getTable(this.form.assess_name)
    },
  },
}
</script>

<style scoped></style>
