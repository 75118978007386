<template>
  <div class="headerRight">
    <!-- <img src="../assets/logo.png" alt="" />
    <img src="../assets/logo.png" alt="" />
    <img src="../assets/logo.png" alt="" /> -->
    <img src="../assets/logo.png" alt="" class="pic" />

    <!-- <span>{{ user.name }}</span> -->
    <!-- <p class="outUser" @click="outUser">退出</p> -->

    <a-dropdown>
      <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
        {{ user.name }} <a-icon type="down" />
      </a>
      <a-menu slot="overlay">
        <a-menu-item>
          <a href="javascript:;" @click="outUser">退出</a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {},
    }
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem('userInfo'))
  },
  methods: {
    outUser() {
      console.log('退出')

      sessionStorage.removeItem('token')
      sessionStorage.removeItem('userMenu')
      sessionStorage.removeItem('userInfo')

      // sessionStorage.removeItem('sectionList')
      // sessionStorage.removeItem('provinceList')

      this.$router.push('/login')
    },
  },
}
</script>

<style lang="less" scoped>
.headerRight {
  float: right;
  padding-right: 30px;
  cursor: pointer;

  img {
    width: 22px;
    height: 22px;
    margin: 0 10px;
    border: 1px solid;
    border-radius: 50%;
  }
  img.pic {
    width: 32px;
    height: 32px;
  }
  .ant-dropdown-link {
    color: #fff;
  }

  // .outUser {
  //   background: #fff;
  //   line-height: 30px;
  //   border-bottom-left-radius: 5px;
  //   border-bottom-right-radius: 5px;
  //   color: #000;
  //   text-align: center;
  //   width: 100%;
  //   display: none;
  // }
}
</style>
