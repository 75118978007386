<template>
  <div class="userPaper">
    <a-spin :spinning="spinning" size="large" tip="加载中...">
      <div class="top">
        <div>
          <img src="../../assets/logo1.png" alt="" />

          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              {{ schoolName }}&nbsp;&nbsp;&nbsp;{{ gradeName }}年级{{
                className
              }}
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-popconfirm
                  placement="topRight"
                  ok-text="确认"
                  cancel-text="取消"
                  @confirm="confirm"
                >
                  <template slot="title">
                    <p>退出后此次答题成绩不做保留</p>
                  </template>
                  <p style="text-align: center; margin-bottom: 0px">退出</p>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>

      <div class="main">
        <div class="tips">
          {{ desc }}
        </div>

        <div class="content">
          <div class="num">
            <span>{{ nowTopic + 1 }}</span
            >/{{ totalTopic }}题
          </div>

          <a-progress :percent="percent" :show-info="false" status="active" />

          <div class="contentTitle">{{ title }}</div>

          <div v-if="multiple_choice === 0">
            <a-radio-group v-model="value" size="large" @change="onChange">
              <a-radio
                :style="radioStyle"
                :value="item.label"
                v-for="(item, i) in options"
                :key="i"
              >
                <!-- 占位 -->
                <span class="org">1</span>
                <span class="radioName">{{ item.label }}</span>
              </a-radio>
            </a-radio-group>
          </div>
          <div v-else>
            <a-checkbox-group v-model="checkvalue" @change="checkvalueChange">
               <a-checkbox :value="item.label" v-for="(item, i) in options" :key="i" :style="radioStyle">
                 <!-- 占位 -->
                <span class="org">1</span>
                <span class="radioName">{{ item.label }}</span>
               </a-checkbox>
            </a-checkbox-group>
          </div>
          <div class="footer">
            <a-button class="btnGreen" @click="upFn" v-if="upBtn">
              上一题
            </a-button>
            <a-button class="btnGreen fr" @click="nextFn" v-if="nextBtn || (multiple_choice === 1 && (totalTopic -1 !== nowTopic))">
              下一题
            </a-button>
            <a-button
              type="primary"
              class="btnOrange fr"
              @click="subFn"
              v-if="lastFlag || (nowTopic == totalTopic - 1)"
              :loading="loadingBtn"
            >
              提交评测卷
            </a-button>
          </div>
        </div>
      </div>
    </a-spin>

    <a-modal v-model="visibleModal" title="" :footer="null" @cancel="confirm">
      <div class="mmo">
        <p class="first">
          <a-icon type="check-circle" theme="filled" />
        </p>
        <p>您已完成本次测评！</p>
      </div>
    </a-modal>

    <a-modal
      :visible="visibleRadio"
      @cancel="handleCancel"
      :footer="null"
      :closable="false"
      width="480px"
    >
      <template slot="title">
        <a-icon type="info-circle" />
        <span class="topTitle">提示</span>
      </template>
      <p class="info">
        目前检测您的作答过程中注意力存在不集中现象，请您认真作答！
      </p>
      <a-button type="primary" @click="handleCancel" class="know"
        >知道了</a-button
      >
    </a-modal>

    <!-- @cancel.once="handleCancel2" -->
    <a-modal
      :visible="visibleRadio2"
      :footer="null"
      :closable="false"
      width="580px"
    >
      <template slot="title">
        <a-icon type="info-circle" />
        <span class="topTitle">提示</span>
      </template>
      <div class="infoTipsDiv">
        <p class="infoTips">
          我们会严格保管您的个人信息，请放心并诚实作答。认真阅读问卷，选择最符合您当前情况的答案
        </p>
        <a-button
          type="primary"
          @click="handleCancel2"
          class="know"
          v-if="timeCount2 == 0"
          >开始作答</a-button
        >
        <a-button type="primary" class="knowDis" disabled v-else
          >开始作答{{
            timeCount2 > 0 ? " （ " + timeCount2 + " ） " : ""
          }}</a-button
        >
      </div>
    </a-modal>
    <a-modal
      :visible="visibleRadio3"
      :closable="false"
      width="580px"
      @ok="handleOk3"
      @cancel="handleCancel3"
    >
      <template slot="title">
        <a-icon type="info-circle" />
        <span class="topTitle">提示</span>
      </template>
      <div class="infoTipsDiv">
        <p class="infoTips">
          确认提交评测卷？
        </p>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "userPaper",
  created() {
    document.oncontextmenu = function () {
      console.log("oncontextmenu");
      console.log("鼠标右键");
      return false;
    };

    document.onkeydown = function (event) {
      console.log("onkeydown");
      console.log("F5刷新");
      var e = event || window.event || arguments.callee.caller.arguments[0];
      if (e && e.keyCode == 116) {
        return false;
      }
    };

    window.onbeforeunload = function () {
      console.log("onbeforeunload");
      console.log("浏览器--刷新按钮");
      window.clearInterval(this.times);

      return false;
    };

    window.clearInterval(this.times);

    this.getAssessment();
    this.timeCount = sessionStorage.getItem("duration");

    let times2 = setInterval(() => {
      this.timeCount2--; //递减
      if (this.timeCount2 == 0) {
        clearInterval(times2);
      }
    }, 1000);
  },
  mounted() {
    //监听浏览器返回
    if (window.history && window.history.pushState) {
      console.log("监听浏览器--返回--按钮");
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goPageBefore, false);
    }
  },
  watch: {},
  computed: {
    percent() {
      return ((this.nowTopic + 1) / this.totalTopic) * 100;
    },
  },
  data() {
    return {
      visibleRadio3:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      nowTopic: 0,
      totalTopic: 0,

      checkvalue: [],
      value: "",
      radioStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
      userName: sessionStorage.getItem("userName"),
      schoolName: sessionStorage.getItem("schoolName"),
      gradeName: sessionStorage.getItem("gradeName"),
      className: sessionStorage.getItem("className"),
      desc: sessionStorage.getItem("desc"),

      upBtn: false,
      nextBtn: false,

      // 是否完成所有题目 0-否 1-是
      complete: 0,
      created_at: "",
      assess_id: sessionStorage.getItem("assess_id"),

      // 往后台的传值集合
      answer: [],

      questions: [],

      title: "",
      options: [],
      multiple_choice: 0,
      visibleRadio: false,
      visibleRadio2: true,

      visibleModal: false,
      lastFlag: false,

      // 倒计时
      timeCount: 0,
      timeCount2: 5,

      nowValue: 0,
      radioShow: 0,
      radioList: [],

      spinning: true,

      loadingBtn: false,

      times: "",

      numm: 0,
    };
  },
  methods: {
    getAssessment() {
      this.$api
        .postAction("/app/v1/assessment/question-list", {
          assess_id: this.assess_id,
        })
        .then((res) => {
          if (res.code == 0) {
            this.questions = res.data.questions;
            this.totalTopic = res.data.questions.length;
            this.getQuestion();
            this.getGanrao();
            this.spinning = false;
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    getQuestion(i = this.nowTopic) {
      this.title = this.questions[i].name;
      this.options = this.questions[i].option;
      this.multiple_choice = this.questions[i].multiple_choice
    },
    getGanrao() {
      let radioList = this.questions.filter((item, index) => {
        // console.log('item')
        // console.log(item)
        item.radioIndex = index;
        return item.type == 2;
      });
      // console.log("radioList");
      // console.log(radioList);
      // 干扰题选项 当前为3道干扰题，取前2个做判断依据，第3个放行
      if (radioList.length > 0) {
        this.radioList.push(
          radioList[0].radioIndex,
          radioList[1].radioIndex,
          radioList[2].radioIndex
        );
        // console.log("this.radioList");
        // console.log(this.radioList);
      }
    },
    onChange(e) {
      if (this.nowTopic == this.totalTopic - 1) {
        this.lastFlag = true;
        this.visibleRadio3 = true
      } else {
        this.value = "";
      }

      this.nowValue = e.target.value;
      console.log(this.nowValue, 'this.nowValue')
      // 如果 是 干扰题，若选择错误项，则展示 提示信息[只针对前两道]
      if (
        this.radioList.indexOf(this.nowTopic) != -1 &&
        this.nowValue == 0 &&
        this.radioShow < 1
      ) {
        this.visibleRadio = true;
        this.radioShow++;
        return false;
      } else {
        this.nextFun();
      }
    },
    checkvalueChange() {
      console.log(this.checkvalue, 'fdasf')
      // if (this.nowTopic == this.totalTopic - 1) {
      //   this.lastFlag = true;
      //   this.visibleRadio3 = true
      // } else {
      //   this.value = "";
      // }
    },
    // 多选处理下一题数据
    checknextFun() {
     this.upBtn = true;
      let obj = {}
      let intersection = this.options.filter((item) => {
         return this.checkvalue.some(j => j === item.label)
      });
      let labellist = []
      let valuelist = []
      intersection.forEach(item => {
        labellist.push(item.label)
        valuelist.push(item.value)
      });
      obj = {
        question_id: this.questions[this.nowTopic].id,
        module_id: this.questions[this.nowTopic].module_id,
        value: valuelist,
        label: labellist,
      };

      if (!this.answer[this.nowTopic]) {
        this.answer.push(obj);
      } else {
        this.answer.splice(this.nowTopic, 1, obj);
      }

      if (!this.lastFlag) {
        this.nowTopic++;
        this.getQuestion();
      }

      this.nextBtn = false;
    },
    nextFun() {
      this.upBtn = true;
      let obj = {}
      let labelName = this.options.filter((item) => {
        return item.label == this.nowValue;
      });

      console.log(labelName[0].value)
      obj = {
        question_id: this.questions[this.nowTopic].id,
        module_id: this.questions[this.nowTopic].module_id,
        value: labelName[0].value,
        label: labelName[0].label,
      };
 
      if (!this.answer[this.nowTopic]) {
        this.answer.push(obj);
      } else {
        this.answer.splice(this.nowTopic, 1, obj);
      }

      if (!this.lastFlag) {
        this.nowTopic++;
        this.getQuestion();
      }

      this.nextBtn = false;
    },
    handleOk3(){
      this.subFn()
    },
    handleCancel3(){
        this.visibleRadio3 = false
    },
    subFn() {
      if (this.multiple_choice === 1) {
        let obj = {}
        let intersection = this.options.filter((item) => {
          return this.checkvalue.some(j => j === item.label)
        });
        let labellist = []
        let valuelist = []
        intersection.forEach(item => {
          labellist.push(item.label)
          valuelist.push(item.value)
        });
        obj = {
          question_id: this.questions[this.nowTopic].id,
          module_id: this.questions[this.nowTopic].module_id,
          value: valuelist,
          label: labellist,
        };

        if (!this.answer[this.nowTopic]) {
          this.answer.push(obj);
        } else {
          this.answer.splice(this.nowTopic, 1, obj);
        }
      }
      this.loadingBtn = true;

      this.$api
        .postAction("/app/v1/assessment/submit", {
          complete: this.nowTopic == this.totalTopic - 1 ? 1 : 0,
          created_at: this.created_at,
          answer: this.answer,
          assess_id: this.assess_id,
        })
        .then((res) => {
          if (res.code == 406) {
            console.log("406--倒计时重新--赋值");
            console.log(res.data);
            clearInterval(this.times);
            // 重新对 倒计时 进行赋值
            // sessionStorage.setItem("duration", res.data.assess.duration);
            this.timeCount = res.data.timeCount;
            this.daojishiFn();
          } else if (res.code == 0) {
            if (res.data !== undefined) {
              // 有附加题
              clearInterval(this.times);

              sessionStorage.removeItem("fujiati");
              sessionStorage.setItem("fujiati", JSON.stringify(res.data));

              this.$router.push({
                path: "/userPaperFu",
              });
            } else {
              // 无附加题
              this.visibleModal = true;

              setTimeout(() => {
                this.confirm();
              }, 2000);
            }
         
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
            setTimeout(() => {
              this.confirm();
            }, 2000);
          }

          this.loadingBtn = false;
        });

    },
    // 下一题
    nextFn() {
      if (this.multiple_choice === 0) {
        this.nextBtn = false;
        this.upBtn = true;
        this.nowTopic++;
        this.getQuestion();
        this.value = "";
      } else {
        this.checknextFun()
      }
    },
    upFn() {
      this.nowTopic--;
      this.upBtn = false;
      this.nextBtn = true;

      if (this.lastFlag) {
        this.lastFlag = false;
      }
      this.getQuestion();
      if (this.multiple_choice === 0) {
        this.value = this.answer[this.nowTopic].label;
      } else {
        this.checkvalue = this.answer[this.nowTopic].label
      }
    },
    confirm() {
      sessionStorage.clear();

      window.clearInterval(this.times);

      this.$router.push({
        path: "/loginUser",
      });
    },
    handleCancel() {
      this.visibleRadio = false;
      this.nextFun();
    },
    handleCancel2() {
      console.log("handleCancel2--1");
      if (this.timeCount2 == 0 && this.visibleRadio2) {
        // 往后台发送 开始考试标识
        this.$api
          .postAction("/app/v1/assessment/start", { assess_id: this.assess_id })
          .then((res) => {
            if (res.code == 0) {
              console.log("1");
              this.created_at = res.data.created_at;

              this.visibleRadio2 = false;
              this.daojishiFn();
            } else {
              if (res.data !== undefined) {
                this.$message.error(res.data[Object.keys(res.data)[0]]);
              } else {
                this.$message.error(res.msg);
              }
              setTimeout(() => {
                this.confirm();
              }, 2000);
              console.log("2");
            }
          });
      }
    },
    daojishiFn() {
      console.log("触发定时器");
      // this.numm++;
      let that = this;
      that.times = setInterval(() => {
        that.timeCount--; //递减
        // console.log("that.timeCount--" + that.numm);
        console.log(that.timeCount);
        if (that.timeCount == 0) {
          clearInterval(that.times);
          console.log("提交咯");
          that.subFn();
        }
      }, 1000);
    },
    goPageBefore() {
      //这里设置想要返回的页面，需要参数等。
      // console.log("想要返回？？");
      this.confirm();
      // return false;
    },
  },
  destroyed() {
    console.log("destroyed");
    window.removeEventListener("popstate", this.goPageBefore, false);
    document.onkeydown = () => {
      return true;
    };
  },
};
</script>

<style scoped lang="less">
.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-content {
  width: 100px;
  float: right;
}

.userPaper {
  position: relative;
  :deep(.ant-spin-nested-loading) {
    .ant-spin {
      top: 30%;
    }
  }

  .top {
    background: #fff;

    div {
      width: 858px;
      margin: 0 auto;

      img {
        width: 80px;
        height: 69px;
        margin-top: 7px;
        margin-bottom: 12px;
      }

      .ant-dropdown-link {
        float: right;
        color: #666666;
        margin-top: 32px;
      }
      .ant-dropdown-link:hover {
        color: #1890ff;
      }
    }
  }
  .main {
    width: 858px;
    margin: 0 auto;

    .tips {
      margin: 30px 0 25px;
      color: #333;
      span {
        color: #3b3230;
      }
      span.red {
        color: #ff595d;
      }
    }

    .content {
      background: #fff;
      border-radius: 10px;
      padding: 25px 60px 60px;

      .title {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: #333;
      }
      .num {
        text-align: right;
        font-size: 12px;

        span {
          font-size: 20px;
          color: #007cae;
        }
      }

      .contentTitle {
        margin: 25px 0;
        text-align: center;
        color: #e87817;
        font-size: 25px;
      }

      :deep(.ant-radio-group) {
        width: 100%;
        .ant-radio-wrapper {
          width: 100%;
          background-color: #fafafa;
          margin-bottom: 15px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
          height: 55px !important;
          line-height: 55px !important;

          span.ant-radio + * {
            padding-left: 0;
          }

          span.ant-radio {
            float: right;
            margin: 20px;

            input.ant-radio-input:focus {
              display: none;
            }
          }

          .org {
            background: #fafafa;
            color: #fafafa;
            margin-right: 15px;
          }
        }
        .ant-radio-wrapper.ant-radio-wrapper-checked {
          background: rgba(255, 130, 0, 0.1);

          .org {
            background: #ff8200;
            color: #ff8200;
            padding: 19px 0;
          }
        }

        .ant-radio-wrapper:hover {
          background: rgba(255, 130, 0, 0.1);

          .org {
            background: #ff8200;
            color: #ff8200;
            padding: 19px 0;
          }
        }
      }
      :deep(.ant-checkbox-group) {
        width: 100%;
        .ant-checkbox-wrapper {
          width: 100%;
          background-color: #fafafa;
          margin-bottom: 15px;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
          height: 55px !important;
          line-height: 55px !important;

          span.ant-checkbox + * {
            padding-left: 0;
          }

          span.ant-checkbox {
            float: right;
            margin: 20px;

            input.ant-checkbox-input:focus {
              display: none;
            }
          }

          .org {
            background: #fafafa;
            color: #fafafa;
            margin-right: 15px;
          }
        }
        .ant-checkbox-wrapper.ant-radio-wrapper-checked {
          background: rgba(255, 130, 0, 0.1);

          .org {
            background: #ff8200;
            color: #ff8200;
            padding: 19px 0;
          }
        }

        .ant-checkbox-wrapper:hover {
          background: rgba(255, 130, 0, 0.1);

          .org {
            background: #ff8200;
            color: #ff8200;
            padding: 19px 0;
          }
        }
      }
      .footer {
        margin-top: 33px;

        .fr {
          float: right;
        }
      }
    }
  }
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
:deep(.ant-modal-content) {
  .ant-modal-body {
    min-height: 125px;

    .first {
      padding-top: 80px;
    }

    p {
      font-size: 36px;
      color: #22bb7e;
      text-align: center;
      .anticon {
        font-size: 54px;
      }
    }

    .mmo {
      height: 300px;
      background: url("../../assets/bgUser2.png") no-repeat center;
      background-size: cover;
    }

    p.info {
      font-size: 14px;
      color: #666666;
    }

    .know {
      background-color: #e87817;
      border: 0;
      float: right;
      margin-top: 10px;
    }

    .infoTipsDiv {
      min-height: 125px;

      p.infoTips {
        font-size: 20px;
        color: #e87817;
      }
      .know {
        margin-top: 0px;
      }
      .knowDis {
        border: 0;
        float: right;
        margin-top: 10px;
      }
    }
  }
  .anticon-info-circle {
    color: #e87817;
  }
  span.topTitle {
    color: #000;
    padding-left: 10px;
    font-weight: 600;
  }
}
.over {
  font-size: 30px;
  text-align: center;
  color: green;
  padding-top: 100px;
  .anticon {
    font-size: 50px;
  }
}
.radioName {
  font-weight: 900;
  font-size: 18px;
}
</style>
