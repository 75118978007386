// 封装 拦截器
import axios from 'axios'
// import store from '@/store'
import { message } from 'ant-design-vue'
import router from '@/router'

const service = axios.create({
  baseURL: '/api',
  timeout: 30000,
  // timeout: 10000,
  headers: {
    // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    'Content-Type': 'application/json',
    Accept: 'application/json',
    // "Access-Control-Allow-Origin": "*",
    // "X-YS-UID": "Q00001020",
    'Cache-Control': 'no-cache',
  },
})

/**
 * 禁止点击蒙层、显示一秒后关闭
 */
const msg = (msg_content) => {
  message.error(msg_content)
}

/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
  console.log('toLogin')

  sessionStorage.removeItem('token')
  sessionStorage.removeItem('userInfo')
  sessionStorage.removeItem('userMenu')
  
  // 后台应用 userType 为1  前台应用 userType 为0
  if (sessionStorage.getItem('userType') == 1) {
    router.push('/login')
  } else {
    router.push('/loginUser')
  }
  sessionStorage.removeItem('userType')
}

const to101 = () => {
  console.log('101')
  msg('登录过期，请重新登录')
  setTimeout(() => {
    toLogin()
  }, 2000)
}
const to403 = () => {
  console.log('403')
  msg('登录过期，请重新登录')
  setTimeout(() => {
    toLogin()
  }, 2000)
}
const to404 = () => {
  console.log('404')
  router.replace({
    path: '/404',
    query: {
      redirect: router.currentRoute.fullPath,
    },
  })
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  switch (status) {
    // 401: 未登录
    // 未登录则跳转登录页面，并携带当前页面的路径
    // 在登录成功后返回当前页面，这一步需要在登录页操作。
    case 101:
      to101()
      break
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      to403()
      break
    // 404请求不存在
    case 404:
      msg('网络请求不存在')
      to404()
      break
    case 502:
      msg('服务器无响应')
      break
    default:
      console.log('505')
      console.log(other)
  }
}

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
service.interceptors.request.use(
  (config) => {
    // const token = store.state.common.token
    const token = sessionStorage.getItem('token')

    token && (config.headers.Authorization = token)
    return config
  },
  (error) => Promise.error(error)
)

service.interceptors.response.use(
  (res) => {
    return res.status !== 200
      ? Promise.reject(res)
      : res.data.code !== 101
      ? Promise.resolve(res.data)
      : to101()
  },
  (error) => {
    const { response } = error
    if (response) {
      errorHandle(response.status, response.data.message)
      return Promise.reject(error.response)
    }
  }
)

export default service
