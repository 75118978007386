<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学段" prop="">
          <a-select
            v-model="form.section"
            placeholder="请选择学段"
            style="width: 150px"
          >
            <a-select-option
              :value="item.value"
              v-for="(item, index) in sectionList"
              :key="index"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="模块名称" prop="">
          <a-select
            v-model="form.module_id"
            placeholder="请选择模块名称"
            style="width: 150px"
          >
            <a-select-option
              :value="item.id"
              v-for="(item, index) in modularList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>

        <a-button class="btnIn ml" @click="showDrawer">
          <a-icon type="upload" />导入模板
        </a-button>

        <a :href="outUrl + '/report/template/question.xlsx'">
          <a-button class="btnIn ml" icon="download"> 导出题目空模板 </a-button>
        </a>

        <span v-if="selectedRowKeys.length > 0">
          {{ `选中 ${selectedRowKeys.length} 项` }}
        </span>
      </div>

      <!-- :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }" -->
      <a-table
        :scroll="{ x: 1500, y: 520 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="optionInfo" slot-scope="text">
          <p v-for="(item, i) in text" :key="i" style="margin: 0">
            {{ item.label }}={{ item.value }}分
          </p>
        </span>

        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>

          <a-divider type="vertical" />

          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" :modularList="modularList" />

    <a-drawer
      title="题库导入"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="640"
    >
      <a-alert message="请先选择对应模块,然后再进行导入" banner />

      <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="模块名称" prop="moduleId">
          <a-select
            v-model="moduleId"
            placeholder="请选择模块"
            style="width: 100%"
            :disabled="isUping"
          >
            <a-select-option
              :value="item.id"
              v-for="(item, index) in modularList"
              :key="index"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="导入模板" prop="name">
          <a-upload
            name="file"
            :multiple="false"
            :show-upload-list="false"
            @change="handleChange"
            :customRequest="customRequest"
            v-if="!isUping"
          >
            <a-button class="btnIn ml">
              <a-icon type="upload" /> 导入模板
            </a-button>
          </a-upload>
          <a-button v-else loading> 模板导入中，请稍等 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "题目",
    dataIndex: "name",
    // align: 'center',
    key: "name",
    width: 400,
  },
  {
    title: "计分方式",
    dataIndex: "option_info",
    // align: 'center',
    key: "option_info",
    width: 200,
    scopedSlots: { customRender: "optionInfo" },
  },
  {
    title: "模块",
    dataIndex: "module_info",
    // align: 'center',
    key: "module_name",
    customRender: function (t) {
      return t.name;
    },
  },
  {
    title: "学段",
    dataIndex: "module_info",
    // align: 'center',
    key: "grade_type_name",
    customRender: function (t) {
      return t.grade_type_name.join("，");
    },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];

import addFrom from "./addFrom";

export default {
  name: "questionBank",
  components: {
    addFrom,
  },
  created() {
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));
    this.getmodularList();
    this.getTable();
  },
  data() {
    return {
      visible: false,
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      form: {},
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      // 学段
      sectionList: [],
      // 模块
      modularList: [],

      moduleId: "",
      outUrl: process.env.VUE_APP_API_BASE_URL,

      // 导入的判断
      isUping: false,
    };
  },
  methods: {
    afterVisibleChange(val) {
      console.log("visible", val);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      console.log("onClose???");
      if (this.isUping) {
        this.$message.warning("数据导入中，请稍等");
      } else {
        this.visible = false;
      }
    },
    customRequest(data) {
      console.log(data);
      console.log(this.moduleId);

      if (!this.moduleId) {
        this.$message.error("请先选择对应模块");
        return false;
      }
      let formData = new FormData();
      console.log(data.file);

      formData.append("attachment", data.file);
      formData.append("module_id", this.moduleId);

      // console.log(formData);

      this.saveFile(formData);
    },
    saveFile(formData) {
      this.$message.warning("数据导入中，请稍等");
      this.isUping = true;
      this.$api
        .postAction("/admin/v1/question-banks/import", formData)
        .then((res) => {
          console.log(res);

          if (res.code == 0) {
            this.$message.success(res.msg);

            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
          this.isUping = false;
          this.onClose();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    getmodularList() {
      // 获取所有模块
      this.modularList = [];
      this.$api
        .getAction("/admin/v1/modules", {
          expand: "grade_type_name,type_name",
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.modularList = res.data.list.map((item) => {
              item.speed = Math.round(Math.random() * 10);
              return item;
            });
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    getTable(section = "", module_id = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/question-banks", {
          expand: "module_info,option_info",
          page: this.pagination.current,
          "per-page": 10,
          section,
          module_id,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(this.form.section, this.form.module_id);
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    addFrom() {
      console.log("addFrom");
      this.$refs.addFrom.addFrom();
    },
    editRecord(record) {
      console.log("editRecord");
      console.log(record);
      this.$refs.addFrom.editFrom(record);
    },
    inFrom() {
      console.log("inFrom--导入");
    },
    delFrom() {
      console.log("delFrom--多选删除");
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);
      this.$api
        .delAction("/admin/v1/question-banks/", record.id)
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            // this.$message.success(res.msg)
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log("tableChange");
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(this.form.section, this.form.module_id);
    },
  },
};
</script>

<style scoped></style>
