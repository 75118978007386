<template>
  <a-drawer
    title="测评管理"
    placement="right"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="起止时间" prop="time">
        <a-range-picker
          :format="dateFormat"
          :placeholder="['开始时间', '结束时间']"
          @change="onOk"
          :allowClear="false"
          style="width: 100%"
          :value="[time1, time2]"
        />
      </a-form-model-item>

      <a-form-model-item label="生成报告时间" prop="reportTime">
        <a-date-picker
          :format="dateFormat"
          style="width: 100%"
          @change="onChange"
          :value="reportTime"
        />
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 生成测评卷 </a-button>
        <a-button @click="resetForm" class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
import moment from 'moment'
export default {
  name: '',
  data() {
    return {
      time1: null,
      time2: null,
      reportTime: null,
      dateFormat: 'YYYY-MM-DD',
      visible: false,
      visibleModal: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: '',
      form: {},
      rules: {
        time: [
          {
            required: true,
            validator: this.timeFn,
            trigger: 'blur',
          },
        ],
        reportTime: [
          {
            required: true,
            validator: this.reportTimeFn,
            trigger: 'blur',
          },
        ],
      },
    }
  },
  watch: {},
  created() {},
  methods: {
    moment,
    onChange(date, dateString) {
      console.log(date, dateString)
      this.reportTime = moment(date).format(this.dateFormat)
      // this.form.reportTime = moment(date).format(this.dateFormat)
    },
    onOk(value) {
      console.log('onOk: ', value)
      console.log('onOk: ', moment(value[0]).format(this.dateFormat))
      console.log('onOk: ', moment(value[1]).format(this.dateFormat))

      this.time1 = moment(value[0]).format(this.dateFormat)
      this.time2 = moment(value[1]).format(this.dateFormat)

      // this.form.start_time = moment(value[0]).format(this.dateFormat)
      // this.form.end_time = moment(value[1]).format(this.dateFormat)
    },
    onSubmit() {
      // console.log(this.form)
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let obj = {
            start_time: this.time1,
            end_time: this.time2,
            report_time: this.reportTime,
          }
          // console.log(obj)
          this.$api
            .putAction('/admin/v1/school-assessments', this.form.id, obj)
            .then((res) => {
              if (res.code == 0) {
                this.onClose()
                this.$parent.getTable()
              } else {
                if (res.data !== undefined) {
                  console.log(res.data[Object.keys(res.data)[0]])
                  this.$message.error(res.data[Object.keys(res.data)[0]])
                } else {
                  this.$message.error(res.msg)
                }
              }
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.onClose()
    },
    addFrom() {
      // this.addFlag = true
      // this.form = {}
      // console.log('addFrom')
      // this.visible = true
    },
    editFrom(record) {
      // this.addFlag = false
      console.log('editFrom')
      console.log(record)
      this.form = record

      console.log('record.start_time_str')
      console.log(record.start_time_str)
      console.log(record.end_time_str)
      console.log(record.report_time_str)

      this.time1 = record.start_time_str
        ? moment(record.start_time_str).format(this.dateFormat)
        : null
      this.time2 = record.end_time_str
        ? moment(record.end_time_str).format(this.dateFormat)
        : null
      this.reportTime = record.report_time_str
        ? moment(record.report_time_str).format(this.dateFormat)
        : null

      console.log('--')
      console.log(this.time1)
      console.log(this.time2)
      console.log(this.reportTime)

      this.visible = true
    },
    onClose() {
      console.log('onClose')
      this.$refs.ruleForm.resetFields()
      this.form = {}

      this.time1 = null
      this.time2 = null
      this.reportTime = null

      this.visible = false
    },

    timeFn(rule, value, callback) {
      console.log('value-*-timeFn')
      console.log(this.time1)
      console.log(this.time2)
      // 如果为空值，就抛出错误
      if (
        this.time1 == '' ||
        this.time2 == '' ||
        this.time1 == null ||
        this.time2 == null ||
        this.time1 == undefined ||
        this.time2 == undefined
      ) {
        callback(new Error('请选择起止时间'))
      } else {
        callback()
      }
    },
    reportTimeFn(rule, value, callback) {
      console.log('value-*-reportTimeFn')
      console.log(this.reportTime)
      // 如果为空值，就抛出错误
      if (
        this.reportTime == '' ||
        this.reportTime == undefined ||
        this.reportTime == null
      ) {
        console.log('1')
        callback(new Error('请选择报告生成时间'))
      } else {
        console.log('2')
        callback()
      }
    },
  },
}
</script>

<style scoped lang="less">
:deep(.ant-col-14) {
  width: 80%;
}

:deep(.ant-form) {
  .ant-transfer-list {
    height: 400px;
    width: calc(50% - 20px);
  }

  .ant-checkbox-group {
    .ant-row {
      overflow: auto;
      height: 300px;
    }
  }

  .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: center;
  }
}

:deep(.ant-drawer-body) {
  .ant-col-4 {
    width: 20%;
  }

  .ant-input-number {
    width: 100%;
  }
}
</style>
