<template>
  <a-config-provider :locale="locale">
    <!-- <App /> -->
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  data() {
    return {
      locale: zhCN,
    }
  },
}
</script>
<style lang="less">
#app {
  height: 100%;
}
</style>
