var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questionBank"},[_c('div',{staticClass:"bodyTop"},[_c('a-form-model',{ref:"ruleForm",attrs:{"layout":"inline","model":_vm.form,"labelAlign":"right"},on:{"submit":_vm.handleSubmit},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"校区"}},[_c('a-input',{attrs:{"placeholder":"请输入校区"},model:{value:(_vm.form.campus),callback:function ($$v) {_vm.$set(_vm.form, "campus", $$v)},expression:"form.campus"}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"手机号"}},[_c('a-input',{attrs:{"placeholder":"请输入手机号"},model:{value:(_vm.form.contact_phone),callback:function ($$v) {_vm.$set(_vm.form, "contact_phone", $$v)},expression:"form.contact_phone"}})],1)],1),_c('a-col',[_c('a-form-model-item',[_c('a-button',{staticClass:"btnGreenFull",attrs:{"html-type":"submit","icon":"search"}},[_vm._v(" 搜索 ")]),_c('a-button',{staticClass:"btnGreen ml",attrs:{"icon":"sync"},on:{"click":_vm.reset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{ref:"projectArea",attrs:{"prop":"projectArea","labelCol":{ span: 10 },"wrapperCol":{ span: 12 },"label":"地区:"}},[_c('a-cascader',{staticStyle:{"width":"185px"},attrs:{"options":_vm.options,"placeholder":"请选择地区","allowClear":"","field-names":{
                label: 'name',
                value: 'optionsid',
                children: 'list',
              }},on:{"change":_vm.onChange},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}})],1)],1),_c('a-col',{attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"label":"学校"}},[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"请选择学校"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}},_vm._l((_vm.schoolList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1)],1)],1)],1),_c('div',{staticClass:"bodyMain"},[_c('div',{staticClass:"btnRegion"},[_c('a-button',{staticClass:"btnGreenFull",attrs:{"icon":"plus"},on:{"click":_vm.addFrom}},[_vm._v(" 新增 ")]),_c('a-popconfirm',{attrs:{"placement":"topRight","ok-text":"确认","cancel-text":"取消"},on:{"confirm":_vm.delFrom}},[_c('template',{slot:"title"},[_c('p',[_vm._v("确认删除吗?")])]),_c('a-button',{staticClass:"ml",attrs:{"icon":"delete"}},[_vm._v(" 删除 ")])],2),(_vm.selectedRowKeys.length > 0)?_c('span',[_vm._v(" "+_vm._s(`选中 ${_vm.selectedRowKeys.length} 项`)+" ")]):_vm._e()],1),_c('a-table',{attrs:{"scroll":{ x: 1500, y: 520 },"loading":_vm.loading,"columns":_vm.columns,"data-source":_vm.data,"row-selection":{
        selectedRowKeys: _vm.selectedRowKeys,
        onChange: _vm.onSelectChange,
      },"pagination":_vm.pagination,"rowKey":(record) => record.id},on:{"change":_vm.tableChange},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('span',{},[_c('a-button',{staticClass:"btnIn",on:{"click":function($event){return _vm.editRecord(record)}}},[_vm._v(" 编辑 ")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"placement":"topRight","ok-text":"确认","cancel-text":"取消"},on:{"confirm":function($event){return _vm.delRecord(record)}}},[_c('template',{slot:"title"},[_c('p',[_vm._v("确认删除吗?")])]),_c('a-button',{staticClass:"btnOrg"},[_vm._v(" 删除 ")])],2)],1)}}])})],1),_c('addFrom',{ref:"addFrom"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }