<template>
  <a-drawer
    :title="titleName"
    placement="right"
    :closable="false"
    :visible="visible"
    :after-visible-change="afterVisibleChange"
    @close="onClose"
    :width="640"
  >
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="学校名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="校区" prop="campus">
        <a-input v-model="form.campus" />
      </a-form-model-item>

      <a-form-model-item label="省" prop="province_id">
        <a-select
          v-model="form.province_id"
          placeholder="请选择省份"
          @change="provinceChange"
        >
          <a-select-option
            :value="item.id"
            v-for="(item, index) in provinceList"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="市" prop="city_id">
        <a-select
          v-model="form.city_id"
          placeholder="请选择城市"
          @change="cityChange"
        >
          <a-select-option
            :value="item.id"
            v-for="(item, index) in cityList"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="区" prop="district_id">
        <a-select v-model="form.district_id" placeholder="请选择县区">
          <a-select-option
            :value="item.id"
            v-for="(item, index) in districtList"
            :key="index"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="详细地址" prop="address">
        <a-input v-model="form.address" />
      </a-form-model-item>
      <a-form-model-item label="学段" prop="section">
        <a-select v-model="form.section" placeholder="请选择学段">
          <a-select-option
            :value="item.value"
            v-for="(item, index) in sectionList"
            :key="index"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="联系人" prop="contact_name">
        <a-input v-model="form.contact_name" />
      </a-form-model-item>
      <a-form-model-item label="联系人号码" prop="contact_phone">
        <a-input v-model="form.contact_phone" />
      </a-form-model-item>
      <a-form-model-item label="联系人邮箱" prop="contact_mail">
        <a-input v-model="form.contact_mail" />
      </a-form-model-item>

      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
        <a-button @click="resetForm" class="ml"> 取消 </a-button>
      </a-form-model-item>
    </a-form-model>
  </a-drawer>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      visible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      other: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "blur",
          },
        ],
        campus: [
          {
            min: 0,
            max: 128,
            message: "最大长度128",
            trigger: "blur",
          },
        ],
        address: [
          {
            min: 0,
            max: 128,
            message: "最大长度128",
            trigger: "blur",
          },
        ],

        province_id: [
          {
            required: true,
            message: "请选择省份",
            trigger: "blur",
          },
        ],
        city_id: [
          {
            required: true,
            message: "请选择市区",
            trigger: "blur",
          },
        ],
        district_id: [
          {
            required: true,
            message: "请选择县区",
            trigger: "blur",
          },
        ],
        section: [
          {
            required: true,
            message: "请选择学段",
            trigger: "blur",
          },
        ],
        contact_name: [
          {
            required: true,
            message: "请输入联系人",
            trigger: "blur",
          },
          {
            min: 0,
            max: 20,
            message: "最大长度20",
            trigger: "blur",
          },
        ],
        contact_phone: [
          {
            required: true,
            message: "请输入联系人手机号",
            trigger: "blur",
          },
        ],
        contact_mail: [
          {
            required: true,
            message: "请输入联系人邮箱",
            trigger: "blur",
          },
          {
            type: "email",
            message: "请输入正确邮箱",
          },
        ],
      },

      // 判断是 add 还是edit
      addFlag: true,
      titleName: "",

      // 省市区
      provinceList: [],
      cityList: [],
      districtList: [],
      // 学段
      sectionList: [],
    };
  },
  watch: {},
  created() {
    this.setList();
  },
  methods: {
    setList() {
      // console.log("setList");
      // console.log(this.provinceList);
      // console.log(sessionStorage.getItem("provinceList"));

      if (this.provinceList == null || this.provinceList.length == 0) {
        this.provinceList = JSON.parse(sessionStorage.getItem("provinceList"));
        // console.log("provinceList--2");
        // console.log(this.provinceList);
      }

      if (this.sectionList == null || this.sectionList.length == 0) {
        this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));
        // console.log("sectionList--2");
        // console.log(this.sectionList);
      }
    },
    provinceChange(value) {
      console.log(`selected ${value}`);
      let city = this.provinceList.filter((item) => item.id == value);
      console.log(city);
      this.cityList = city[0].list;
      console.log(this.cityList);
    },
    cityChange(value) {
      console.log(`selected ${value}`);
      let district = this.cityList.filter((item) => item.id == value);
      console.log(district);
      this.districtList = district[0].list;
      console.log(this.districtList);
    },
    onSubmit() {
      console.log(this.form);
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.addFlag) {
            this.$api.postAction("/admin/v1/schools", this.form).then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.onClose();
                this.$parent.getTable();
              } else {
                if (res.data !== undefined) {
                  console.log(res.data[Object.keys(res.data)[0]]);
                  this.$message.error(res.data[Object.keys(res.data)[0]]);
                } else {
                  this.$message.error(res.msg);
                }
              }
            });
          } else {
            this.$api
              .putAction("/admin/v1/schools", this.form.id, this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.onClose();
                  this.$parent.getTable();
                } else {
                  if (res.data !== undefined) {
                    console.log(res.data[Object.keys(res.data)[0]]);
                    this.$message.error(res.data[Object.keys(res.data)[0]]);
                  } else {
                    this.$message.error(res.msg);
                  }
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.form = {};
      this.onClose();
    },
    addFrom() {
      this.setList();
      this.form = {};
      console.log("addFrom");
      this.visible = true;
      this.addFlag = true;
      this.titleName = "新增学校信息";
    },
    editFrom(record) {
      this.setList();
      console.log("editFrom--record.province_id");
      console.log(record);
      console.log(record.province_id);

      this.provinceChange(record.province_id);
      this.cityChange(record.city_id);

      this.form = record;
      this.visible = true;
      this.addFlag = false;
      this.titleName = "编辑学校信息";
    },
    onClose() {
      this.$refs.ruleForm.resetFields();
      this.visible = false;
    },
    afterVisibleChange(val) {
      console.log("触发以及结束时的回调", val);
    },
  },
};
</script>

<style scoped></style>
