<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
        labelAlign="right"
      >
        <a-form-model-item label="用户名">
          <a-input v-model="form.user_name" placeholder="请输入用户名" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <a-table
        :scroll="{ x: 1500, y: 520 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    // align: 'center',
    customRender: function (text, record, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "账号名",
    dataIndex: "user_name",
    // align: 'center',
    key: "user_name",
  },
  {
    title: "IP地址",
    dataIndex: "ip",
    // align: 'center',
    key: "ip",
  },
  {
    title: "时间",
    dataIndex: "created_at_str",
    // align: 'center',
    key: "created_at_str",
  },
  {
    title: "设备系统信息",
    dataIndex: "",
    key: "device",
    // align: 'center',
    customRender: function (text, record) {
      return record.os_info + record.browser_info;
    },
  },
];

export default {
  name: "authLog",
  components: {},
  created() {
    this.getTable();
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      columns: columns,
      pagination: {
        // 只有1页时,不展示下方分页按钮
        // hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
    };
  },
  methods: {
    getTable(user_name = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/auth-logs", {
          expand: "created_at_str",
          page: this.pagination.current,
          user_name,
          "per-page": 10,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
          this.loading = false;
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(this.form.user_name);
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;
      this.getTable(this.form.user_name);
    },
  },
};
</script>

<style scoped lang="less">
.bodyTop {
  height: 100px;

  :deep(.ant-select-selection--single .ant-select-selection__rendered) {
    width: 100px;
  }

  :deep(.ant-form-inline .ant-form-item > .ant-form-item-label) {
    width: 55px;
  }
}
</style>
