<template>
  <div class="logo">
    <img src="../assets/logo.png" alt="" />
    <span v-show="!collapsed">后台管理系统</span>
  </div>
</template>
<script>
export default {
  props: ["collapsed"],
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
#components-layout-demo-custom-trigger .logo {
  height: 64px;
  background: #007cae;
  color: #fff;
  // margin: 16px;
  img {
    width: 75px;
    height: 64px;
    padding: 15px 10px 15px 30px;
  }
}
</style>
