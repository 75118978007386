import Vue from 'vue'
import VueRouter from 'vue-router'

import layOut from '@/components/layOut'
import error from '@/components/error'
import login from '@/views/login'
import loginUser from '@/views/loginUser'
import userPaper from '@/views/loginUser/userPaper'
import userPaperFu from '@/views/loginUser/userPaperFu'
import authLog from '@/views/auth/authLog'
import interview from '@/views/interview'

import examination from '@/views/examination'
import question from '@/views/examination/question'

import evaluating from '@/views/evaluating'

import questionBank from '@/views/questionBank'
import auth from '@/views/auth'
import authUser from '@/views/auth/user'

import school from '@/views/school'
import classInfo from '@/views/school/classInfo'
import studentsInfo from '@/views/school/studentsInfo'
import cityschool from '@/views/school/cityschool'

import regionReport from '@/views/regionReport'
import gradeReport from '@/views/gradeReport'
import classReport from '@/views/classReport'
import classReportBatch from '@/views/classReportBatch'
import report from '@/views/report'
import personalReport from '@/views/personalReport'
import modular from '@/views/modular'

import regionDataVisualization from '@/views/largeScreen/regionDataVisualization'
import schoolDataVisualization from '@/views/largeScreen/schoolScreen'


Vue.use(VueRouter)

// 这个先放一放,后面 是否根据后台返回值 来设定路由信息
// console.log("JSON.parse(sessionStorage.getItem('userMenu'))---router")
// console.log(JSON.parse(sessionStorage.getItem('userMenu')))
// console.log(JSON.parse(sessionStorage.getItem('userMenu'))[1])
// let userMenu = JSON.parse(sessionStorage.getItem('userMenu'))[0]

let userMenu = {}
if (sessionStorage.getItem('userMenu')) {
  // console.log('有')
  userMenu = JSON.parse(sessionStorage.getItem('userMenu'))[0]
}
// console.log(userMenu)

let uurl = ''

if (userMenu.url == '') {
  // 从子元素当中找
  uurl = userMenu.list[0].url
} else {
  uurl = userMenu.url ? userMenu.url : 'loginUser'
}

// console.log(uurl)

let routerObj = {
  path: '/',
  name: 'layOut',
  component: layOut,
  redirect: '/' + uurl,
  children: [
    // 题库
    {
      path: 'questionBank',
      component: questionBank,
    },

    // 学校-基本信息
    {
      path: 'school',
      component: school,
    },
    // 学生-基本信息
    {
      path: 'studentsInfo',
      component: studentsInfo,
    },
    {
      path: 'cityschool',
      component: cityschool,
    },
    // 班级管理
    {
      path: 'classInfo',
      component: classInfo,
    },

    // 学校评测管理
    {
      path: 'evaluating',
      component: evaluating,
    },

    // 试卷--评测试卷
    {
      path: 'examination',
      component: examination,
    },
    // 试卷--问卷
    {
      path: 'question',
      component: question,
    },

    // 评估报告
    {
      path: 'report',
      component: report,
    },

    // 个人报告
    {
      path: 'personalReport',
      component: personalReport,
    },
    // 区级报告
    {
      path: 'regionReport',
      component: regionReport,
    },
    // 年级报告
    {
      path: 'gradeReport',
      component: gradeReport,
    },
    // 班级报告
    {
      path: 'classReport',
      component: classReport,
    },
    {
      path: 'classReportBatch',
      component: classReportBatch
    },

    // 模块
    {
      path: 'modular',
      component: modular,
    },

    // 权限-用户
    {
      path: 'authUser',
      component: authUser,
    },
    // 权限-角色
    {
      path: 'auth',
      component: auth,
    },
    {
      // 用户日志
      path: '/authLog',
      component: authLog,
    },
    {
      // 访谈信息
      path: '/interview',
      component: interview,
    },
    {
      //学校数据大屏
      path:'/schoolDataVisualization',
      component: schoolDataVisualization,
    },
    {
      //区级数据大屏
      path:'/regionDataVisualization',
      component: regionDataVisualization,
    }
  ],
}
let routes = [
  {
    // 后台登录
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    // 用户--前台
    path: '/loginUser',
    name: 'loginUser',
    component: loginUser,
    meta: {
      title: '综合素养发展测评',
      requiresAuth: true,
    },
  },
  {
    // 用户--前台--评测题
    path: '/userPaper',
    name: 'userPaper',
    component: userPaper,
    meta: {
      title: '综合素养发展测评',
      requiresAuth: true,
    },
  },
  {
    // 用户--前台--附加题
    path: '/userPaperFu',
    name: 'userPaperFu',
    component: userPaperFu,
    meta: {
      title: '综合素养发展测评',
      requiresAuth: true,
    },
  },
  {
    // 404 及 其他错误页面
    path: '*',
    name: 'error',
    component: error,
  },
]
routes.push(routerObj)
console.log('routes.push(routerObj)--1')
// console.log(routes)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes: routes,
})

// 全局 前置守卫：初始化时执行、每次路由切换前执行
router.beforeEach((to, from, next) => {
  // console.log('beforeEach----')
  // console.log('to.path')
  // console.log(to.path)
  // console.log('from.path')
  // console.log(from.path)

  // window.document.title =
  // to.meta.title == undefined ? '心理评测管理系统' : to.meta.title

  // console.log('beforeEach----')
  // to.meta.isAuth 路由中自定义变量
  // if (to.meta.isAuth) {
  //   //判断当前路由是否需要进行权限控制
  //   //sessionStorage.getItem('权限名称') 获取存储在本地的权限变量
  //   if (sessionStorage.getItem("权限名称") === "权限数值") {
  //     //权限控制的具体规则
  //     next(); //放行
  //   } else {
  //     alert("暂无权限查看");
  //   }
  // } else {
  next() //放行
  // }
})

// 全局 后置守卫：初始化时执行、每次路由切换后执行
// router.afterEach((to, from) => {
router.afterEach(() => {
  // console.log('afterEach----')
  // console.log(to.path)
  // console.log(from.path)
  // console.log('afterEach----')

  // if (to.meta.title) {
  //   document.title = to.meta.title; //修改网页的title
  // } else {
  //   document.title = "vue_test";
  // }
})

export default router
