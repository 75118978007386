<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
        labelAlign="right"
      >
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="省" prop="province_id">
              <a-select
                v-model="form.province_id"
                placeholder="请选择省份"
                @change="provinceChange"
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in provinceList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="市" prop="city_id">
              <a-select
                v-model="form.city_id"
                placeholder="请选择城市"
                @change="cityChange"
              >
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in cityList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="区" prop="district_id">
              <a-select v-model="form.district_id" placeholder="请选择县区">
                <a-select-option
                  :value="item.id"
                  v-for="(item, index) in districtList"
                  :key="index"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="学校">
              <a-input v-model="form.name" placeholder="请输入学校" />
            </a-form-model-item>
          </a-col>

          <a-col :span="8">
            <a-form-model-item label="校区">
              <a-input v-model="form.campus" placeholder="请输入校区" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-form-model-item>
            <a-button html-type="submit" class="btnGreenFull" icon="search">
              搜索
            </a-button>
            <a-button class="btnGreen ml" @click="reset" icon="sync">
              重置
            </a-button>
            <a-button class="btnGreen ml" @click="resetRe" icon="sync">
              开始重新测评
            </a-button>
          </a-form-model-item>
        </a-row>
      </a-form-model>
    </div>

    <!-- :scroll="{ x: 1500, y: 520 }" -->
    <div class="bodyMain">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <!-- <p style="text-align: center; margin-bottom: 0px">删除</p> -->
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "学校名称",
    dataIndex: "name",
    // align: 'center',
    key: "name",
  },
  {
    title: "校区",
    dataIndex: "campus",
    // align: 'center',
    key: "campus",
  },
  {
    title: "省",
    dataIndex: "province_name",
    // align: 'center',
    key: "province_name",
  },
  {
    title: "市",
    dataIndex: "city_name",
    // align: 'center',
    key: "city_name",
  },
  {
    title: "县区",
    dataIndex: "district_name",
    // align: 'center',
    key: "district_name",
  },
  {
    title: "学段",
    dataIndex: "section_name",
    // align: 'center',
    key: "section_name",
  },
];

export default {
  name: "schoolRe",
  components: {},
  created() {
    console.log("schoolRe-------------")
    this.provinceList = JSON.parse(sessionStorage.getItem("provinceList"));
    this.sectionList = JSON.parse(sessionStorage.getItem("sectionList"));
    
    this.getTable();
  },
  data() {
    return {
      form: {},
      data: [],
      loading: true,
      columns: columns,
      selectedRowKeys: [],
      pagination: {
        // 只有1页时,不展示下方分页按钮
        // hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      outUrl: process.env.VUE_APP_API_BASE_URL,

      // 省市区
      provinceList: [],
      cityList: [],
      districtList: [],
    };
  },
  methods: {
    provinceChange(value) {
      console.log(`selected ${value}`);
      let city = this.provinceList.filter((item) => item.id == value);
      console.log(city);
      this.cityList = city[0].list;
      console.log(this.cityList);
    },
    cityChange(value) {
      console.log(`selected ${value}`);
      let district = this.cityList.filter((item) => item.id == value);
      console.log(district);
      this.districtList = district[0].list;
      console.log(this.districtList);
    },
    getTable(
      name = "",
      campus = "",
      contact_phone = "",
      province_id = "",
      city_id = "",
      district_id = ""
    ) {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/schools", {
          expand: "province_name,city_name,district_name,section_name",
          page: this.pagination.current,
          name,
          campus,
          contact_phone,
          "per-page": 10,
          province_id,
          city_id,
          district_id,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
          this.loading = false;
        });
    },
    handleSubmit() {
      console.log(this.form);
      this.pagination.current = 1;
      this.getTable(
        this.form.name,
        this.form.campus,
        this.form.contact_phone,
        this.form.province_id,
        this.form.city_id,
        this.form.district_id
      );
    },
    reset() {
      console.log("reset");
      this.form = {};
      this.getTable();
    },
    resetRe() {
      console.log("开始重新测评");
      console.log(this.selectedRowKeys);

      if (this.selectedRowKeys.length < 1) {
        this.$message.warning("请选勾选");
        return false;
      }

      this.$api
        .postAction("/admin/v1/trouble-papers/batch-re-assess", {
          id: this.selectedRowKeys,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.selectedRowKeys = [];
            this.getTable();
          } else {
            if (res.data !== undefined) {
              console.log(res.data[Object.keys(res.data)[0]]);
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },

    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;

      this.getTable(
        this.form.name,
        this.form.campus,
        this.form.contact_phone,
        this.form.province_id,
        this.form.city_id,
        this.form.district_id
      );
    },
  },
};
</script>

<style scoped lang="less">
.bodyTop {
  height: 165px;
  :deep(.ant-select-selection--single .ant-select-selection__rendered) {
    width: 100px;
  }

  :deep(.ant-form-inline .ant-form-item > .ant-form-item-label) {
    width: 55px;
  }
}
</style>
