<template>
  <div class="questionBank">
    <div class="bodyTop">
      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="form"
        @submit="handleSubmit"
        @submit.native.prevent
      >
        <a-form-model-item label="学生姓名">
          <a-input v-model="form.student_name" placeholder="请输入学生姓名" />
        </a-form-model-item>

        <a-form-model-item>
          <a-button html-type="submit" class="btnGreenFull" icon="search">
            搜索
          </a-button>
          <a-button class="btnGreen ml" @click="reset" icon="sync">
            重置
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="bodyMain">
      <div class="btnRegion">
        <a-button class="btnGreenFull" @click="addFrom" icon="plus">
          新增
        </a-button>

        <a-upload
            name="file"
            :multiple="false"
            :show-upload-list="false"
            @change="uploadFile"
            :before-upload="beforeUpload"
            :customRequest="customRequest"
          >
            <a-button class="btnIn ml">
              <a-icon type="upload" /> 导入学生
            </a-button>
          </a-upload>

        <a-button class="btnIn ml" style="" @click="downFile" icon="download">
          导出学生
        </a-button>
      </div>

      <a-table
        :scroll="{ x: 1500, y: 500 }"
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="pagination"
        @change="tableChange"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a-button class="btnIn" @click="editRecord(record)"> 编辑 </a-button>
          <a-divider type="vertical" />
          <a-popconfirm
            placement="topRight"
            ok-text="确认"
            cancel-text="取消"
            @confirm="delRecord(record)"
          >
            <template slot="title">
              <p>确认删除吗?</p>
            </template>
            <a-button class="btnOrg"> 删除 </a-button>
          </a-popconfirm>
        </span>
      </a-table>
    </div>

    <addFrom ref="addFrom" />
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    dataIndex: "",
    key: "rowIndex",
    width: 60,
    customRender: function (t, r, index) {
      return parseInt(index) + 1;
    },
  },
  {
    title: "学生姓名",
    width: 300,
    dataIndex: "student_name",
    key: "student_name",
  },
  {
    title: "学校",
    dataIndex: "school_name",
    key: "school_name",
  },
  {
    title: "账号",
    width: 300,
    dataIndex: "user_name",
    key: "user_name",
  },
  {
    title: "创建日期",
    width: 200,
    dataIndex: "created_at_str",
    key: "created_at_str",
  },
  {
    title: "更新日期",
    width: 200,
    dataIndex: "updated_at_str",
    key: "updated_at_str",
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 200,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
import moment from "moment";
import addFrom from "./addFrom";

export default {
  name: "interview",
  components: {
    addFrom,
  },
  created() {
    this.getQuestionnaire();
    this.getTable();
  },
  data() {
    return {
      interview_question_data: [],
      data: [],
      loading: true,
      columns: columns,
      user: JSON.parse(sessionStorage.getItem('userInfo')),
      pagination: {
        // 只有1页时,不展示下方分页按钮
        hideOnSinglePage: false,
        total: 50,
        current: 1,
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条";
        },
      },
      form: {},
    };
  },
  methods: {
    moment,
    getQuestionnaire() {
      this.$api
        .getAction("/admin/v1/common/static", {
          data_name: "interview_question",
        })
        .then((res) => {
          if (res.code == 0) {
            this.interview_question_data = res.data.interview_question;
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    getTable(student_name = "") {
      this.loading = true;
      this.$api
        .getAction("/admin/v1/interviews", {
          expand:
            "school_name,user_name,student_name,created_at_str,updated_at_str",
          page: this.pagination.current,
          "per-page": 10,
          student_name,
        })
        .then((res) => {
          if (res.code == 0) {
            this.pagination.total = res.data._meta.totalCount;
            this.data = res.data.list.map((item) => {
              return item;
            });
            this.loading = false;
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.getTable(this.form.student_name);
    },
    reset() {
      this.form = {};
      this.getTable();
    },
    addFrom() {
      if (this.interview_question_data) {
        this.$refs.addFrom.addFrom(this.interview_question_data);
      } else {
        this.$message.error("未获取问卷数据，请刷新后重试");
      }
    },
    editRecord(record) {
      this.$refs.addFrom.editFrom(this.interview_question_data, record);
    },
    delRecord(record) {
      console.log("delRecord--单个删除");
      console.log(record);

      this.$api.delAction("/admin/v1/interviews", record.id).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.getTable();
        } else {
          if (res.data !== undefined) {
            console.log(res.data[Object.keys(res.data)[0]]);
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    tableChange(pagination) {
      console.log(pagination.current);
      this.pagination.current = pagination.current;
      this.getTable(this.form.student_name);
    },
    // 导出学生
    downFile(){
      if(this.user.grade_id.length == 0){
        this.$message.error("您尚未绑定班级");
      } else {
        let name = '学生访谈信息.xlsx';
        const a = document.createElement("a");
        let url = "/api/admin/v1/interviews/export";

        fetch(url,{
          headers: {
            'Authorization': `${sessionStorage.getItem('token')}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Cache-Control': 'no-cache',
          },
        })
          .then((res) => res.blob())
          .then((blob) => {
            // 将链接地址字符内容转变成blob地址
            a.href = URL.createObjectURL(blob);
            // 下载文件的名字
            a.download = name;
            document.body.appendChild(a);
            a.click();
          });
        }
    },
    // 导入学生
    uploadFile(info){
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload(){
      if(this.user.grade_id.length == 0){
        this.$message.error("您尚未绑定班级");
        return false
      }
    },
    customRequest(data) {
      let formData = new FormData();
      console.log(data.file);

      formData.append("attachment", data.file)

      this.saveFile(formData);
    },
    saveFile(formData) {
      this.$message.warning("数据导入中，请稍等");
      this.$api
        .postAction("/admin/v1/interviews/import", formData)
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg)
            this.getQuestionnaire();
            this.getTable();
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]])
            } else {
              this.$message.error(res.msg)
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="less">
span.btnOrg {
  border: 1px solid #ff8200;
  color: #ff8200;
  background: rgba(255, 128, 1, 0.06);
  margin-left: 10px;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
