<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible>
      <hLeft :collapsed="collapsed" />
      <!-- theme="dark" ant的默认模式-->
      <a-menu
        mode="inline"
        :default-open-keys="openKeys"
        :default-selected-keys="defaultKeys"
        @openChange="onOpenChange"
      >
        <template v-for="item in powerList">
          <a-sub-menu :key="item.id" v-if="item.list.length != 0">
            <span slot="title">
              <a-icon :type="item.icon" />
              <span> {{ item.name }}</span>
            </span>
            <template v-for="child in item.list">
              <a-menu-item :key="child.id">
                <router-link :to="{ path: child.url }">
                  <span>{{ child.name }}</span>
                </router-link>
              </a-menu-item>
            </template>
          </a-sub-menu>
          <a-menu-item :key="item.id" v-else>
            <router-link :to="{ path: item.url }">
              <a-icon :type="item.icon" />
              <span>{{ item.name }}</span>
            </router-link>
          </a-menu-item>
        </template>
      </a-menu>
    </a-layout-sider>

    <a-layout>
      <a-layout-header style="background: #007cae; padding: 0; color: #fff">
        <a-icon
          class="trigger"
          :type="collapsed ? 'menu-unfold' : 'menu-fold'"
          @click="() => (collapsed = !collapsed)"
        />
        <hRight />
      </a-layout-header>

      <a-layout-content>
        <router-view />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import hLeft from './headerLeft'
import hRight from './headerRight'
// import { powerList } from '@/utils/mixin'

export default {
  name: 'layOut',
  // mixins: [powerList],
  components: {
    hLeft,
    hRight,
  },
  data() {
    return {
      collapsed: false,
      // 所有的 卡片
      rootSubmenuKeys: [],
      // 默认打开的 卡片组合
      openKeys: [],
      // 默认打开的 卡片单项
      defaultKeys: [],

      powerList: [],
    }
  },
  created() {
    // this.powerList = powerList
    this.powerList = JSON.parse(sessionStorage.getItem('userMenu'))

    this.rootSubmenuKeys = this.powerList.map((item) => item.id + '')

    let path = this.$route.path.slice(1)

    // 循环(包含子元素),判断 是否含有顶部路由的页面 url == path
    for (let i = 0; i < this.powerList.length; i++) {
      if (this.powerList[i].list.length > 0) {
        for (let y = 0; y < this.powerList[i].list.length; y++) {
          if (path == this.powerList[i].list[y].url) {
            this.openKeys.push(this.powerList[i].id)
            this.defaultKeys.push(this.powerList[i].list[y].id)
          }
        }
      } else {
        if (path == this.powerList[i].url) {
          this.openKeys.push(this.powerList[i].id)
          this.defaultKeys.push(this.powerList[i].id)
        }
      }
    }

    console.log(this.openKeys)
    console.log(this.defaultKeys)
    console.log('layOut')
  },
  methods: {
    onOpenChange(openKeys) {
      console.log('onOpenChange')
      console.log(openKeys)

      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      )

      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
  },
}
</script>

<style lang="less" scoped>
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #007cae;
}

#components-layout-demo-custom-trigger.ant-layout {
  height: 100%;

  .ant-layout-sider,
  .ant-menu,
  :deep(.ant-menu-sub) {
    background-color: #f7f9fa;
  }
  .ant-menu-item,
  .ant-menu-submenu-title {
    color: #333333;
  }
  :deep(.ant-menu-submenu-title:hover) {
    color: #007cae;
  }

  .ant-menu .ant-menu-item-selected,
  .ant-menu-submenu-popup .ant-menu-item-selected {
    background: rgba(0, 124, 174, 0.06);
  }

  .ant-menu-submenu-active {
    // color: #007cae;
    color: #e11;
  }
  .ant-menu-submenu-selected {
    color: #333;
  }

  .ant-menu .ant-menu-item-selected .anticon,
  .ant-menu .ant-menu-item-selected .anticon + span {
    color: #007cae;
  }
  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover,
  .ant-menu-item > a:hover {
    color: #007cae;
  }

  .ant-menu .ant-menu-selected::after,
  .ant-menu .ant-menu-item-selected::after {
    opacity: 0;
  }
}
</style>
