<template>
  <div ref="question">
    <a-drawer
      title="问卷"
      placement="right"
      :closable="false"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="resetForm"
      :width="640"
    >
      <a-form-model :model="form" labelAlign="left">
        <a-form-model-item label="班级">
          <a-cascader
            v-model="form.select"
            :options="options"
            placeholder="请选择班级"
            allowClear
            @change="onChangeGrade"
            :field-names="{
              label: 'name',
              value: 'id',
              children: 'list',
            }"
          />
        </a-form-model-item>
        <a-form-model-item label="测评卷">
          <a-radio-group
            v-model="form.assess_id"
            @change="onChange"
            style="width: 100%"
          >
            <a-row>
              <a-col
                v-for="(item, index) in list"
                :key="index"
                :span="24"
                class="example"
                style="margin: 5px 0"
              >
                <a-radio
                  :value="item.id"
                  style="
                    width: 100%;
                    white-space: pre-wrap !important;
                    word-break: break-all;
                    word-wrap: break-word;
                  "
                  >{{ item.name }}</a-radio
                >
              </a-col>
            </a-row>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
          <a-button @click="onSubmit" class="btnGreenFull"> 确认 </a-button>
          <a-button @click="resetForm" class="ml"> 取消 </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      visible: false,
      form: {
        school_id: undefined,
        assess_id: undefined,
        class_id: undefined,
        grade: undefined,
        select: [],
      },
      list: [],
      options: [],
      select: [],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.getInfo();
  },
  methods: {
    onChange(e) {
      this.form.assess_id = e.target.value;
    },
    onChangeGrade(value) {
      if (value.length != 0) {
        this.form.select = value;
        this.form.school_id = value[0];
        this.form.grade = value[1];
        this.form.class_id = value[2];
        this.list = [];
        this.getList();
      } else {
        this.list = [];
      }
    },
    // getArea() {
    //   this.$api.getAction("/admin/v1/school/cityschool").then((res) => {
    //     if (res.code == 0) {
    //       for (let i in res.data) {
    //         this.options.push(res.data[i]);
    //       }
    //       this.options.forEach((item) => {
    //         item.optionsid = item.province_id;
    //         item.list.forEach((j) => {
    //           j.optionsid = j.city_id;
    //           j.list.forEach((m) => {
    //             m.optionsid = m.district_id;
    //           });
    //         });
    //       });
    //     } else {
    //       console.log("this.options111111111");
    //     }
    //   });
    // },
    getInfo() {
      this.$api
        .getAction("/admin/v1/class-reports/class-list", {})
        .then((res) => {
          if (res.code == 0) {
            // this.$message.success(res.msg);
            this.options = res.data.school;
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },

    getList() {
      let params = {
        class_id: this.form.select[2],
      };
      this.$api
        .postAction("/admin/v1/class-reports/assess-list", params)
        .then((res) => {
          if (res.code == 0) {
            // this.$message.success(res.msg);
            this.list = res.data;
          } else {
            if (res.data !== undefined) {
              this.$message.error(res.data[Object.keys(res.data)[0]]);
            } else {
              this.$message.error(res.msg);
            }
          }
        });
    },
    onSubmit() {
      if (!this.form.select.length) {
        this.$message.warning("请选择班级");
        return false;
      }
      if (!this.form.class_id) {
        this.$message.warning("请选择班级");
        return false;
      }
      if (!this.form.assess_id) {
        this.$message.warning("请选择问卷");
        return false;
      }
      let params = {
        class_id: this.form.select[2],
        assess_id: this.form.assess_id,
      };
      this.$api.postAction("/admin/v1/class-reports", params).then((res) => {
        if (res.code == 0) {
          this.resetForm();
          this.$message.success(res.msg);
          this.$parent.getTable();
        } else {
          if (res.data !== undefined) {
            this.$message.error(res.data[Object.keys(res.data)[0]]);
          } else {
            this.$message.error(res.msg);
          }
        }
      });
    },
    resetForm() {
      this.form = {};
      this.visible = false;
    },
    //data 是问卷数据
    addFrom() {
      this.visible = true;
    },
    afterVisibleChange(val) {
      console.log("触发以及结束时的回调", val);
    },
  },
};
</script>

<style scoped lang="less">
:deep(.ant-drawer-body) {
  .ant-col-4 {
    width: 20%;
  }

  .ant-input-number {
    width: 100%;
  }
  .ant-cascader-menu {
    height: 300px !important;
  }
}
</style>
